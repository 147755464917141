import React, { useEffect, Suspense ,lazy } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import {  
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import {
  globalIsMainPatientPageOpen,
} from './GraphQL/LocalState/globalState';

//import PermissionsAdminPanel from "./Views/AdminPanel/Permissions";
//import AreasAdminPanel from "./Views/AdminPanel/Areas";
//import CentersAdminPanel from './Views/AdminPanel/Centers';
//import RolesAdminPanel from './Views/AdminPanel/Roles';
//import UsersAdminPanel from './Views/AdminPanel/Users';
//import BillingCodesAdminPanel from './Views/AdminPanel/BillingCodes';
//import ProceduresAdminPanel from './Views/AdminPanel/Procedures';
//import ZonesAdminPanel from './Views/AdminPanel/Zones';
//import ProfessionalsAdminPanel from './Views/AdminPanel/Professionals';
//import Persons from './Views/AdminPanel/Persons';
//import SpecialitiesAdminPanel from './Views/AdminPanel/Specialities';
//import ProfessionalSchedulesAdminPanel from "./Views/AdminPanel/ProfessionalSchedules";
//import EquipmentSchedulesAdminPanel from './Views/AdminPanel/EquipmentSchedules';
//import EquipmentsAdminPanel from './Views/AdminPanel/Equipments';
//import TokenTagsAdminPanel from './Views/AdminPanel/TokenTags';
//import TemplatesAdminPanel from './Views/AdminPanel/Templates';
//import DynamicDoctosAdminPanel from './Views/AdminPanel/DynamicDoctos';
//import InsuranceCorpsAdminPanel from './Views/AdminPanel/InsuranceCorps';
//import MedicalAttentions from './Views/Physician/MedicalAttentions'
//import ExamsAndProcedures from './Views/Physician/ExamsAndProcedures'
//import PriceAgreementsAdminPanel from './Views/AdminPanel/PriceAgreements';
//import PhysicianSchedule from './Views/Physician/Schedule'
//import PhysicianStatistics from './Views/Physician/Statistics'
//import PhysicianPatient from './Views/Physician/Patient'
//import AdminPanelDashboard from "./Views/AdminPanel/Dashboard";
//import TenantsAdminPanel from './Views/AdminPanel/Tenants';
//import MasterdataAdminPanel from "./Views/AdminPanel/Masterdata";
//import PhysicianWorklist from './Views/Physician/Worklist'
//import RecepcionWorklist from "./Views/RecepcionWorklist";
//import Schedule from "./Views/Schedule/Schedule";
//import CashierSessionsPayments from './Views/Payments/CashierSessions';
//import UserCashierSessions from './Views/Payments/UserCashierSessions';
//import PatientInformation from './Views/PatientInformation';
//import PhysicianPatientList from './Views/Physician/PhysicianPatientList';
//import TecWorklist from './Views/Physician/TecWorklist';
//import RadWorklist from './Views/Radiologist/RadWorklist';
//import PatientContainerRadiology from './Views/Radiologist/PatientContainerRadiology';
//import PatientDataDeliveryWorklist from './Views/PatientDataDeliveryWorklist';
 
const AIRadWorklist = lazy(() => import('./Views/Radiologist/AIRadWorklist'));
const PermissionsAdminPanel = lazy(() => import("./Views/AdminPanel/Permissions"));
const AreasAdminPanel = lazy(() => import("./Views/AdminPanel/Areas"));
const CentersAdminPanel = lazy(() => import('./Views/AdminPanel/Centers'));
const RolesAdminPanel = lazy(() => import("./Views/AdminPanel/Roles"));
const UsersAdminPanel = lazy(() => import('./Views/AdminPanel/Users'));
const BillingCodesAdminPanel = lazy(() => import('./Views/AdminPanel/BillingCodes'));
const ProceduresAdminPanel = lazy(() => import('./Views/AdminPanel/Procedures'));
const ZonesAdminPanel = lazy(() => import('./Views/AdminPanel/Zones'));
const ProfessionalsAdminPanel = lazy(() => import('./Views/AdminPanel/Professionals'));
const Persons = lazy(() => import('./Views/AdminPanel/Persons'));
const SpecialitiesAdminPanel = lazy(() => import('./Views/AdminPanel/Specialities'));
const ProfessionalSchedulesAdminPanel = lazy(() => import("./Views/AdminPanel/ProfessionalSchedules"));
const EquipmentSchedulesAdminPanel = lazy(() => import('./Views/AdminPanel/EquipmentSchedules'));
const EquipmentsAdminPanel = lazy(() => import('./Views/AdminPanel/Equipments'));
const TokenTagsAdminPanel = lazy(() => import('./Views/AdminPanel/TokenTags'));
const TemplatesAdminPanel = lazy(() => import('./Views/AdminPanel/Templates'));
const DynamicDoctosAdminPanel = lazy(() => import('./Views/AdminPanel/DynamicDoctos'));
const InsuranceCorpsAdminPanel = lazy(() => import('./Views/AdminPanel/InsuranceCorps'));
const PriceAgreementsAdminPanel = lazy(() => import('./Views/AdminPanel/PriceAgreements'));
const AdminPanelDashboard = lazy(() => import("./Views/AdminPanel/Dashboard"));
const TenantsAdminPanel = lazy(() => import('./Views/AdminPanel/Tenants'));
const MasterdataAdminPanel = lazy(() => import("./Views/AdminPanel/Masterdata"));
const Schedule = lazy(() => import("./Views/Schedule/Schedule"));
const PatientContainerRadiology = lazy(() => import('./Views/Radiologist/PatientContainerRadiology'));
const PatientInformation = lazy(() => import('./Views/PatientInformation'));
const RadWorklist = lazy(() => import('./Views/Radiologist/RadWorklist'));
const ReportBasedWorklist = lazy(() => import('./Views/Radiologist/ReportBasedWorklist'));
const PhysicianPatientContainer = lazy(() => import('./Views/Physician/Patient'));
const ReportsProduction = lazy(() => import('./Views/Statistics/ReportsProduction'));
const EventsProduction = lazy(() => import('./Views/Statistics/EventsProduction'));
const TecWorklist = lazy(() => import('./Views/Physician/TecWorklist'));
const PhysicianPatientList = lazy(() => import('./Views/Physician/PhysicianPatientList'));
const UserCashierSessions = lazy(() => import('./Views/Payments/UserCashierSessions'));
const CashierSessionsPayments = lazy(() => import('./Views/Payments/CashierSessions'));
const RecepcionWorklist = lazy(() => import("./Views/RecepcionWorklist"));
const PhysicianWorklist = lazy(() => import('./Views/Physician/Worklist'));
const MedicalAttentions = lazy(() => import('./Views/Physician/MedicalAttentions'));
const PhysicianStatistics = lazy(() => import('./Views/Physician/Statistics'));
const PhysicianSchedule = lazy(() => import('./Views/Physician/Schedule'));
const ExamsAndProcedures = lazy(() => import('./Views/Physician/ExamsAndProcedures'));
const PatientDataDeliveryWorklist = lazy(() => import('./Views/PatientDataDeliveryWorklist'));
const OphthalmologyWorklist = lazy(() => import('./Views/Ophthalmology/OphthalmologyWorklist'));
const OphthalmologistPatientContainer = lazy(() => import('./Views/Ophthalmology/PatientContainerOphthalmology'));

export const mainRoutesMap = new Map([
  ['/administrative/worklist', RecepcionWorklist],
  ['/administrative/worklist/patient-data-delivery', PatientDataDeliveryWorklist],
  ['/administrative/schedule', Schedule],
  ['/clinical/procedures', 'procedures'],
  ['/clinical/physician/worklist', PhysicianWorklist]  
]);

export const exactRoutesMap = new Map([
  
  ['/administrative/adminpanel/dashboard', AdminPanelDashboard],
  ['/administrative/adminpanel/persons', Persons],
  ['/administrative/adminpanel/tenants', TenantsAdminPanel],
  ['/administrative/adminpanel/masterdata', MasterdataAdminPanel],    
  ['/clinical/physician/schedule', PhysicianSchedule],
  ['/clinical/physician/worklist', PhysicianWorklist],  
  ['/clinical/radiology/tec/worklist', TecWorklist],
  ['/clinical/radiologist/worklist', RadWorklist],
  ['/clinical/ai/radiologist/ai-worklist', AIRadWorklist],
  ['/clinical/physician/statistics', PhysicianStatistics],
  ['/clinical/physician/patients', PhysicianPatientList],
  ['/clinical/physician/patient/ehr', PhysicianPatientContainer],
  ['/clinical/physician/patient/radreport', PatientContainerRadiology],
  ['/clinical/physician/patient/ophthalmologyreport', OphthalmologistPatientContainer],
  ['/clinical/physician/patients/medical_attentions', MedicalAttentions],
  ['/clinical/physician/patients/exams_and_procedures', ExamsAndProcedures],
  ['/clinical/radiologist/report-based-worklist', ReportBasedWorklist],
  ['/clinical/ophthalmologist/worklist', OphthalmologyWorklist],
  ['/administrative/adminpanel/permissions', PermissionsAdminPanel],
  ["/administrative/adminpanel/areas", AreasAdminPanel],
  ["/administrative/adminpanel/centers", CentersAdminPanel],
  ["/administrative/adminpanel/roles", RolesAdminPanel],
  ["/administrative/adminpanel/users", UsersAdminPanel],
  ["/administrative/adminpanel/billing-codes", BillingCodesAdminPanel],
  ["/administrative/adminpanel/procedures", ProceduresAdminPanel],
  ["/administrative/adminpanel/zones", ZonesAdminPanel],
  ["/administrative/adminpanel/professionals", ProfessionalsAdminPanel],
  ["/administrative/adminpanel/specialities", SpecialitiesAdminPanel],
  ["/administrative/adminpanel/professional-schedule", ProfessionalSchedulesAdminPanel],
  ["/administrative/adminpanel/equipment-schedule", EquipmentSchedulesAdminPanel],
  ["/administrative/adminpanel/equipments", EquipmentsAdminPanel],
  ["/administrative/adminpanel/token-tags", TokenTagsAdminPanel],
  ["/administrative/adminpanel/templates", TemplatesAdminPanel],
  ["/administrative/adminpanel/dynamic-doctos", DynamicDoctosAdminPanel],
  ["/administrative/adminpanel/insurance-corps", InsuranceCorpsAdminPanel],
  ["/administrative/adminpanel/price-agreements", PriceAgreementsAdminPanel],
  ['/administrative/payments/cashier-sessions', CashierSessionsPayments],
  ['/administrative/payments/user-cashier-sessions', UserCashierSessions],
  ['/statistics/reportsproduction', ReportsProduction],
  ['/statistics/eventsproduction', EventsProduction],
]);


function RoutesControl({
  allowedRoutes,
  defaultRoute
}) {  

  const localIsMainPatientPageOpen = useReactiveVar(globalIsMainPatientPageOpen);

  const location = useLocation();

  useEffect(() => {
    globalIsMainPatientPageOpen(false);
  }, [location.pathname]);

  const displayAllowedMainRoutes = () => {

    const allowedMainRoutes = allowedRoutes.filter(route => {

      return mainRoutesMap.has(route);

    });

    return allowedMainRoutes.map(mainRoute => {

      const Component = mainRoutesMap.get(mainRoute);
      
      return (
        <Route
          exact
          key={mainRoute}
          path={mainRoute}
          //component={Component}
        >
          <Component />
        </Route>
      );

    });

  };

  const displayAllowedExactRoutes = () => {
    
    const allowedExactRoutes = allowedRoutes.filter(route => {

      return exactRoutesMap.has(route);

    });

    return allowedExactRoutes.map(exactRoute => {

      const Component = exactRoutesMap.get(exactRoute);
      
      return (
        
        <Route
          exact
          key={exactRoute}
          path={exactRoute}
          //component={Component}
        >
          <Component />
        </Route>
      );

    });

  };
  
  return (
    <>
      {
        localIsMainPatientPageOpen
         ? <Suspense fallback={<div>Updating view... Please wait... </div>}><PatientInformation /></Suspense>
         : <Suspense fallback={<div>Updating view... Please wait... </div>}>
            <Switch>
              {displayAllowedMainRoutes()}    
              {displayAllowedExactRoutes()}    
              <Redirect to={defaultRoute} />
              </Switch>
            </Suspense>
      }      
    </>
  )
}

export default RoutesControl;
