import React, { useEffect, useState } from 'react'
import { useReactiveVar, useMutation } from '@apollo/client';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import './App.css'
import './Shared/tailwind.css'
import Login from './Views/Login'
import { Spin, Result, Button, message } from 'antd'
import { getAccessKey, setAccessKey } from './setKey'
import { useLazyQuery } from '@apollo/client'
import {
  globalLoggedUserInfo,
  globalCurrentTenant,
  globalCurrentCenter,
  globalCurrentArea,
  globalAppointmentType,
  globalIsMainPatientPageOpen,
  globalMainPatientPageInputText,
  globalMainPatientPageResults,
  globalIsExpired,
  globalColors,
  globalColorMode,
  globalUserWorklistColumns
} from "./GraphQL/LocalState/globalState";
import Layout from './Layout';
import { ErrorBoundary } from 'react-error-boundary';
import { getMessageError } from './setMessagesError'

import { GET_USER_CONF, GET_USER_PREFERENCES_BY_WORKLIST, LOGOUT_USER } from './GraphQL/remoteQueries';
import { CREATE_FRONTEND_ERROR } from './GraphQL/mutations'

import RoutesControl from './Routes';
import { APP_WORKLISTS } from './Shared/Constants/worklistContext';

function App() {
  const [
    getUserConf,
    { data: dataGetUserConf }
  ] = useLazyQuery(GET_USER_CONF, { fetchPolicy: 'network-only' });

  const [
    logoutUserQuery,
    { client }
  ] = useLazyQuery(LOGOUT_USER, {
    fetchPolicy: 'no-cache', onCompleted: () => {
      message.destroy();
      setAccessKey(null);
      client.clearStore();
      globalLoggedUserInfo(null);
      //globalLoggedUserInfo_OLD(null);    
      globalCurrentTenant(null);
      globalCurrentCenter(null);
      globalCurrentArea(null);
      globalAppointmentType(null);
      globalIsMainPatientPageOpen(false);
      globalMainPatientPageInputText('');
      globalMainPatientPageResults(null);
      globalIsExpired(false)
      setShowLogin(true)
    }
  });

  const [showError, setShowError] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const loggedUser = useReactiveVar(globalLoggedUserInfo);
  const isExpired = useReactiveVar(globalIsExpired);

  //If cookie send it to BE to get access token
  useEffect(() => {
    fetch(process.env.REACT_APP_LOCAL_GMARS_URL || "https://blues.medphs.com/gmars", {
      method: "POST",
      credentials: "include"
    }).then(async x => {
      const { token } = await x.json();
      if (token) {
        //console.log('token', token)
        setAccessKey(token);
        //getUserInfo();  // ****** SERA ELIMINADO
        getUserConf();
        if (showLogin) setShowLogin(false)
      } else {
        setShowLogin(true)
      }
    }).catch(function (error) {
      setShowError(true)
    })
  }, []);

  useEffect(() => {

    if (isExpired) {
      message.error("La sesion ha expirado, redireccionando a login...", 5, () => logoutUserQuery())

    }

  }, [isExpired])


  useEffect(() => {
    if (dataGetUserConf && !loggedUser) {

      globalLoggedUserInfo(dataGetUserConf.getUserConf);

      const loggedUserId = dataGetUserConf.getUserConf?._id

      const fetchUserPreferences = async (userId) => {

        try {

          const preferencesByWorklistPromises = APP_WORKLISTS.map(worklistName => {

            return client.query({
              query: GET_USER_PREFERENCES_BY_WORKLIST,
              variables: {
                userID: userId,
                worklist: worklistName
              },
              fetchPolicy: 'network-only'
            })

          })

          const responses = await Promise.all(preferencesByWorklistPromises)

          const userColumnsData = responses.reduce((columnsData, response, index) => {

            const worklist = APP_WORKLISTS[index]

            if (!response?.data?.getUserPreferencesByWorklist) return columnsData

            const { __typename, ...columnData } = response.data.getUserPreferencesByWorklist

            return {
              ...columnsData,
              [worklist]: columnData
            }

          }, {})

          globalUserWorklistColumns(userColumnsData)

        } catch (error) {

          console.log('***** UNABLE TO FETCH USER PREFERENCES ****')

        }


      }

      fetchUserPreferences(loggedUserId)

    };
    //eslint-disable-next-line
  }, [dataGetUserConf]);

  return (
    <Router>
      {getAccessKey() ? (loggedUser ? (
        <LoginRoutes
          loggedUser={loggedUser}
          logoutUserQuery={logoutUserQuery}
          setShowLogin={setShowLogin}
        />
      ) : showError ? (<Result status="500" title="500" subTitle="Sorry, We hope to have this resolved soon. Thank you for your patience." extra={<Button type="primary" onClick={() => window.location.reload()}>Reload</Button>}
      />) : (<div style={{ textAlign: "center", padding: "25%" }} className='bg-gray-300'><Spin className='bg-gray-300' tip="Iniciando RetinaRAD Desktop..." size="large" /></div>)) : showLogin ? (
        <LogoutRoutes
          //login={Login}
          getUserConf={getUserConf}
        />
      ) : showError ? (<Result status="500" title="500" subTitle="Sorry, We hope to have this resolved soon. Thank you for your patience." extra={<Button type="primary" onClick={() => window.location.reload()}>Reload</Button>}
      />) : (<div style={{ textAlign: "center", padding: "25%" }} className='bg-gray-300'><Spin className='bg-gray-300' tip="Iniciando RetinaRAD Desktop..." size="large" /></div>)}
    </Router>
  )
}



function ErrorFallback({ error, resetErrorBoundary }) {

  //console.log("error: ", error)

  const [createFrontendError, { loading: loadingFrontEndError, error: errorFrontEndError, data: dataFrontEndError, called: calledFrontendError }] = useMutation(CREATE_FRONTEND_ERROR);

  useEffect(() => {
    if (errorFrontEndError) {
      message.error(getMessageError(), 5);
      resetErrorBoundary()
    }
  }, [errorFrontEndError]);

  useEffect(() => {
    if (calledFrontendError && dataFrontEndError && dataFrontEndError.createFrontendError._id) {
      message.success("Reporte enviado existosamente", 5);
      resetErrorBoundary()
    } else if (calledFrontendError && dataFrontEndError && !dataFrontEndError.createFrontendError._id) {
      message.error("No se logro enviar reporte", 5);
      resetErrorBoundary()
    }
  }, [dataFrontEndError]);

  const handleSendError = () => {
    createFrontendError({
      variables: {
        input: {
          error: error.message,
          system_version: 'RETINARX_DESKTOP v1.84.0001.001',
          text: error.stack
        }
      }
    })
  }




  return (
    <Result status="500" title="505" subTitle="Ocurrio un error inesperado mientras se procesaba tu solicitud. Por favor reporta este problema presionando el boton." extra={<Button loading={loadingFrontEndError} type="primary" onClick={() => { handleSendError() }}>Reportar error y reiniciar RetinaRAD Desktop</Button>}
    />
  )
}


function LoginRoutes({
  loggedUser,
  logoutUserQuery,
  setShowLogin,
  // backColor,
  // setBackColor,
  // iconClass,
  // setIconClass,
  // colorMode,
  // setColorMode
}) {

  const { defaultRoute } = loggedUser;

  const allowedRoutes = loggedUser.enabledRoutes.map(routeObject => {

    return routeObject.route;

  });

  const GLOBAL_COLORS_LIGHT = {
    menu_icon: 'menu_icon_light',
    color_mode: 'light',
    back_color: 'bg-gray-300',
    text_header_color: 'text-gray-700',
    anticon: 'grey',
    key_text_color: '#4a5568',
    styled_dropdown_container: 'styled-dropdown-container-light',
    back_dropdown_menu_color: 'white',
    styled_center_name: 'styled-center-name-light',
    styled_patient_name: 'styled-patient-name-light',
    styled_patient_rut: 'styled-patient-rut-light',
    styled_patient_sex: 'styled-patient-sex-light',
    styled_patient_age: 'styled-patient-age-light',
    styled_patient_address: 'styled-patient-address-light',
    styled_patient_email: 'styled-patient-email-light',
    styled_doctor_name: 'styled-doctor-name-light',
    styled_procedure_name: 'styled-procedure-name-light',
    styled_date_time: 'styled-date-time-light',
    styled_event_uuid: 'styled-event-uuid-light',
    styled_visit_number: 'styled-visit-number-light',
    styled_range_picker_text_input: 'styled-range-picker-text-input-light',
    table_background: undefined
  }

  const GLOBAL_COLORS_DARK = {
    menu_icon: 'menu_icon_dark',
    color_mode: 'dark',
    back_color: 'bg-gray-900',
    text_header_color: 'text-gray-500',
    anticon: 'white',
    key_text_color: 'white',
    styled_dropdown_container: 'styled-dropdown-container-dark',
    back_dropdown_menu_color: '#4e5256',
    styled_center_name: 'styled-center-name-dark',
    styled_patient_name: 'styled-patient-name-dark',
    styled_patient_rut: 'styled-patient-rut-dark',
    styled_patient_sex: 'styled-patient-sex-dark',
    styled_patient_age: 'styled-patient-age-dark',
    styled_patient_address: 'styled-patient-address-dark',
    styled_patient_email: 'styled-patient-email-dark',
    styled_doctor_name: 'styled-doctor-name-dark',
    styled_procedure_name: 'styled-procedure-name-dark',
    styled_date_time: 'styled-date-time-dark',
    styled_event_uuid: 'styled-event-uuid-dark',
    styled_visit_number: 'styled-visit-number-dark',
    styled_range_picker_text_input: 'styled-range-picker-text-input-dark',
    table_background: "#242c3c"
  }

  globalColors(GLOBAL_COLORS_LIGHT)
  globalColorMode('light')

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}
      onReset={() => {
        logoutUserQuery()
      }}>
      <Layout setShowLogin={setShowLogin} >
        <Redirect to={defaultRoute} />
        <RoutesControl allowedRoutes={allowedRoutes} defaultRoute={defaultRoute} />
      </Layout>
    </ErrorBoundary>
  )

}

function LogoutRoutes({ getUserConf }) {
  return (
    <>
      {/* <Redirect to='/' /> */}
      <Switch>
        <Route
          path='/'
          render={props => (
            <Login
              {...props}
              getUserConf={getUserConf}
            ></Login>
          )}
        //default
        ></Route>
      </Switch>
    </>
  )
}

export default App
