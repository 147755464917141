import { gql } from '@apollo/client'

export const GET_TENANTS = gql`
  query GetTenants($search: String) {
    getTenants(search: $search) {
      _id
      internal_number
      altern_number
      status
      legal_id_number
      legal_name
      social_name
      head_office_address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
        latitude
        longitude
      }
      mail_address
      cel_phone
      assoc_insuranceCorpsID
      assoc_insuranceCorps_detail {
        _id
        name
        type
      }
    }
  }
`

export const GET_PERSONS = gql`
  query GetPersons($search: String) {
    persons(search: $search) {
      _id
      status
      person_legal_name {
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
      ssn_personintId
      ssn_issuer
      sex
      cel_phone
      home_phone
      birth_date
      mail_address
      address {
        street
        street_number
        city
        country_name
      }
    }
  }
`

export const GET_PERSON_BY_ID = gql`
  query GetPersonById($id: ID) {
    getPersonByID(id: $id) {
      _id
      status
      ssn_issuer
      legal_name {
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
      ssn_personintId
      birth_date
      sex
      sex_code
      mail_address
      cel_phone
      home_phone
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      insurance_type
      insurance_detail
      insurance_company
      insuranceCompanyID
    }
  }
`

export const GET_PERSON_EVENTS = gql`
  query GetPersonEvents($personID: ID!, $pageNumber: Int, $limit: Int, $tenantsID: [ID]) {
    getPersonEvents(
      personID: $personID
      pageNumber: $pageNumber
      limit: $limit
      tenantsID: $tenantsID
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        patientID
        patient_doc_id
        event_status
        eventID
        event_uuid
        event_date
        event_time
        event_type
        procedures_names
        professionals_names
        equipments_names
        centers_names
      }
    }
  }
`

export const GET_PERSON_CASH_EVENTS = gql`
  query GetPersonCashEvents($personID: ID, $tenantsID: [ID]) {
    getPersonCashEvents(
      personID: $personID      
      tenantsID: $tenantsID
    ) {
      patientID
    	patient_doc_id
    	event_status
    	eventID
    	event_uuid
    	event_altern_uuid
      event_date
      event_time
      event_type
      centers_names
      procedures_data {
       	name
        code
        type
        description
        billing_codes {
          billingCodeID
          quantity
        }
      }
    }
  }
`

export const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      _id
      internal_number
      name
      division {
        _id
        detailId
      }
      phone_code
      primary_lang
    }
  }
`

export const GET_LEVELS_DATA = gql`
  query CountryDivDetailLevelsAll(
    $search: String
    $countrysDivisions: countrysDivisionsInput
  ) {
    countryDivsDetailLevelsAll(
      search: $search
      countrysDivisions: $countrysDivisions
    ) {
      _id
      level1
      level2
      level3
    }
  }
`

export const GET_PRO_BY_AUTO_COMPLETE = gql`
  query GetProByAutoComplete($search: String, $tenantsID: [ID]) {
    getProByAutocomplete(search: $search, tenantsID: $tenantsID) {
      _id
      assigned_specialities {
        _id
        name
      }
      assigned_person {
        _id
        age
        person_legal_name {
          first_name
          second_name
          surname
          mother_maiden
        }
      }
    }
  }
`

export const GET_SPECIALITIES_BY_TYPE = gql`
  query GetSpecialitiesByType($search: String!, $type: String!, $tenantID: ID) {
    getSpecialitiesByType(search: $search, type: $type, tenantID: $tenantID) {
      _id
      name
      search_type
      assigned_procedures {
        _id
        name
        code
        minutes
        is_DICOM
        DICOM_type
        billingCodes {
          billingCodeID
          quantity
        }
      }
    }
  }
`

export const SEARCH_SLOTS_BY_PRO = gql`
  query SearchSlotsByPro(
    $professionalID: ID
    $startDate: String
    $endDate: String
    $limit: Int
    $pageNumber: Int
    $tenantID: ID
  ) {
    searchSlotsByPro(
      professionalID: $professionalID
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      pageNumber: $pageNumber
      tenantID: $tenantID
    ) {
      totalDaysQ
      slotsQInResponse
      cursor
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        day_slotID
        active
        day
        appointments_types
        overbooking
        overbooking_Q
        specialities {
          _id
          name
          altern_name
          code
        }
        professional {
          _id
          assigned_person {
            _id
            age
            person_legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        procedures {
          _id
          status
          name
          altern_name
          code
        }
        centers {
          _id
          legal_name
          social_name
          address {
            street
            street_number
            city
            city2
            city3
            country_name
          }
        }
        tenant
        zones {
          _id
          name
        }
        created_slot {
          created_slotID
          resource_types
          appointments_types
          minutes
          time_from
          time_to
          time_am_pm
          status
          resource_types
        }
      }
    }
  }
`

export const GET_PERSON_BY_ID_FOR_EDIT = gql`
  query GetPersonByID($id: ID) {
    getPersonByID(id: $id) {
      _id
      uuid
      is_imported
      status
      origin_system
      ssn_issuer
      legal_name {
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
      ssn_personintId
      birth_date
      sex
      sex_code
      mail_address
      cel_phone
      home_phone
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      insurance_type
      insurance_detail
      insurance_company
      insuranceCompanyID
    }
  }
`

export const GET_CENTERS = gql`
  query GetCenters($search: String, $arrTenantsID: [ID]) {
    getCenters(search: $search, arrTenantsID: $arrTenantsID) {
      _id
      social_name
      legal_name
      legal_id_number
    }
  }
`

export const SEARCH_SLOTS_BY_CENTER = gql`
  query SearchSlotsByCenter(
    $centerID: ID
    $proceduresID: arrayTypeID
    $startDate: String
    $endDate: String
    $limit: Int
    $pageNumber: Int
  ) {
    searchSlotsByCenterOrdByTime(
      centerID: $centerID
      proceduresID: $proceduresID
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      pageNumber: $pageNumber
    ) {
      totalDaysQ
      slotsQInResponse
      cursor
      hasMoreData
      totalPages
      pageNumber
      center {
        _id
        tenantID
        status
        legal_id_number
        legal_name
        social_name
        initials
        address {
          street
          street_number
          city
          city2
          city3
          country_name
          latitude
          longitude
        }
      }
      queryResults {
        day
        created_slots {
          time_from
          procedure {
            _id
            status
            name
            code
            billingCodes {
              _id
              status
              name
              code
            }
          }
          createdSlot {
            created_slotID
            daySlotID
            equipment {
              _id
              assigned_centersID
              name
              alias
              location
              status
            }
            professional {
              _id
              status
              legal_name {
                first_name
                surname
                mother_maiden
              }
              sched_specialities {
                _id
                name
                code
              }
            }
            equipment {
              alias
              assigned_centersID
              location
              name
              is_dicom_comp
              dicom_modality     
              dicom_mwl_aetitle  
            }
            appointments_types
            resource_types
            patient_types
            tenantID
            centerID
            specialityID
            procedures {
              _id
              name
              status
              code
              is_DICOM
              DICOM_type
            }
            minutes
            time_from
            time_to
            time_am_pm
            status
          }
        }
      }
    }
  }
`

export const SEARCH_SLOTS_BY_TELEMEDICINE = gql`
  query SearchSlotsByTelemedicine(
    $tenantsID: [ID]
    $proceduresID: arrayTypeID
    $startDate: String
    $endDate: String
    $limit: Int
    $pageNumber: Int
  ) {
    searchSlotsByTelemedOrdByTime(
      tenantsID: $tenantsID
      proceduresID: $proceduresID
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      pageNumber: $pageNumber
    ) {
      totalDaysQ
      slotsQInResponse
      cursor
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        day
        created_slots {
          time_from
          procedure {
            _id
            status
            name
            code
            billingCodes {
              _id
              status
              name
              code
            }
          }
          tenant {
            _id
            status
            legal_id_number
            legal_name
            social_name
          }
          createdSlot {
            created_slotID
            daySlotID
            professional {
              _id
              status
              sex
              sex_code
              age
              legal_name {
                first_name
                surname
                mother_maiden
              }
              sched_specialities {
                _id
                name
                code
              }
            }
            appointments_types
            resource_types
            patient_types
            tenantID
            specialityID
            procedures {
              _id
              name
              status
              code
            }
            minutes
            time_from
            time_to
            time_am_pm
            status
          }
        }
      }
    }
  }
`

export const SEARCH_SLOTS_BY_DOMICILIARY = gql`
  query SearchSlotsByDomiciOrdByTime(
    $zonesID: [ID]
    $proceduresID: arrayTypeID
    $startDate: String
    $endDate: String
    $limit: Int
    $pageNumber: Int
  ) {
    searchSlotsByDomiciOrdByTime(
      zonesID: $zonesID
      proceduresID: $proceduresID
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      pageNumber: $pageNumber
    ) {
      totalDaysQ
      slotsQInResponse
      cursor
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        day
        created_slots {
          time_from
          procedure {
            _id
            status
            name
            code
            billingCodes {
              _id
              status
              name
              code
            }
          }
          zones {
            _id
            status
            name
            tenantID
          }
          createdSlot {
            created_slotID
            daySlotID
            professional {
              _id
              status
              sex
              sex_code
              age
              legal_name {
                first_name
                surname
                mother_maiden
              }
              sched_specialities {
                _id
                name
                code
              }
            }
            appointments_types
            resource_types
            patient_types
            tenantID
            specialityID
            procedures {
              _id
              name
              status
              code
            }
            minutes
            time_from
            time_to
            time_am_pm
            status
          }
        }
      }
    }
  }
`

export const GET_PERMISSIONS = gql`
  query GetPermissions($search: String) {
    getPermissions(search: $search) {
      _id
      name
      internal_number
      op_code
      status
      detail
      system
      created_by
      created_at
    }
  }
`

export const GET_AREAS = gql`
  query GetAreas($search: String) {
    getAreas(search: $search) {
      _id
      name
      tenantID
      proceduresID
      professionalsID
      internal_number
      detail
      status
      worklist_states
      system
      created_by
    }
  }
`

export const GET_TENANTS_ALL = gql`
  query GetTenantsAll {
    getTenants {
      _id
      legal_name
      legal_id_number
      social_name
      status
    }
  }
`

export const GET_PROCEDURES = gql`
  query GetProcedures($tenantID: ID) {
    getProcedures(tenantID: $tenantID) {
      _id
      name
      code
      altern_code
      ext_code
      status
    }
  }
`

export const GET_CENTERS2 = gql`
  query GetCenters2($search: String, $tenantID: ID) {
    getCenters(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      altern_number
      ext_number
      areasID
      status
      license_number
      legal_id_number
      legal_name
      social_name
      initials
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      zones
      mail_address
    }
  }
`

export const GET_ZONES = gql`
  query GetZones {
    getZones {
      _id
      name
      tenantID
      internal_number
    }
  }
`

export const GET_AREAS_BY_TENANTID = gql`
  query GetAreas($tenantID: ID!) {
    getAreas(tenantID: $tenantID) {
      _id
      name
      tenantID
      proceduresID
      professionalsID
      internal_number
      worklist_states
      detail
      status
      system
      created_by
    }
  }
`

export const GET_ROLES = gql`
  query GetRoles($search: String, $tenantID: ID) {
    getRoles(search: $search, tenantID: $tenantID) {
      _id
      name
      internal_number
      tenantID
      centersID
      areasID
      permissionsID
      status
      detail
      valid_for
      frontend_routes
      appointment_types
    }
  }
`

export const GET_USERS = gql`
  query GetUsers(
    $search: String
    $tenantID: ID
    $pagination: paginationPageInput
  ) {
    getUsers(search: $search, tenantID: $tenantID, pagination: $pagination) {
      totalUsersQ
      usersQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        internal_number
        tenantsID
        type
        assigned_personID
        assigned_professionalID
        isClinical
        origin_system
        status
        uuid
        alias
        assigned_rolesID
        override_permissionsID
        default_roleID
        default_frontend_route
        default_frontend_route_tenantID
        default_frontend_route_centerID
        default_frontend_route_areaID
        pass_last_change
        pass_must_change
        isCashier
        isCashierSupervisor
      }
    }
  }
`

export const GET_EVENTS_ADMIN_WL = gql`
  query GetEventsAdminWl(
    $equipmentsID: [ID]
    $professionalsID: [ID]
    $proceduresID: [ID]
    $centersID: [ID]
    $eventStatus: [String]
    $appointmentTypes: [String]
    $tenantID: ID
    $dateFromStr: String
    $dateToStr: String
    $search: String
    $recordsPerPage: Int
    $pageNumber: Int
    $includeImportedEvents: Boolean
    $importedEventsOnly: Boolean
    ) {
    getEventsAdmWL(
      equipmentsID: $equipmentsID
      professionalsID: $professionalsID
      proceduresID: $proceduresID
      centersID: $centersID
      eventStatus: $eventStatus
      appointmentTypes: $appointmentTypes
      tenantID: $tenantID
      dateFromStr: $dateFromStr
      dateToStr: $dateToStr
      search: $search
      recordsPerPage: $recordsPerPage
      pageNumber: $pageNumber
      includeImportedEvents: $includeImportedEvents
      importedEventsOnly: $importedEventsOnly
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        tenantID
        isImported
        internal_number
        accession_number
        uuid
        altern_uuid
        creation_source
        creation_channel
        groupID
        reception_comment
        patient_type
        priority
        date_string
        time_string
        status
        recall
        payment_status
        added_minutes
        default_duration
        resource_types
        appointment_reason
        paymentAccountID
        referring_professionalID
        referring_phy_number
        referring_phy_name
        referring_center_name
        referring_centerID
        referring_area
        visit_number
        created_at
        DICOM_requested_procedures {
          group_case
          group_code_value
          group_assigned_radiologists_proID
          group_description
          sched_procedures_steps {
            _id
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
        }
        patient {
          _id
          status
          ssn_personintId
          mail_address
          birth_date
          age
          sex
          address {
            street
            street_number
            city
            country_name
          }
          legal_name {
            first_name
            second_name
            surname
            mother_maiden
          }
        }
        procedures {
          _id
          status
          name
          code
          billingCodes {
            _id
            status
            name
            altern_name
            code
            altern_code
          }
          specialities {
            _id
            name
            search_type
          }
        }
        professionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        equipments {
          _id
          name
          location
        }
        attached_FILES {
          _id
          type
          classification
          isDeleted
        }
        isConfirmed
        confirm_actions {
          confirmed_action_date
          userID
          confirmed_action_channel
          confirmed_action_detail
          confirmedbypatient
      }
    }
  }
}
`

export const GET_BILLING_CODES = gql`
  query GetBillingCodes($search: String, $tenantID: ID) {
    getBillingCodes(search: $search, tenantID: $tenantID) {
      _id
      status
      name
      tenantID
      altern_name
      internal_number
      code
      code_issuer
      altern_code
      ext_code
      ext_name
    }
  }
`

export const GET_BILLING_CODES_BY_CENTER = gql`
  query GetBillingCodesByCenter($centerID: ID) {
    getBillingCodesByCenter(centerID: $centerID) {
      _id
      status
      name
      tenantID
      altern_name
      internal_number
      code
      code_issuer
      altern_code
      ext_code
      ext_name
    }
  }
`

export const GET_PROCEDURES2 = gql`
  query GetProcedures($search: String, $tenantID: ID) {
    getProcedures(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      status
      name
      altern_name
      internal_number
      code
      altern_code
      ext_name
      ext_code
      billingCodes {
        _id
        quantity
      }
      is_DICOM
      DICOM_type
      minutes
      appointment_types
      req_preparation_minutes
      send_email_to_pat
      instructions_to_patient
      assoc_instructions_docID
    }
  }
`

export const GET_ZONES2 = gql`
  query GetZones($search: String, $tenantID: ID) {
    getZones(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      name
      internal_number
      created_by
      created_at
    }
  }
`

export const GET_PROFESSIONALS = gql`
  query GetProfessionals($search: String, $tenantID: ID) {
    getProfessionals(search: $search, tenantID: $tenantID) {
      _id
      personID
      personName
      assigned_tenantsID
      assigned_centersID
      internal_number
      altern_number
      uuid
      legal_data {
        college
        year
        title
        specialities
        record
      }
      type
      title
      status
      document_number
      sched_specialitiesID
      sched_proceduresID
      created_by
      created_at
    }
  }
`

export const GET_SPECIALITIES = gql`
  query GetSpecialities($search: String, $tenantID: ID) {
    getSpecialities(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      status
      name
      search_type
      altern_name
      internal_number
      code
      altern_code
      proceduresID
    }
  }
`

export const GET_PERSONS_ADMIN = gql`
  query GetPersons($search: String) {
    getPersons(search: $search) {
      _id
      uuid
      is_imported
      status
      origin_system
      ssn_issuer
      legal_name {
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
      ssn_personintId
      birth_date
      sex
      sex_code
      mail_address
      cel_phone
      home_phone
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      insurance_type
      insurance_detail
      insurance_company
      insuranceCompanyID
    }
  }
`

export const GET_MD_INSURANCE_CORP = gql`
  query GetMDInsuranceCorp($search: String) {
    getMDinsuranceCorp(search: $search) {
      _id
      name
      code
      legal_id_number
      ext_code
      internal_number
      status
      detail
    }
  }
`

export const GET_AREAS_COMPLETE = gql`
  query GetAreas($search: String, $tenantID: ID) {
    getAreas(search: $search, tenantID: $tenantID) {
      _id
      name
      tenantID
      proceduresID
      professionalsID
      internal_number
      include_imported_events
      imported_events_only
      worklist_states
      detail
      status
      system
      appointment_types
    }
  }
`

export const GET_PERSONS_ADMIN_SMALL = gql`
  query GetPersons($search: String) {
    getPersons(search: $search) {
      _id
      legal_name {
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
      ssn_personintId
      birth_date
    }
  }
`

export const GET_PROFESSIONALS_ADMIN_SMALL = gql`
  query GetProfessionals($search: String, $tenantID: ID) {
    getProfessionals(search: $search, tenantID: $tenantID) {
      _id
      personID
      assigned_tenantsID
      assigned_centersID
      internal_number
      sched_specialitiesID
      sched_proceduresID
    }
  }
`

export const GET_PERSON_BY_ID_ADMIN_SMALL = gql`
  query GetPersonById($id: ID) {
    getPersonByID(id: $id) {
      _id
      status
      legal_name {
        first_name
        surname
        mother_maiden
      }
      ssn_personintId
      birth_date
      sex
      sex_code
      mail_address
      cel_phone
      home_phone
    }
  }
`

export const GET_PROCEDURES_BY_PRO_AND_TENANT = gql`
  query GetProceduresByProAndTenant($professionalID: ID, $tenantID: ID) {
    getProcsByProAndTenant(
      professionalID: $professionalID
      tenantID: $tenantID
    ) {
      _id
      tenantID
      status
      name
      internal_number
      code
      altern_code
      billingCodesID
      minutes
    }
  }
`

export const GET_PROCEDURES_BY_EQUIP_AND_TENANT = gql`
  query GetProceduresByProAndTenant($equipmentID: ID, $tenantID: ID) {
    getProcsByEquipAndTenant(equipmentID: $equipmentID, tenantID: $tenantID) {
      _id
      tenantID
      status
      name
      internal_number
      code
      altern_code
      billingCodesID
      minutes
    }
  }
`

export const GET_SLOTS_CREATION_INFO = gql`
  query GetSlotsCreationInfo(
    $professionalID: ID
    $tenantsID: [ID]
    $limit: Int
    $pageNumber: Int
  ) {
    getSlotsCreationInfo(
      professionalID: $professionalID
      tenantsID: $tenantsID
      limit: $limit
      pageNumber: $pageNumber
    ) {
      totalRecords
      recordsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        dateFromStr
        dateToStr
        daysOfTheWeek
        tenantsID
        active
        resource_types
        professionalID
        equipmentID
        daySlotsID
        ranges {
          range_id
          dayOfTheWeek
          uuid
          timeFrom
          timeTo
          override_proc_duration
          overrided_range_minutes
          appointment_types
          insurancesID
          tenantID
          zonesID
          centerID
          specialitiesID
          proceduresID
          patient_type
          domiciliary
          overbooking
          overbooking_Q
          created_slotsID
        }
      }
    }
  }
`

export const GET_SLOTS_CREATION_INFO_EQUIP = gql`
  query GetSlotsCreationInfoEquip(
    $equipmentID: ID
    $tenantsID: [ID]
    $limit: Int
    $pageNumber: Int
  ) {
    getSlotsCreationInfoEquip(
      equipmentID: $equipmentID
      tenantsID: $tenantsID
      limit: $limit
      pageNumber: $pageNumber
    ) {
      totalRecords
      recordsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        dateFromStr
        dateToStr
        daysOfTheWeek
        tenantsID
        active
        resource_types
        professionalID
        equipmentID
        daySlotsID
        ranges {
          range_id
          dayOfTheWeek
          uuid
          timeFrom
          timeTo
          override_proc_duration
          overrided_range_minutes
          appointment_types
          insurancesID
          tenantID
          zonesID
          centerID
          specialitiesID
          proceduresID
          patient_type
          domiciliary
          overbooking
          overbooking_Q
          created_slotsID
        }
      }
    }
  }
`

export const GET_PROFESSIONALS_FOR_SEARCH = gql`
  query GetProfessionals($search: String, $tenantID: ID) {
    getProfessionals(search: $search, tenantID: $tenantID) {
      _id
      personID
    }
  }
`

export const GET_CENTERS_FOR_SEARCH = gql`
  query GetCenters2($search: String, $tenantID: ID) {
    getCenters(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      legal_id_number
      legal_name
      social_name
    }
  }
`

export const GET_PROC_PREPARATIONS = gql`
  query GetProcPreparations($procedureID: ID) {
    getProcPreparations(procedureID: $procedureID) {
      _id
      name
      code
      tenant_social_name
      tenantID
      instructions_to_patient
    }
  }
`

export const GET_EQUIPMENTS = gql`
  query GetEquipments($search: String, $tenantID: ID) {
    getEquipments(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      assigned_tenantsID
      assigned_centersID
      name
      alias
      initials
      location
      is_dicom_comp
      ipv4_addr
      ipv6_addr
      dicom_modality
      dicom_qr_aetitle
      dicom_mwl_aetitle
      dicom_qr_port
      dicom_mwl_port
      internal_number
      altern_number
      ext_number
      status
      sched_specialitiesID
      sched_proceduresID
      override_proceduresTime {
        procedureID
        timeInMinutes
      }
    }
  }
`

export const GET_EQUIPMENTS_FOR_SEARCH = gql`
  query GetEquipments($search: String, $tenantID: ID) {
    getEquipments(search: $search, tenantID: $tenantID) {
      _id
      name
      tenantID
      assigned_tenantsID
      assigned_centersID
      sched_specialitiesID
      sched_proceduresID
      override_proceduresTime {
        procedureID
        timeInMinutes
      }
    }
  }
`

export const GET_CENTERS_BY_TENANTS = gql`
  query GetCentersByTenants($search: String, $arrTenantsID: [ID]) {
    getCenters(search: $search, arrTenantsID: $arrTenantsID) {
      _id
      tenantID
      altern_number
      ext_number
      areasID
      status
      license_number
      legal_id_number
      legal_name
      social_name
      initials
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      zones
      mail_address
    }
  }
`

export const GET_TOKEN_TAGS = gql`
  query GetTokenTags($search: String, $tenantID: ID) {
    getTokenTags(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      name
      description
      internal_number
      type
      status
      display_selector
      schemaName
      collectionName
      fieldName
    }
  }
`

export const GET_TEMPLATES = gql`
  query GetTemplates($search: String, $tenantID: ID, $type: String!) {
    getTemplates(search: $search, tenantID: $tenantID, type: $type) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      isStructural
      owner_userID
      status
      isRad 
      reportProcedures
      reportRadModality
      content
      core_type
    }
  }
`

export const GET_CASHIER_SESSIONS_BY_PERSON_AS_XLS = gql`
  query GetCashierSessionsByPersonAsXLS($cashierSessionID: ID!) {
    getCashierSessionsByPersonAsXLS(cashierSessionID: $cashierSessionID) {
      name
      fileRaw
      MIME_Type
    }
  }
`

export const GET_DYNAMIC_DOCTOS = gql`
  query GetDynamicDoctos($search: String, $tenantID: ID, $type: String!) {
    getDynamicDoctos(search: $search, tenantID: $tenantID, type: $type) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      owner_userID
      status
      isRad 
      reportProcedures
      reportRadModality
      content
    }
  }
`

export const GET_PDF_DOCTO = gql`
  query GetPDFDocto(
    $documentID: ID
    $procedureID: ID
    $personID: ID
    $eventID: ID
    $paymentAccID: ID
  ) {
    getPDFDocto(
      documentID: $documentID
      procedureID: $procedureID
      personID: $personID
      eventID: $eventID
      paymentAccID: $paymentAccID
    ) {
      name
      MIME_Type
      fileRaw
    }
  }
`

export const GET_ZONES_BY_TENANT = gql`
  query GetZonesByTenant($tenantsIDs: [ID]!) {
    getZonesByTenants(tenantsIDs: $tenantsIDs) {
      _id
      name
      tenantID
      internal_number
    }
  }
`

export const GET_MD_INSURANCE_CORP_NEW = gql`
  query GetMDInsuranceCorp($search: String, $tenantID: ID) {
    getMDinsuranceCorp(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      type
      type_other_detail
      name
      code
      legal_id_number
      ext_code
      internal_number
      status
      detail
    }
  }
`

export const GET_PRICE_AGREEMENTS_BASIC = gql`
  query GetPriceAgreements($search: String, $tenantID: ID) {
    getPriceAgreements(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      status
      centersID
      agreement_type
      insuranceCorpID
      name
      altern_name
      internal_number
      code
      altern_code
      ext_code
      ext_name
    }
  }
`

export const GET_PRICE_AGREEMENTS = gql`
  query GetPriceAgreements($search: String, $tenantID: ID) {
    getPriceAgreements(search: $search, tenantID: $tenantID) {
      _id
      tenantID
      status
      centersID
      agreement_type
      insuranceCorpID
      name
      altern_name
      internal_number
      code
      altern_code
      list {
        prlist_id
        status
        type
        billingCodeID
        other_code
        price
        price_tax
        total_price
        default_price_coverage
        price_accepts_dscnt
        ISO4217
      }
    }
  }
`

export const GET_BILLING_CODES_SEARCH = gql`
  query GetBillingCodes($search: String, $tenantID: ID) {
    getBillingCodes(search: $search, tenantID: $tenantID) {
      _id
      name
      tenantID
      code
    }
  }
`

export const GET_USER_INFO = gql`
  query GetUserInfo($userID: ID) {
    getUserInfo(userID: $userID) {
      _id
      personName
      userName
      userDefaultProfile
      tenants {
        _id
        status
        legal_id_number
        legal_name
        social_name
      }
    }
  }
`

export const GET_USER_CONF = gql`
  query GetUserConf($userID: ID) {
    getUserConf(userID: $userID) {
      _id
      status
      isBlackListed
      defaultRoute
      personName
      lastLogin
      userLogin
      personID
      VRInfo
      professionalID
      defaultTenant {
        _id
        RUT
        social_name
      }
      defaultCenter {
        _id
        RUT
        social_name 
        initials
      }
      defaultArea {
        _id
        appointment_types
        name
        details
        proceduresID
        professionalsID
        include_imported_events
        imported_events_only
        worklist_states
      }      
      enabledRoutes {
        route
        assignedAppointmentTypes
        assignedTenants {
          tenant_id
          tenantName
          tenantRUT
        }
        assignedCenters {
          center_id
          centerSocialName
          centerTenantID
          centerInitials
          centerRut
          centerAllAreas
          centerAccessUUID
        }
        assignedAreas {
          area_id
          areaAppointmentTypes
          areaName
          areaTenantID
          areaProceduresID
          areaProfessionalsID
          areaImportedEventsOnly
          areaIncludeImportedEvents
          areaWorklistStates
          areaDetail
        }
        assignedPermissions {
          permission_id
          permissionName
          permissionDetail
        }
      }
    }
  }
`

export const GET_CASHIER_SESSIONS = gql`
  query GetCashierSessions($search: String, $tenantID: ID) {
    getCashierSessions(search: $search, tenantID: $tenantID) {
      _id
      internal_number
      uuid
      tenantID
      userID
      open_by_userID
      open_time
      closed_by_userID
      closed_time
      initial_amounts {
        currency_denomination
        amount
      }
      total_initial_amount
      payment_movements {
        type
        paymentAccountID
        paylisID
        comments
      }
      closed_amounts {
        payment_method
        amount
      }
      total_closed_amount
      status
      comments
      close_reason
    }
  }
`

export const GET_USER_CONF_FOR_SEARCH = gql`
  query GetUserInfo($userID: ID) {
    getUserInfo(userID: $userID) {
      _id
      personName
    }
  }
`

export const GET_CENTER_SPECIALITIES = gql`
  query GetCenterSpecialities($centerID: ID, $type: String) {
    getCenterSpecialities(centerID: $centerID, type: $type) {
      _id
      tenantID
      status
      name
      search_type
      altern_name
      internal_number
      code
      altern_code
      ext_code
      ext_name
      proceduresID
      assigned_procedures {
        _id
        name
        code
        minutes
      }
    }
  }
`

export const GET_PRO_BY_AUTO_COMPLETE2 = gql`
  query GetProByAutoComplete(
    $search: String
    $tenantsID: [ID]
    $centersID: [ID]
    $specialitiesID: [ID]
    $proceduresID: [ID]
  ) {
    getProByAutocomplete(
      search: $search
      tenantsID: $tenantsID
      centersID: $centersID
      specialitiesID: $specialitiesID
      proceduresID: $proceduresID
    ) {
      _id
      assigned_specialities {
        _id
        name
      }
      assigned_person {
        _id
        age
        ssn_personintId
        person_legal_name {
          first_name
          second_name
          surname
          mother_maiden
        }
      }
    }
  }
`

export const GET_EQUIPMENTS_BY_AUTO_COMPLETE = gql`
  query GetEquipmentsByAutoComplete(
    $search: String
    $tenantsID: [ID]
    $centersID: [ID]
    $specialitiesID: [ID]
    $proceduresID: [ID]
  ) {
    getEquipmentsByAutocomplete(
      search: $search
      tenantsID: $tenantsID
      centersID: $centersID
      specialitiesID: $specialitiesID
      proceduresID: $proceduresID
    ) {
      _id
      name
      alias
      initials
      location
      is_dicom_comp
      assigned_specialities {
        _id
        name
        search_type
        altern_name
      }
    }
  }
`

export const GET_EVENTS_DETAILS_FOR_PAYACC = gql`
  query GetEventsDetailsForPayacc($eventsID: [ID]) {
    getEventsDetailsForPayacc(eventsID: $eventsID) {
      _id
      day
      time
      status
      payment_status
      patient {
        _id
        name
        ssn_ident
        insurances {
          _id
          name
          ext_code
          tenantID
          legal_id_number
          code
          type
          type_other_detail
          internal_number
          status
          detail
        }
      }
      resource_professional {
        _id
        type
        name
        ssn_ident
      }
      resource_equipment {
        _id
        type
        name
        location
      }
      center {
        _id
        tenantID
        has_subcenters
        internal_number
        is_imported
        imported_from
        imported_at
        altern_number
        ext_number
        legal_name
        social_name
        initials
        mail_address
      }
      idEvent
      procedures {
        _id
        procedure_name
        billing_codes {
          _id
          code
          quantity
          name
        }
      }
      type
    }
  }
`

export const GET_PRICES_BY_AGREEMENT = gql`
  query GetPricesByAgreement($billingCodesPrice: [billCodePriceInput]) {
    getPricesByAgreement(billingCodesPrice: $billingCodesPrice) {
      tenantID
      eventID
      procedureID
      billingCodeID
      centerID
      appointmentType
      insuranceCorpID
      billingType
      agreementName
      agreementCode
      price
      price_tax
      total_price
      default_price_coverage
      price_accepts_dscnt
      billing_name
      billing_code_code
      prlistID
      priceAgreementID
    }
  }
`

export const SEARCH_EVENTS_BY_PATIENT = gql`
  query SearchEventsByPatient(
    $patientID: ID
    $eventStatus: [String]
    $dateFrom: String
    $dateTo: String
  ) {
    searchEventsByPatient(
      patientID: $patientID
      eventStatus: $eventStatus
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      _id
      day
      time
      status
      payment_status
      patient {
        _id
        name
        ssn_ident
        insurances {
          _id
          name
          ext_code
          tenantID
          legal_id_number
          code
          type
          type_other_detail
          internal_number
          status
          detail
        }
      }
      resource_professional {
        _id
        type
        name
        ssn_ident
      }
      resource_equipment {
        _id
        type
        name
        location
      }
      center {
        _id
        tenantID
        has_subcenters
        internal_number
        is_imported
        imported_from
        imported_at
        altern_number
        ext_number
        legal_name
        social_name
        initials
        mail_address
      }
      idEvent
      procedures {
        _id
        procedure_name
        billing_codes {
          _id
          code
          quantity
          name
        }
      }
      type
    }
  }
`
export const GET_PROFESSIONALS_FOR_USERS = gql`
  query GetProfessionalsForUsers($search: String, $tenantID: ID) {
    getProfessionals(search: $search, tenantID: $tenantID) {
      _id
      legal_data {
        college
        year
        title
      }
    }
  }
`

export const GET_PROFESSIONAL_BY_PERSON_ID = gql`
  query GetProfessionalByPersonID($personID: ID) {
    getProfessionalByPersonID(personID: $personID) {
      _id
      legal_data {
        college
        year
        title
      }
    }
  }
`

export const GET_MD_PRO_TYPES = gql`
  query GetMdProTypes {
    getMDproTypes {
      _id
      name
      type
    }
  }
`

export const GET_PRICE_AGREEMENTS_BY_CENTER = gql`
  query GetPriceAgreementsByCenter($centerID: [ID]) {
    getPriceAgreementsByCenter(centerID: $centerID) {
      _id
      tenantID
      status
      centersID
      agreement_type
      insuranceCorpID
      name_insuranceCorp
      name
      altern_name
      internal_number
      code
      events_type
      altern_code
      ext_code
      ext_name
      list {
        prlist_id
        status
        type
        billingCodeID
        other_code
        price
        price_tax
        total_price
        default_price_coverage
        price_accepts_dscnt
        ISO4217
        log {
          date
          time
          action_type
          detail
          user
        }
      }
      log {
        date
        time
        action_type
        detail
        user
      }
      created_at
      created_by
    }
  }
`

export const GET_TENANT_SPECIALITIES = gql`
  query GetTenantSpecialities(
    $search: String
    $centerID: ID
    $tenantID: ID
    $type: String
    $appointmentTypes: [String]
  ) {
    getTenantSpecialities(
      search: $search
      centerID: $centerID
      tenantID: $tenantID
      type: $type
      appointmentTypes: $appointmentTypes
    ) {
      _id
      tenantID
      status
      name
      search_type
      altern_name
      internal_number
      code
      altern_code
      ext_code
      ext_name
      proceduresID
      assigned_procedures {
        _id
        name
        code
        minutes
      }
    }
  }
`

export const GET_PAYMENTS_ACCOUNTS = gql`
  query GetPaymentsAccounts($search: String, $tenantID: ID) {
    getPaymentsAccounts(search: $search, tenantID: $tenantID) {
      _id
      internal_number
      uuid
      tenantID
      status
      eventsID
      billingCodes_list {
        _id
        eventID
        billingCodeID
        quantity
        priceAgreementID
        prlistID
        price
        tax
        discount
        totalPrice
      }
      comments
      has_discount
      dscnt_reason
      dscnt_amount
      total_price
      insurance_coverage
      aditional_insurance_coverage
      insurance_coverage
      other_coverage
      payment_list {
        _id
        uuid
        status
        date
        type
        insuranceCorpID
        payment_method
        amount
        hasInvoice
        invoicesID
        eventsID
        paymethod_detail {
          bono_number
          bono_date
          bono_emitter
          brand_cc
          bank_cc
          brand_dc
          bank_dc
          voucher_code
          auth_code
          other_code
          transfer_date
          transfer_bank_from
          transfer_person_from
          transfer_code
          cheque_number
          cheque_bank
          cheque_owner
          other_type
          other_detail
          other_scannedDocto
          comments
        }
        cashierSessionID
        log {
          date
          time
          action_type
          detail
          prev_data
          new_data
          user
        }
      }
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      created_at
      created_by
    }
  }
`
export const GET_EVENTS_PHYSICIAN_WL = gql`
  query GetEventsPhysicianWl(
    $search: String
    $equipmentsID: [ID]
    $professionalsID: [ID]
    $proceduresID: [ID]
    $centersID: [ID]
    $eventStatus: [String]
    $appointmentTypes: [String]
    $tenantID: ID
    $dateFrom: String
    $dateTo: String
    $recordsPerPage: Int
    $pageNumber: Int
    $includeImportedEvents: Boolean
    $importedEventsOnly: Boolean
  ) {
    getEventsPhysicianWL(
      search: $search
      equipmentsID: $equipmentsID
      professionalsID: $professionalsID
      proceduresID: $proceduresID
      centersID: $centersID
      eventStatus: $eventStatus
      appointmentTypes: $appointmentTypes
      tenantID: $tenantID
      dateFrom: $dateFrom
      dateTo: $dateTo
      recordsPerPage: $recordsPerPage
      pageNumber: $pageNumber
      includeImportedEvents: $includeImportedEvents
      importedEventsOnly: $importedEventsOnly
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        tenantID
        internal_number
        accession_number
        uuid
        altern_uuid
        creation_source
        creation_channel
        groupID
        reception_comment
        patient_type
        priority
        isImported
        date_string
        time_string
        status
        recall
        payment_status
        added_minutes
        default_duration
        resource_types
        appointment_reason
        paymentAccountID
        referring_professionalID
        referring_phy_number
        referring_phy_name
        referring_center_name
        referring_centerID
        referring_area
        visit_number
        patient {
          _id
          status
          ssn_personintId
          mail_address
          birth_date
          age
          sex
          insurance {
            _id
            name
          }
          address {
            street
            street_number
            city
            country_name
          }
          legal_name {
            first_name
            second_name
            surname
            mother_maiden
          }
        }
        procedures {
          _id
          status
          name
          code
          billingCodes {
            _id
            status
            name
            altern_name
            code
            altern_code
          }
          specialities {
            _id
            name
            search_type
          }
        }
        professionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        equipments {
          _id
          status
          name
          location
        }
      }
    }
  }  
`;

export const GET_PERSON_EVENTS_BY_PERSON_SSN = gql`
  query GetPersonEventsByPersonSSN(
    $SsnPersonintID: String,
    $recordsPerPage: Int,
    $pageNumber: Int
  ) {
    getPersonEventsByPersonSsnPersonintID(
      SsnPersonintID: $SsnPersonintID,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber,
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        status
        ssn_personintId
        mail_address
        birth_date
        insurance {
          _id
          name
        }
        age
        sex
        address{
          street
          street_number
          city
          country_code
          country_name
        }
        legal_name{
          first_name
          second_name
          third_name
          surname
          mother_maiden
        }
        events{
          _id
          tenantID
          centersID
          internal_number
          accession_number
          uuid
          altern_uuid
          altern_uuid
          creation_source
          creation_channel
          groupID
          day_slotID
          created_slotID
          external_uuid
          patient_type
          priority
          date_string
          time_string
          referring_professionalID
          referring_phy_number
          referring_phy_name
          referring_center_name
          referring_area
          referring_centerID
          status
          default_duration
          added_minutes
          recall
          recall_status
          recall_reason
          payment_status
          appointment_types
          paymentAccountID
          resource_types
          active
          mid_attention_proID
          med_docto_types
          appointment_reason
          reception_comment
          professionals{
            _id
            document_number
            professional_person{
              _id
              status
              ssn_personintId
              birth_date
              age
              sex
              legal_name{
                first_name
                second_name
                third_name
                surname
                mother_maiden
              }
            }
            professional_specialities{
              _id
              status
              name
            }
          }
          equipments{
            _id
            status
            name
            alias
            location
          }
          procedures{
            _id
            status
            name
            code
            billingCodes{
              _id
              status
              name
              altern_name
              code
              altern_code
            }
            specialities{
              _id
              name
              altern_name
              search_type
            }
          }
        }
      }
    }
  }
`;

export const GET_PATIENTS_BY_PROFESSIONAL = gql`
  query GetPatientsByProfessional(
    $professionalID: ID,
  ) {
    getPatientsByProfessional(
      professionalID: $professionalID,
    ) {
      _id
      status
      ssn_personintId
      mail_address
      birth_date
      age
      sex
      address{
        street
        street_number
        city
        country_code
        country_name
      }
      legal_name{
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
      insurance{
        _id
        name
      }
    }
  }
`;

export const GET_EVENTS_BY_PROFESSIONAL = gql`
  query GetEventsByProfessional(
    $professionalID: ID,
    $dateFrom: String,
    $dateTo: String,
  ) {
    getEventsByProfessional(
      professionalID: $professionalID,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
    ) {
      _id
      tenantID
      centersID
      internal_number
      accession_number
      uuid
      altern_uuid
      external_uuid
      creation_source
      creation_channel
      groupID
      day_slotID
      created_slotID
      patient_type
      priority
      date_string
      time_string
      referring_professionalID
      referring_phy_number
      referring_phy_name
      referring_center_name
      referring_area
      referring_centerID
      status
      default_duration
      added_minutes
      recall
      recall_status
      recall_reason
      payment_status
      appointment_types
      resource_types
      active
      mid_attention_proID
      med_docto_types
      appointment_reason
      reception_comment
      paymentAccountID
      appointment_types
      procedures{
        _id
        status
        name
        code
        billingCodes{
          _id
          status
          name
          altern_name
          code
          altern_code
        }
        specialities{
          _id
          name
        }
      }
      patient{
        _id
        status 
        ssn_personintId
        mail_address
        age
        sex
        birth_date
        legal_name{
          first_name
          second_name
          third_name
          surname
          mother_maiden
        }
        address{
          street
          street_number
          city
          country_code
          country_name
      }
      }
    }
  }
`;

export const GET_MD_CIE10 = gql`
  query GetMDcie10(
    $search: String,
    $tenantID: ID,
    $level: Int,
    $codes: [String]
  ) {
    getMDcie10(
      search: $search,
      tenantID: $tenantID,
      level: $level,
      codes: $codes
    ) {
      _id
      level
      descriptor
      code
      parent_0
      parent_1
      parent_2
      parent_3
      parent_4
    }
  }
`;

export const GET_MD_CIE10_CHILDREN = gql`
  query GetMDcie10Children(   
    $tenantID: ID,
    $level: Int,
    $parent_0: String,
    $parent_1: String,
    $parent_2: String,
    $parent_3: String,
    $parent_4: String
  ) {
    getMDcie10Childrens(      
      tenantID: $tenantID,
      level: $level,
      parent_0: $parent_0,
      parent_1: $parent_1,
      parent_2: $parent_2,
      parent_3: $parent_3,
      parent_4: $parent_4,
    ) {
      _id
      level
      descriptor
      code
      parent_0
      parent_1
      parent_2
      parent_3
      parent_4
    }
  }
`;

export const GET_MD_DRUGS_NAME_BY_AUTOCOMPLETE = gql`
  query GetMDDrugsNameByAutocomplete(
    $tenantID: ID,
    $searchName: String,
    $limit: Int,
  ) {
    getMDdrugsNameByAutocomplete(
      tenantID: $tenantID,
      searchName: $searchName,
      limit: $limit,
    ) {
      _id      
      name
      pharmaceutical
      active_component
      legal_status
    }
  }
`;

export const GET_MD_DRUGS_COMPONENT_BY_AUTOCOMPLETE = gql`
  query GetMDDrugsComponentByAutocomplete(
    $tenantID: ID,
    $searchComponent: String,
    $limit: Int,
  ) {
    getMDdrugsComponentByAutocomplete(
      tenantID: $tenantID,
      searchComponent: $searchComponent,
      limit: $limit,
    ) {
      _id      
      name
      pharmaceutical
      active_component
      legal_status
    }
  }
`;

export const GET_PROFESSIONAL_TENANTS = gql`
  query GetProfessionalTenants($search: String, $professionalID: ID) {
    getProfessionalTenants(search: $search, professionalID: $professionalID) {
      _id
      assigned_tenantsID 
    }
  }
`

export const GET_EVENTS_RAD_TEC_WL = gql`
  query GetEventsRadTecWl(
    $tenantID: ID,
    $dateFrom: String,
    $dateTo: String,
    $centersID: [ID],
    $proceduresID: [ID],
    $professionalsID: [ID],
    $equipmentsID: [ID],    
    $eventStatus: [String],
    $appointmentTypes: [String],    
    $search: String,
    $recordsPerPage: Int,
    $pageNumber: Int,
    $includeImportedEvents: Boolean
    $importedEventsOnly: Boolean
  ) {
    getEventsRadTecWL(
      equipmentsID: $equipmentsID,
      professionalsID: $professionalsID,
      proceduresID: $proceduresID,
      centersID: $centersID,
      eventStatus: $eventStatus,
      appointmentTypes: $appointmentTypes,
      tenantID: $tenantID,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      search: $search,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber,
      includeImportedEvents: $includeImportedEvents,
      importedEventsOnly: $importedEventsOnly
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        visit_number
        tenantID
        isImported
        internal_number
        uuid
        altern_uuid
        creation_source
        creation_channel
        appointment_types
        default_duration
        added_minutes
        reception_comment
        patient_type
        priority
        date_string
        time_string
        status
        recall
        payment_status
        resource_types
        appointment_reason
        paymentAccountID    
        created_at
        patient {
          _id
          status
          ssn_personintId
          mail_address
          birth_date
          age
          sex
          address {
            street
            street_number
            city
            country_name
          }
          legal_name {
            first_name
            second_name
            surname
            mother_maiden
          }
          insuranceID
          insurance_name
        }
        procedures {
          _id
          status
          name
          code
          billingCodes {
            _id
            status
            name
            altern_name
            code
            altern_code
          }
          specialities {
            _id
            name
            search_type
            altern_name		
          }
        }
        professionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        signedByProfessionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        equipments {    
          _id            
          status			
          name					
          alias					
          location		
        }
        isDICOM
        DICOM_HTML_viewer_url
        tec_anamnesis
        medical_order_number
        clinical_support_doctoID
        original_in_procedures_billingCodes {
          billingCodeID
          quantity
        }
        DICOM_requested_procedures {
          group_case
          group_code_value
          group_assigned_radiologists_proID
          group_description
          sched_procedures_steps {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
          sched_procedures_steps_obj {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
        }
        attached_FILES {
          _id
          type
          classification
        }
      }
    }
  }
`

export const GET_EVENTS_RAD_WL = gql`
  query GetEventsRadWl(
    $tenantID: ID,
    $dateFrom: String,
    $dateTo: String,
    $centersID: [ID],
    $proceduresID: [ID],
    $professionalsID: [ID],
    $equipmentsID: [ID],    
    $eventStatus: [String],
    $appointmentTypes: [String],    
    $search: String,
    $recordsPerPage: Int,
    $pageNumber: Int,
    $includeImportedEvents: Boolean,
    $importedEventsOnly: Boolean
  ) {
    getEventsRadWL(
      equipmentsID: $equipmentsID,
      professionalsID: $professionalsID,
      proceduresID: $proceduresID,
      centersID: $centersID,
      eventStatus: $eventStatus,
      appointmentTypes: $appointmentTypes,
      tenantID: $tenantID,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      search: $search,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber,
      includeImportedEvents: $includeImportedEvents,
      importedEventsOnly: $importedEventsOnly
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        isImported
        visit_number
        tenantID
        internal_number
        uuid
        altern_uuid
        creation_source
        creation_channel
        appointment_types
        default_duration
        added_minutes
        reception_comment
        patient_type
        priority
        date_string
        time_string
        status
        recall
        payment_status
        resource_types
        appointment_reason
        paymentAccountID 
        mid_attention_proID
        clinical_support_doctoID
        created_at   
        patient {
          _id
          status
          ssn_personintId
          mail_address
          birth_date
          age
          sex
          address {
            street
            street_number
            city
            country_name
          }
          legal_name {
            first_name
            second_name
            surname
            mother_maiden
          }
          insuranceID
          insurance_name
        }
        procedures {
          _id
          status
          name
          code
          billingCodes {
            _id
            status
            name
            altern_name
            code
            altern_code
          }
          specialities {
            _id
            name
            search_type
            altern_name		
          }
        }
        professionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        signedByProfessionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        equipments {    
          _id            
          status			
          name					
          alias					
          location		
        }
        isDICOM
        DICOM_HTML_viewer_url
        tec_anamnesis
        medical_order_number
        original_in_procedures_billingCodes {
          billingCodeID
          quantity
        }
        DICOM_requested_procedures {
          group_case
          group_code_value
          group_assigned_radiologists_proID
          group_description
          sched_procedures_steps {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
          sched_procedures_steps_obj {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
        }
        attached_FILES {
          _id
          type
          classification
        }
      }
    }
  }
`

export const GET_EVENTS_AI_RAD_WL = gql`
  query GetEventsAIRadWL(
    $tenantID: ID,
    $dateFrom: String,
    $dateTo: String,
    $centersID: [ID],
    $proceduresID: [ID],
    $professionalsID: [ID],
    $equipmentsID: [ID],    
    $eventStatus: [String],
    $appointmentTypes: [String],    
    $search: String,
    $recordsPerPage: Int,
    $pageNumber: Int,
    $includeImportedEvents: Boolean,
    $importedEventsOnly: Boolean
  ) {
    getEventsAIRadWL(
      equipmentsID: $equipmentsID,
      professionalsID: $professionalsID,
      proceduresID: $proceduresID,
      centersID: $centersID,
      eventStatus: $eventStatus,
      appointmentTypes: $appointmentTypes,
      tenantID: $tenantID,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      search: $search,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber,
      includeImportedEvents: $includeImportedEvents,
      importedEventsOnly: $importedEventsOnly
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        isImported
        visit_number
        tenantID
        internal_number
        uuid
        altern_uuid
        creation_source
        creation_channel
        appointment_types
        default_duration
        added_minutes
        reception_comment
        patient_type
        priority
        date_string
        time_string
        status
        recall
        payment_status
        resource_types
        appointment_reason
        paymentAccountID 
        mid_attention_proID
        created_at   
        patient {
          _id
          status
          ssn_personintId
          mail_address
          birth_date
          age
          sex
          address {
            street
            street_number
            city
            country_name
          }
          legal_name {
            first_name
            second_name
            surname
            mother_maiden
          }
          insuranceID
          insurance_name
        }
        procedures {
          _id
          status
          name
          code
          billingCodes {
            _id
            status
            name
            altern_name
            code
            altern_code
          }
          specialities {
            _id
            name
            search_type
            altern_name		
          }
        }
        professionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        signedByProfessionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        equipments {    
          _id            
          status			
          name					
          alias					
          location		
        }
        isDICOM
        DICOM_HTML_viewer_url
        tec_anamnesis
        medical_order_number
        original_in_procedures_billingCodes {
          billingCodeID
          quantity
        }
        DICOM_requested_procedures {
          group_case
          group_code_value
          group_assigned_radiologists_proID
          group_description
          sched_procedures_steps {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
          sched_procedures_steps_obj {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
        }
        AI_analisis {
          studyInstanceUID
          date
          type
          modelName
          modelVersion
          value
          responseOriginId
          score
          qualityScore
        }
        ai_priority
        attached_FILES {
          _id
          type
          classification
        }
      }
    }
  }
`


export const GET_EVENTS_DICOM_BY_PERSON = gql`
  query GetEventsDicomByPerson(
    $personID: ID,
    $recordsPerPage: Int,
    $pageNumber: Int
  ) {
    getEventsDicomByPerson(
      personID: $personID,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        visit_number
        tenantID
        internal_number
        uuid
        altern_uuid
        creation_source
        creation_channel
        appointment_types
        default_duration
        added_minutes
        reception_comment
        patient_type
        priority
        date_string
        time_string
        status
        recall
        payment_status
        resource_types
        appointment_reason
        paymentAccountID    
        patient {
          _id
          status
          ssn_personintId
          mail_address
          birth_date
          age
          sex
          address {
            street
            street_number
            city
            country_name
          }
          legal_name {
            first_name
            second_name
            surname
            mother_maiden
          }
          insuranceID
          insurance_name
        }
        procedures {
          _id
          status
          name
          code
          billingCodes {
            _id
            status
            name
            altern_name
            code
            altern_code
          }
          specialities {
            _id
            name
            search_type
            altern_name		
          }
        }
        professionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        equipments {    
          _id            
          status			
          name					
          alias					
          location		
        }
        isDICOM
        DICOM_HTML_viewer_url
        tec_anamnesis
        medical_order_number
        original_in_procedures_billingCodes {
          billingCodeID
          quantity
        }
        DICOM_requested_procedures {
          group_case
          group_code_value
          group_assigned_radiologists_proID
          group_description
          sched_procedures_steps {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
        }
      }
    }
  }
`
export const GET_EVENT_FILES_DOCUMENTS = gql`
  query GetEventFilesDocuments($eventID: ID, $fileDoctosIDs: [ID]) {
    getEventFilesDocuments(eventID: $eventID, fileDoctosIDs: $fileDoctosIDs) {
      _id
      name
      fileRaw
      MIME_Type
      file_type
      description
      classification
    }
  }
`

export const GET_PERSON_MED_RECORDS_FULL = gql`
  query GetPersonMedRecordsFull($personID: ID, $recordsPerPage: Int, $pageNumber: Int) {
    getPersonMedRecordsFull(personID: $personID, recordsPerPage: $recordsPerPage, pageNumber: $pageNumber) {
      totalMedicalDoctosQ
      medDoctosQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults 
      {
        clinicalInfoID
        medicalDoctos {
          _id
          tenant_name
          professional_name
          date
          type
          isDicom
          isLab
          details {
            _id
            isImported
            hasPatientLicense
            origin
            status
            event_type
            speciality_string
            uuid
            specialityID
            procedureID
            procedure_name
            onhold_date
            finished_date
            visit_reason
            anamnesis
            record
            diagnoses {
              _id
              code_diccionary
              CIE10codeID
              SNOMEDcodeID
              description
              isChronic
              comments
              isGES
            }
            therapy
          }
        }
      }
    }
  }
`

export const GET_PERSON_MED_RECORDS_FULL_OPHT = gql`
  query GetPersonMedRecordsFull($personID: ID, $recordsPerPage: Int, $pageNumber: Int) {
    getPersonMedDoctos(personID: $personID, recordsPerPage: $recordsPerPage, pageNumber: $pageNumber) {
      totalMedicalDoctosQ
      medDoctosQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults 
      {
        clinicalInfoID
        medicalDoctos {
          _id
          tenant_name
          professional_name
          date
          type
          isDicom
          isLab
          details {
            _id
            HTML_rad_report
            form_template_HTML_content
            HTML_type
            isImported
            hasPatientLicense
            origin
            status
            event_type
            speciality_string
            uuid
            specialityID
            procedureID
            procedure_name
            onhold_date
            finished_date
            visit_reason
            anamnesis
            record
            structure_documents {
              _id
              name
              type
              description
              content
            }
            diagnoses {
              _id
              code_diccionary
              CIE10codeID
              SNOMEDcodeID
              description
              isChronic
              comments
              isGES
            }
            therapy
          }
        }
      }
    }
  }
`


export const GET_PERSON_DRUGS_DETAILS = gql`
  query GetPersonDrugsDetails($personID: ID, $recordsPerPage: Int, $pageNumber: Int) {
    getPersonDrugsDetails(personID: $personID, recordsPerPage: $recordsPerPage, pageNumber: $pageNumber) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults 
      {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
    }
  }
`

export const GET_PERSON_EXAMS_DETAILS = gql`
  query GetPersonExamsDetails($personID: ID, $recordsPerPage: Int, $pageNumber: Int) {
    getPersonExamensDetails(personID: $personID, recordsPerPage: $recordsPerPage, pageNumber: $pageNumber) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults 
      {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
    }
  }
`

export const GET_PERSON_CHRONIC_ILLNESS = gql`
  query GetPersonChronicIllness($personID: ID, $recordsPerPage: Int, $pageNumber: Int) {
    getPersonChronicIllness(personID: $personID, recordsPerPage: $recordsPerPage, pageNumber: $pageNumber) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults 
      {
        code_diccionary
        subdivision
        name
        date
        active
        last_updated_by
      }
    }
  }
`

export const GET_PATIENT_CLINICAL_INFO = gql`
  query GetPatientClinicalInfo($patientClinicalInfoID: ID) {
    getPatientClinicalInfo(patientClinicalInfoID: $patientClinicalInfoID) {
      _id
      personal_family_history{
        occupation
        family_group
        family_background
      }
      morbid_antecedents{
        diseases
        surgeries
        others
      }
      allergies{
        medicines
        food
        others
      }
      gynecological_history {
        menarche_age
        menopause_age
        duration_days
        frecuency_days
        Flow_intensity
        FUR
        prengnancy
        others
      }
      habits {
        smoking
        alcohol
        type_feeding
        others
      }
      medicines {
        diseases
        surgeries
        others
      }
      immunizations{
        diseases
        surgeries
        others
      }
    }
  }
`

export const GET_RECENT_EVENTS = gql`
  query GetRecentEvents($professionalsID: ID) {
    getRecentEvents(professionalsID: $professionalsID)
  }
`

export const GET_FUTURE_EVENTS = gql`
  query GetFutureEvents($professionalsID: ID) {
    getFutureEvents(professionalsID: $professionalsID)
  }
`

export const GET_CHRONIC_EVENTS = gql`
  query GetChronicEvents($professionalsID: ID) {
    getChronicEvents(professionalsID: $professionalsID)
  }
`

export const GET_PERSON_ALLERGIES_AND_CHRONIC_ILLNESS = gql`
  query GetPersonAllergiesAndChronicIllness($personID: ID, $recordsPerPage: Int, $pageNumber: Int) {
    getPersonAllergiesAndChronicIllness(
      personID: $personID,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber
    ) {
        totalResults
        totalResultsInResponse
        hasMoreData
        totalPages
        pageNumber
        queryResults 
        {
          allergies {
            medicines
            food
            others
          }
        chronic_illness {
          code_diccionary
          subdivision
          name
          date
          active
        }
      }
    }
  }
`

export const GET_PREDEFINED_TEMPLATES = gql`
  query GetPredefinedTemplates(
    $search: String,
    $tenantID: ID,
    $type: String!,
    $owner_userID: ID,
    $includePublic: Boolean,
    $isRad: Boolean,
    $reportRadModality: String,
    $reportProcedures: [ID]
  ) {
    getPredefinedTemplates(
      search: $search,
      tenantID: $tenantID,
      type: $type,
      owner_userID: $owner_userID,
      includePublic: $includePublic,
      isRad: $isRad,
      reportRadModality: $reportRadModality,
      reportProcedures: $reportProcedures
    ) {
      owner_templates {
        _id
        tenantID
        name
        alias
        description
        tokenTagsID
        internal_number
        type
        is_public
        is_editable
        isStructural
        owner_userID
        status
        isRad 
        reportProcedures
        reportRadModality
        content
        core_type
      }
      public_templates {
        _id
        tenantID
        name
        alias
        description
        tokenTagsID
        internal_number
        type
        is_public
        is_editable
        isStructural
        owner_userID
        status
        isRad 
        reportProcedures
        reportRadModality
        content
        core_type
      }
      structure_templates {
        _id
        tenantID
        name
        alias
        description
        tokenTagsID
        isStructural
        type
        is_public
        is_editable
        internal_number
        owner_userID
        status
        isRad 
        reportProcedures
        reportRadModality
        content
        core_type
      }
    }
  }
`


export const GET_EVENTS_PAYMENT_STATUS = gql`
  query GetEventsPaymentStatus($professionalID: ID) {
    getEventsPaymentStatus(professionalID: $professionalID) {
      total
      unpaid
      halfpaid
      fullpaid
    }
  }
`

export const SEND_EVENT_PREPARATION_EMAIL = gql`
  query SendEventPreparationEmail($uuid: String) {
    sendEventPreparationsEmail(uuid: $uuid) {
      responseCode
      emailSentSuccessfully
    }
  }
`

export const GET_CASHIER_SESSIONS_BY_PERSON = gql`
  query GetCashierSessionsByPerson(
    $personID: ID,
    $dateFrom: String,
    $dateTo: String,
    $recordsPerPage: Int,
    $pageNumber: Int
  ) {
    getCashierSessionsByPerson(
      personID: $personID,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults {
        _id
        internal_number
        uuid
        tenantID
        userID
        open_by_userID
        open_time
        closed_by_userID
        closed_time
        initial_amounts {
          currency_denomination
          amount
        }
        total_initial_amount
        payment_movements {
          type
          paymentAccountID
          paylisID
          comments
        }
        closed_amounts {
          payment_method
          amount
        }
        total_closed_amount
        status
        comments
        close_reason
      }
    }
  }
`

export const GET_CASHIER_SESSION_DETAILS = gql`
  query GetCashierSessionDetails($cashierSessionID: ID) {
    getCashierSessionDetails(cashierSessionID: $cashierSessionID) {
      _id 
      open_dateTime 
      open_amount
      open_details {
        currency_denomination
        amount
      }
      mov_details {
        mov_type
        mov_dateTime
        paymentAccountID
        paylisID
        eventsID
        person_ssn 
        person_name {
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
        pay_type
        insurance_legalID
        insurace_name
        amount_neto
        payment_method
        pay_status
      }
      close_amounts
      totals_pay {
        total_patient
        total_insurancecorp
        total_additionalinsurance
        total_other
      }
    }
  }
`

export const GET_PERSON_OWN_SIGN = gql`
  query GetPersonOwnSign($personID: ID) {
    getPersonOwnSign(personID: $personID) {
      fileLocation
    }
  }
`;

export const GET_EVENTS_IN_MED_DOCTO_ADMIN_WL = gql`
  query GetEventsInMedDoctoAdminWl(
    $equipmentsID: [ID],
    $professionalsID: [ID],
    $proceduresID: [ID],
    $centersID: [ID],
    $appointmentTypes: [String],
    $tenantID: ID,
    $dateFromStr: String,
    $dateToStr: String,
    $search: String,
    $recordsPerPage: Int,
    $pageNumber: Int,
    $includeImportedEvents: Boolean,
    $importedEventsOnly: Boolean
  ) {
    getEventsInMedDoctoAdminWL(
      equipmentsID: $equipmentsID,
      professionalsID: $professionalsID,
      proceduresID: $proceduresID,
      centersID: $centersID,
      appointmentTypes: $appointmentTypes,
      tenantID: $tenantID,
      dateFromStr: $dateFromStr,
      dateToStr: $dateToStr,
      search: $search,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber,
      includeImportedEvents: $includeImportedEvents,
      importedEventsOnly: $importedEventsOnly
    ) {
      totalEventsQ
      eventsQInResponse
      hasMoreData
      totalPages
      pageNumber
      resumeFileClassification 
      queryResults {
        _id
        tenantID
        internal_number
        accession_number
        uuid
        altern_uuid
        creation_source
        creation_channel
        appointment_types
        groupID
        reception_comment
        patient_type
        priority
        date_string
        time_string
        status
        recall
        payment_status
        added_minutes
        default_duration
        resource_types
        appointment_reason
        paymentAccountID        
        visit_number
        created_at
        patient {
          _id
          status
          ssn_personintId
          mail_address
          birth_date
          age
          sex
          address {
            street
            street_number
            city
            country_name
          }
          legal_name {
            first_name
            second_name
            surname
            mother_maiden
          }
          insuranceID
          insurance_name
        }
        procedures {
          _id
          status
          name
          code
          billingCodes {
            _id
            status
            name
            altern_name
            code
            altern_code
          }
          specialities {
            _id
            name
            search_type
          }
        }
        professionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        signedByProfessionals {
          _id
          document_number
          professional_person {
            _id
            status
            ssn_personintId
            birth_date
            age
            sex
            legal_name {
              first_name
              second_name
              surname
              mother_maiden
            }
          }
        }
        equipments {
          _id
          name
          status
          alias
          location
        }
        isDICOM
        medical_order_number
        original_in_procedures_billingCodes {
          billingCodeID
          quantity
        }
        DICOM_requested_procedures  {
          group_case
          group_code_value
          group_assigned_radiologists_proID
          group_description
          sched_procedures_steps  {
            description
            accession_number
            study_instance_UID
            code_value
            sps_description
            modality
            procedureID
            executor_AETITLE
            assigned_radiologists_proID
          }
        }
      }
    }
  }
`;

export const GET_EVENTS_LOG = gql`
  query GetEventsLog(
    $eventID: ID,
    $dateFromStr: String,
    $dateToStr: String,
    $recordsPerPage: Int,
    $pageNumber: Int
  ) {
    getEventsLog(
      eventID: $eventID,
      dateFromStr: $dateFromStr,
      dateToStr: $dateToStr,
      recordsPerPage: $recordsPerPage,
      pageNumber: $pageNumber
    ) {
      totalResults
      totalResultsInResponse
      hasMoreData
      totalPages
      pageNumber
      queryResults{
        _id
        date
        action_type
        detail
        prev_data
        new_data
        user_name
      }
    }
  }
`;

export const GET_PDF_MED_DOCTO_BY_PRO = gql`
  query GetPDFMedDoctoByPro(
    $tenantID: ID,
    $procedureID: ID,
    $personID: ID,
    $eventID: ID,
    $medDoctoID: ID,
    $billingID: ID
  ) {
    getPDFMedDoctoByPro(
      tenantID: $tenantID,      
      procedureID: $procedureID,
      personID: $personID,
      eventID: $eventID,
      medDoctoID: $medDoctoID,
      billingID: $billingID
    ) {
      name
      MIME_Type
      fileRaw
    }
  }
`

export const GET_MED_DOCTO_BY_PRO = gql`
  query GetMedDoctoByPro(
    $professionalID: ID,
    $eventID: ID,
    $personID: ID,    
  ) {
    getMedDoctoByPro(
      professionalID: $professionalID,
      eventID: $eventID,
      personID: $personID,
    ) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      origin
      status
      visit_reason
      anamnesis
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      HTML_type
      form_template_HTML_content
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      structure_documents {
        _id
        name
        type
        description
        content
      }
      therapy
      created_on_tenantID     
      addendums {
        _id
        record
        created_by
        created_at
      } 
    }
  }
`

export const GET_MED_DOCTO_STATUS = gql`
  query GetMedDoctoStatus(
    $professionalID: ID,
    $eventID: ID,
    $personID: ID,    
  ) {
    getMedDoctoStatus(
      professionalID: $professionalID,
      eventID: $eventID,
      personID: $personID,
    ) {    
      _id  
      eventID
      status      
    }
  }
`
export const GET_WL_AS_XLSX_EVENTS_ADM_WL = gql`
  query GetWLAsXLSEventsAdmWL(
    $equipmentsID: [ID]
    $professionalsID: [ID]
    $proceduresID: [ID]
    $centersID: [ID]
    $eventStatus: [String]
    $appointmentTypes: [String]
    $tenantID: ID
    $dateFromStr: String
    $dateToStr: String
    $search: String
    $recordsPerPage: Int
    $pageNumber: Int
    $includeImportedEvents: Boolean
    $importedEventsOnly: Boolean
  ) {
    getWLAsXLSEventsAdmWL(
      equipmentsID: $equipmentsID
      professionalsID: $professionalsID
      proceduresID: $proceduresID
      centersID: $centersID
      eventStatus: $eventStatus
      appointmentTypes: $appointmentTypes
      tenantID: $tenantID
      dateFromStr: $dateFromStr
      dateToStr: $dateToStr
      search: $search
      recordsPerPage: $recordsPerPage
      pageNumber: $pageNumber
      includeImportedEvents: $includeImportedEvents
      importedEventsOnly: $importedEventsOnly
    ) {
        name
        fileRaw
        MIME_Type
        file_type
      }
  }
`

export const LOGOUT_USER = gql`
  query LogoutUser($user: String) {
    logoutUser(user: $user) {
      code
    }
  }
`;

export const GET_USER_VIEWER_PERMISSIONS = gql`
  query getUserViewersPermissions($tenantID: ID) {
    getUserViewersPermissions(tenantID: $tenantID) {
      baseActive
      dicomActive
      advancedActive
      token
    }
  }
`

export const GET_PROFESSIONALS_BY_SPECIALITY = gql`
query GetProfessionalsBySpeciality($speciality: String, $tenantID: ID){
	getProfessionalsBySpeciality(speciality: $speciality, tenantID: $tenantID) {
    _id
    personID
    personName
    assigned_tenantsID
    assigned_centersID
    internal_number
    altern_number
    uuid
    ext_number
    legal_data {
      college
      year
      title
      specialities
      record
    }
    type
    title_code
  }
}
`

export const GET_MD_COUNTRY_DIV_LEVELS = gql`
  query GetMDCountryDivLevels($name: String) {
    getMDCountryDivLevels(name: $name) {
      _id
      level1
      level2
      level3
      level4
      level5
      country_name
      country_code
      country_telephone_prefix
    }
  }
`

export const GET_STATISTICS_REPORTS_SIGNED_BY_PRO = gql`
  query GetStatisticsReportsSignedByPro($tenantID: ID, $proID: ID, $dateFromStr: String, $dateToStr: String) {
    getStatisticsReportsSignedByPro(tenantID: $tenantID, proID: $proID, dateFromStr: $dateFromStr, dateToStr: $dateToStr) {
      proPersonSSN
      total
      tenantID
      centersData {
        centerID
        centerName
      }
      proName
      proID
      signedEventsInfo {
        _id
        altern_uuid
        patientName
        patient_doc_id
        payment_status
      }
    }
  }
`


export const GET_STATISTICS_REPORTS_SIGNED_BY_PRO_AND_DAYS = gql`
  query GetStatisticsReportsSignedByProAndDays($tenantID: ID, $proID: ID, $dateFromStr: String, $dateToStr: String) {
    getStatisticsReportsSignedByProAndDays(tenantID: $tenantID, proID: $proID, dateFromStr: $dateFromStr, dateToStr: $dateToStr) {
      proPersonSSN
      signDate
      total
      tenantID
      centersData {
        centerID
        centerName
      }
      proName
      proID
      signedEventsInfo {
        _id
        altern_uuid
        patientName
        patient_doc_id
        payment_status
      }
    }
  }
`

export const GET_STATISTICS_REPORTS_SIGNED_BY_PRO_AND_DAYS_AND_CENTER = gql`
  query GetStatisticsReportsSignedByProAndDaysAndCenter($tenantID: ID, $proID: ID, $dateFromStr: String, $dateToStr: String, $centerID: ID) {
    getStatisticsReportsSignedByProAndDaysAndCenter(tenantID: $tenantID, proID: $proID, dateFromStr: $dateFromStr, dateToStr: $dateToStr, centerID: $centerID) {
      proPersonSSN
      signDate
      total
      tenantID
      centersData {
        centerID
        centerName
      }
      proName
      proID
      signedEventsInfo {
        _id
        altern_uuid
        patientName
        patient_doc_id
        payment_status
      }
    }
  }
`

export const GET_STATISTICS_EVENTS_BY_DATE = gql`
  query GetStatisticsEventsByDate($tenantID: ID, $proID: ID, $dateFromStr: String, $dateToStr: String, $centerID: ID, $isImported: Boolean) {
    getStatisticsEventsByDate(tenantID: $tenantID, proID: $proID, dateFromStr: $dateFromStr, dateToStr: $dateToStr, centerID: $centerID, isImported: $isImported) {
      total
      tenantID
      eventsStatus 
      totalGlobal
      fill
      centersData {
        centerID
        centerName
      }
      eventsInfo {
        _id
        altern_uuid
        patientName
        patient_doc_id
        payment_status
        event_status
        centerID
        centerName
      }
    }
  }
`

export const GET_STATS_EVENTS_BY_DATE_AS_XLS = gql`
  query GetStatsEventsByDateAsXLS(
    $equipmentsID: [ID]
    $professionalsID: [ID]
    $proceduresID: [ID]
    $centersID: [ID]
    $eventStatus: [String]
    $appointmentTypes: [String]
    $tenantID: ID
    $dateFromStr: String
    $dateToStr: String
    $search: String
    $isImported: Boolean
    $importedEventsOnly: Boolean
  ) {
    getStatsEventsByDateAsXLS(
      equipmentsID: $equipmentsID
      professionalsID: $professionalsID
      proceduresID: $proceduresID
      centersID: $centersID
      eventStatus: $eventStatus
      appointmentTypes: $appointmentTypes
      tenantID: $tenantID
      dateFromStr: $dateFromStr
      dateToStr: $dateToStr
      search: $search
      isImported: $isImported
      importedEventsOnly: $importedEventsOnly
    ) {
        name
        fileRaw
        MIME_Type
        file_type
      }
  }
`

export const GET_STATS_REPORTS_SIGNED_AS_XLS = gql`
  query GetStatsReportsSignedAsXLS(
    $equipmentsID: [ID]
    $professionalsID: [ID]
    $proceduresID: [ID]
    $centersID: [ID]
    $eventStatus: [String]
    $appointmentTypes: [String]
    $tenantID: ID
    $dateFromStr: String
    $dateToStr: String
    $search: String
    $isImported: Boolean
    $importedEventsOnly: Boolean
  ) {
    getStatsReportsSignedAsXLS(
      equipmentsID: $equipmentsID
      professionalsID: $professionalsID
      proceduresID: $proceduresID
      centersID: $centersID
      eventStatus: $eventStatus
      appointmentTypes: $appointmentTypes
      tenantID: $tenantID
      dateFromStr: $dateFromStr
      dateToStr: $dateToStr
      search: $search
      isImported: $isImported
      importedEventsOnly: $importedEventsOnly
    ) {
        name
        fileRaw
        MIME_Type
        file_type
      }
  }
`

export const GET_PAYMENT_ACCOUNT_ASSOC_DYNAMIC_DOCTO = gql`
  query GetPaymentAccountAssocDynamicDocto( $tenantID: ID ) {
    getPaymentAccountAssocDynamicDocto( tenantID: $tenantID ) {
      _id
      tenantID
    }
  }
`

export const GET_USER_PREFERENCES_BY_WORKLIST = gql`
  query GetUserPreferencesByWorklist(
		$userID: ID
		$worklist: String
	) {
    getUserPreferencesByWorklist(
			userID: $userID
			worklist: $worklist
		) {
      firstName
      lastName
      patientType
      priority
      age
      sex
      patientId
      date
      time
      isImported
      speciality
      paymentStatus
      accessionNumber
      procedureCode
      procedureName
      professionalEquipment
      signedByProfessionals
      eventStatus
      actions
      appointmentReason
      visitNumber
      aiAnalisis
      aiAnalisisScore
      aiQuality
    }
  }
`

export const GET_PERSON_STRUCTURE_DOCTOS = gql`
  query GetPersonStructureDoctos(
		$eventID: ID
		$personID: ID
	) {
    getPersonStructureDoctos(
			eventID: $eventID
			personID: $personID
		) {
      _id
			status
			structure_documents {
				_id
				name
				type
				description
			}
    }
  }
`

export const GET_STRUCTURE_DOCTO = gql`
  query GetStructureDocto(
		$eventID: ID
		$doctoID: ID
		$structureDoctoID: ID
	) {
    getStructureDocto(
			eventID: $eventID
			doctoID: $doctoID
			structureDoctoID: $structureDoctoID
		) {
      result
			report
    }
  }
`

export const GET_CLINICAL_SUPPORT_TEMPLATES = gql`
  query GetClinicalSupportTemplates(
    $search: String,
    $tenantID: ID,
    $type: String!,
    $owner_userID: ID,
    $includePublic: Boolean,
    $isRad: Boolean,
    $reportRadModality: String,
    $reportProcedures: [ID]
  ) {
    getClinicalSupportTemplates(
      search: $search,
      tenantID: $tenantID,
      type: $type,
      owner_userID: $owner_userID,
      includePublic: $includePublic,
      isRad: $isRad,
      reportRadModality: $reportRadModality,
      reportProcedures: $reportProcedures
    ) {
      owner_templates {
        _id
        tenantID
        name
        alias
        description
        tokenTagsID
        internal_number
        type
        is_public
        is_editable
        isStructural
        owner_userID
        status
        isRad 
        reportProcedures
        reportRadModality
        content
        core_type
      }
      public_templates {
        _id
        tenantID
        name
        alias
        description
        tokenTagsID
        internal_number
        type
        is_public
        is_editable
        isStructural
        owner_userID
        status
        isRad 
        reportProcedures
        reportRadModality
        content
        core_type
      }
      structure_templates {
        _id
        tenantID
        name
        alias
        description
        tokenTagsID
        isStructural
        type
        is_public
        is_editable
        internal_number
        owner_userID
        status
        isRad 
        reportProcedures
        reportRadModality
        content
        core_type
      }
    }
  }
`


export const GET_CLINICAL_SUPPORT_DOCTOS = gql`
  query GetClinicalSupportDoctos(
    $eventID: ID,
    $doctosID: [ID],    
  ) {
    getClinicalSupportDoctos(
      eventID: $eventID,
      doctosID: $doctosID,
    ) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      clinical_support_templatesID
      origin
      status
      visit_reason
      anamnesis
      templateID
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      HTML_type
      form_template_HTML_content
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      structure_documents {
        _id
        name
        type
        description
        content
      }
      therapy
      created_on_tenantID     
      addendums {
        _id
        record
        created_by
        created_at
      } 
    }
  }
`
