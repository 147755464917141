import { InMemoryCache } from '@apollo/client';



// AQUI VAMOS A DEFINIR LAS RESPUESTAS A LOS LOCAL-ONLY FIELDS DE LAS QUERYS

const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          
        }
      }
    }
  });

export default cache;