import React, { useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { gql,useLazyQuery } from "@apollo/client";
import { setAccessKey } from '../setKey';
import { getMessageError } from '../setMessagesError'


const LOGIN_USER = gql`
  query LoginUser($user: String!, $pass: String! ) {
    loginUser(user: $user, pass: $pass) {
      # message
      userID
      token
      # user {   
      #      _id
      #     internal_number
      #       alias
      #     roles
      #       permissions
      #       mail
      #     phone
      #     }
    }
  }
`;

export default function Login(props) {
  const [form] = Form.useForm();
  // const [, forceUpdate] = useState(); // To disable submit button at the beginning.
  const [
    loginUser,
    { data: dataLoginUser, error: errorLoginUser }
  ] = useLazyQuery(LOGIN_USER, {fetchPolicy: 'network-only'});

  //console.log("props ---", props)

  useEffect(() => {
    if(dataLoginUser) {
      if(dataLoginUser.loginUser.token){
      //console.log("dataLoginUser :", dataLoginUser);
      setAccessKey(dataLoginUser.loginUser.token);
      setTimeout(() => {}, 500);
      props.getUserConf(); //App update
    } 
  } else if (errorLoginUser) {
    //console.log("errorLoginUser :", errorLoginUser);
    if(getMessageError()) {
    message.error(getMessageError(), 10);
  } else {
    message.error("User or Password Invalid", 10);
  }
  }
    // eslint-disable-next-line
  }, [dataLoginUser, errorLoginUser]);

  //console.log("dataLoginUser : ", dataLoginUser);

  // function sendUserLogin(values) {
  //  console.log(props);
  //     props.onClick(values.username);
  // }

  return (
    <>
    <main className="bg-fixed h-screen w-screen p-5 items-end login__background">
    <Form form={form} className="float-right" name="horizontal_login" layout="inline" 
    onFinish={values => {
      try {
        loginUser({
          variables: {
          user: values.username,
          pass: values.password
        }
        });
        message.destroy()
      } catch (err) {
          //console.log(err);
          message.error("There was an unexpected error while login. Please try again later")
      }
    }
  }> 
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} className="rounded-md" placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          className="rounded-md"
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            //onClick={onFinish} // 1.- ver como enviar el usuario al componente APP 
            disabled={
              !form.isFieldsTouched(true) ||
              form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >Log in
          {/* //2.- Eliminar esto ^ y ver como redirecciono desde app basado en el perfil por defecto del usuario */}
          </Button>
        )}
      </Form.Item>
    </Form>
    </main>
    </>
  );
};
