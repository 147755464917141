import { useState } from 'react';
import moment from 'moment';
import { message } from 'antd';
import { DEFAULT_WORKLIST_SELECTED_COLUMNS } from '../Constants/worklistContext';
import { globalLoggedUserInfo, globalUserWorklistColumns } from '../../GraphQL/LocalState/globalState';
import { useApolloClient } from '@apollo/client';
import { SAVE_USER_PREFERENCES } from '../../GraphQL/mutations';
const dateFormat = 'DD-MM-YYYY';

const DEFAULT_PHYSICIAN_WORKLIST_COLUMNS = {
	...DEFAULT_WORKLIST_SELECTED_COLUMNS,
	speciality: false,
	accessionNumber: false,
	professionalEquipment: false,
	signedByProfessionals: false,
	patientType: false,
	aiAnalisis: false,
	aiAnalisisScore: false,
	aiQuality: false,
}

const useClinicalWorklist = (userColumns, worklistName) => {

  const [dateFrom, setDateFrom] = useState(moment().format(dateFormat))
  const [dateTo, setDateTo] = useState(moment().format(dateFormat))
  const [eventStatuses, setEventStatuses] = useState([]);
  const [eventResults, setEventResults] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedEvent, setSelectedEvent] = useState();
  const [additionalEvents, setAdditionalEvents] = useState();

	const [selectedColumns, setColumns] = useState(userColumns || DEFAULT_PHYSICIAN_WORKLIST_COLUMNS);

	const client = useApolloClient()

	const columnsToHide = [
		'speciality',
		'accessionNumber',
		'professionalEquipment',
		'signedByProfessionals',
		'patientType',
		'aiAnalisis',
		'aiAnalisisScore',
		'aiQuality',
	]
	const alwaysVisibleColumns = ['actions', 'eventStatus'];
	
	const columnNames = [
		'firstName',
		'lastName',
		'patientId',
		'priority',
		'age',
		'sex',
		'appointmentReason',
		'isImported',
		'procedureCode',
		'procedureName',
		'eventStatus',
		'paymentStatus',
		'visitNumber',		
		'date',
		'time',
		'actions'		
	]

	const setSelectedColumns = async columns => {

		setColumns(columns)

		globalUserWorklistColumns({
			...globalUserWorklistColumns(),
			[worklistName]: columns
		})
    
		try {
			await client.mutate({
				mutation: SAVE_USER_PREFERENCES,
				variables: {
					input: {
						userID: globalLoggedUserInfo()._id,
						worklist: worklistName,
						...columns
					}
				},
				fetchPolicy: 'network-only'
			})
			message.success('Sus cambios han sido guardados exitosamente!')
		} catch (error) {
			message.error('Se produjo un Error al guardar los cambios!')	
		}
		
	}

  return {
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    eventStatuses,
    setEventStatuses,
    eventResults,
    setEventResults,
    searchText,
    setSearchText,
    selectedEvent,
    setSelectedEvent,
    additionalEvents,
    setAdditionalEvents,
		selectedColumns,
		setSelectedColumns,
		columnsToHide,
		alwaysVisibleColumns,
		columnNames
  }

};

export default useClinicalWorklist;