import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Layout,
  Avatar
} from "antd";

import { UserOutlined } from '@ant-design/icons'

import Menuapp, { PhysicianMenu, PatientMenu } from './Components/Menuapp';

import Headerlife from './Components/Headerlife';

import { globalColors, globalColorMode } from './GraphQL/LocalState/globalState';
import { useReactiveVar } from '@apollo/client';


const { Content, Footer, Sider } = Layout;


function MainLayout({
  setShowLogin, 
  children,
  setColorMode
}) {  

  //console.log('children: ', children)

  const [isCollapsed, setIsCollapsed] = useState(true);

  const localGlobalColors = useReactiveVar(globalColors)

  //const localGlobalColorMode = useReactiveVar(globalColorMode)

  const location = useLocation();
  
  function toggleCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  const displayMenu = () => {

    const adminRoutes = [...mainRoutesMap.keys(), ...adminPanelRoutesMap.keys(), ...paymentsPanelRoutesMap.keys(), ...statisticsRoutesMap.keys()];

    const medicalRoutes = [...physicianRoutesMap.keys()];

    const patientRoutes = [...patientRoutesMap.keys()];

    if (patientRoutes.some(route => location.pathname.includes(route))) {
      return (
        <PatientMenu isCollapsed={isCollapsed}/>
      );
    }

    if (medicalRoutes.some(route => location.pathname.includes(route))) {
      return (
        <PhysicianMenu isCollapsed={isCollapsed} />
      );
    }

    if (adminRoutes.some(route => location.pathname.includes(route))) {
      return (
        <Menuapp isCollapsed={isCollapsed} />
      );
    }    

  };

  return ( 
    <Layout className="site-layout h-full">      
      <Sider
        className={localGlobalColors.back_color}
        trigger={null}
        collapsible
        collapsed={isCollapsed}
      >
        <div className={`logo text-center my-3 ${localGlobalColors.back_color}`}>
          <Avatar className="w-12 h-12" icon={<UserOutlined />} />
        </div>
        {displayMenu()}
      </Sider>
      <Layout className="site-layout" >
        <Headerlife        
          collapsed={isCollapsed}
          toggleMenu={toggleCollapsed}
          setShowLogin={setShowLogin}
          backColor={localGlobalColors.back_color} 
          setColorMode={setColorMode}
        />
        <Content className="site-layout-background">
          { children }
        </Content>
        <Footer className={localGlobalColors.back_color} style={{height: "6%"}}>
          RetinaRAD Desktop v1.84.0001.001
        </Footer>
      </Layout>      
    </Layout>
  );
  

}

export default MainLayout;


const mainRoutesMap = new Map([
  ['/administrative/worklist', 'Administrative Worklist'],
  ['/administrative/worklist/patient-data-delivery', 'Patient Data Delivery'],
  ['/administrative/schedule', 'Schedule Module'],
  // ['/clinical/procedures', 'procedures'],
  ['/clinical/physician/worklist', 'Clinical Worklist'],
]);

const adminPanelRoutesMap = new Map([  
  ['/administrative/adminpanel/dashboard', 'Dashboard'],
  ['/administrative/adminpanel/masterdata', 'Master Data'], 
  ['/administrative/adminpanel/tenants', 'Tenants'], 
  ["/administrative/adminpanel/specialities", 'Specialities'],
  ["/administrative/adminpanel/billing-codes", 'Billing Codes'],
  ["/administrative/adminpanel/procedures", 'Procedures'],
  ["/administrative/adminpanel/zones", 'Zones'],
  ["/administrative/adminpanel/areas", 'Areas'],
  ["/administrative/adminpanel/centers", 'Centers'],
  ['/administrative/adminpanel/persons', 'Persons'],
  ["/administrative/adminpanel/professionals", 'Professionals'],
  ['/administrative/adminpanel/permissions', 'Permissions'],
  ["/administrative/adminpanel/roles", 'Roles'],
  ["/administrative/adminpanel/users", 'Users'],
  ["/administrative/adminpanel/professional-schedule", 'Professional Schedule'],
  ["/administrative/adminpanel/equipments", 'Equipments'],
  ["/administrative/adminpanel/equipment-schedule", 'Equipment Offers'],
  ["/administrative/adminpanel/token-tags", 'Token Tags'],
  ["/administrative/adminpanel/templates", 'Templates'],
  ["/administrative/adminpanel/dynamic-doctos", 'Dynamic Documents'],
  ["/administrative/adminpanel/insurance-corps", 'Insurance Corps'],
  ["/administrative/adminpanel/price-agreements", 'Price Agreements']   
]);

const paymentsPanelRoutesMap = new Map([  
  ['/administrative/payments/cashier-sessions', 'Cashier Sessions'],
  ['/administrative/payments/user-cashier-sessions', 'User Cashier Sessions']  
]);

const statisticsRoutesMap = new Map([  
  ['/statistics/reportsproduction', 'Producción Informes de Estudios'],
  ['/statistics/eventsproduction', 'Producción de Eventos']
]);

const physicianRoutesMap = new Map([
  ['/clinical/physician/worklist', 'My Clinical Worklist'],
  ['/clinical/physician/patients', 'Mis Pacientes'], 
  ['/clinical/physician/schedule', 'Mi Agenda'], 
  ['/clinical/physician/statistics', 'Estadísticas'],
  ['/clinical/radiology/tec/worklist', 'Tec Worklist'],
  ['/clinical/radiologist/worklist', 'Radiologist Worklist'],
  ['/clinical/ai/radiologist/ai-worklist', 'Radiologist AI Worklist'],
  ['/clinical/ophthalmologist/worklist', 'Ophthalmologist Worklist'],
]);

const patientRoutesMap = new Map([
  ['/clinical/physician/patients/medical_attentions', 'Atenciones'],
  ['/clinical/physician/patients/exams_and_procedures', 'Exámenes y Procedimientos'],
  ['/clinical/physician/patient/ehr', 'Registro Clinico'],
  ['/clinical/physician/patient/radreport', 'Registro Radiología'],
  ['/clinical/physician/patient/ophthalmologyreport', 'Registro Oftalmología'],
]);