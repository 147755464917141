import React, { useState } from 'react';

import usePatient from '../Hooks/usePatient.js';

import useSearch from '../Hooks/useSearch';

import useStudy from '../Hooks/useStudy';


export const MainContext = React.createContext({

    patientInfo: {
        patientLoaded: false,
        patientData: {},
        openPatient: () => {},
        closePatient: () => {},
        setPatientData: () => {}
    },
    searchData: {
        searchType: null,
        searchDetail: null,
        secondarySearch: null,
        searchDateRange: {
            startDate: null,
            endDate: null
        },
        handleSearchType: () => {},
        handleSearchDetail: () => {},
        handleSearchDateRange: () => {},
        handleSecondarySearch: () => {},
        resetSearchDetail: () => {}
    },
    studyData: {
        studiesState: {
            allIDs: [],
            studyById: {},
            scheduled: {} 
        },        
        setAddStudy: () => {},
        setRemoveStudy: () => {},
        setScheduleStudy: () => {},
        setUnscheduleStudy: () => {},
        setAddMany: () => {},
        setRemoveMany: () => {},
        setSelectedStudies: () => {},
        resetStudies: () => {}
    }, 
    sessionData: {
      visitNumber: null,
      orderNumber: null,
    }
});

const MainContextProvider = ({ children }) => {

    const [visitNumber, setVisitNumber] = useState(null);
    const [orderNumber, setOrderNumber] = useState(null);

    const patientInfo = usePatient();

    const searchData = useSearch();

    const studyData = useStudy();
   
    const sessionData = {
      visitNumber,
      setVisitNumber,
      orderNumber,
      setOrderNumber
    };

    const contextValue = {
        patientInfo,
        searchData,
        studyData,
        sessionData
    };

    return (
        <MainContext.Provider value={contextValue} >
            {children}
        </MainContext.Provider>
    );

};

export default MainContextProvider;