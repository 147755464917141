import React from 'react';
import moment from 'moment';

import useReceptionWorklist from '../Hooks/useReceptionWorklist';

import usePhysicianWorklist from '../Hooks/usePhysicianWorklist';

import usePhysicianPatientData from '../Hooks/usePhysicianPatientData';

import useRadiologyPatientData from '../Hooks/useRadiologyPatientData';

import usePatientDataDeliveryWorklist from '../Hooks/usePatientDataDeliveryWorklist';
import useRadiologyWorklist from '../Hooks/useRadiologyWorklist';
import { WORKLIST_COLUMN_LABELS } from '../Constants/worklistContext';
import useAIRadWorklist from '../Hooks/useAIRadWorklist';
import { globalUserWorklistColumns } from '../../GraphQL/LocalState/globalState';
import { useReactiveVar } from '@apollo/client';
import useClinicalWorklist from '../Hooks/useClinicalWorklist';
import useTecWorklist from '../Hooks/useTecWorklist';
import useOphthalmologyWorklist from '../Hooks/useOphthalmologyWorklist';

const dateFormat = 'DD-MM-YYYY';

export const WorklistContext = React.createContext({
  receptionWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
  },
  physicianWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
    selectedEvent: null,
    setSelectedEvent: (eventData) => null,
		selectedColumns: {},
		setSelectedColumns: () => {},
		selectedColumnsLabels: {},
		columnsToHide: [],
		alwaysVisibleColumns: [],
		columnNames: []
  },
  physicianPatientData: {  
    visitReason: '',
    setVisitReason: () => null,
    anamnesis: '',
    setAnamnesis: () => null,
    record: '',
    setRecord: () => null,
    diagnoses: [],
    setDiagnoses: () => [],
    examsAndProcs: [],
    setExamsAndProcs: () => null,
    drugs: [],
    setDrugs: () => null,
    therapy: '',
    setTherapy: () => null,
    lastSaved: '',
    setLastSaved: () => null,
    isThereNewData: false,
    setIsThereNewData: () => null, 
    patientRecords: [],
    setPatientRecords: () => null,
    patientClinicalInfo: {},
    setPatientClinicalInfo: () => {},
    patientAlerts: null,
    setPatientAlerts: () => null
  },
  tecWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
  },
  radWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
    selectedEvent: null,
    setSelectedEvent: (eventData) => null,
		selectedColumns: {},
		setSelectedColumns: () => {},
		selectedColumnsLabels: {},
		columnsToHide: [],
		alwaysVisibleColumns: [],
		columnNames: []
  },
  radiologyPatientData: {  
    lastSaved: '',
    setLastSaved: () => null,
    isThereNewData: false,
    setIsThereNewData: () => null, 
  },
  patientDataDeliveryWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
  },
  clinicalWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
    selectedEvent: null,
    setSelectedEvent: (eventData) => null,
    additionalEvents: [],
    setAdditionalEvents: () => {},
  },
	ophthalmologistWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
    selectedEvent: null,
    setSelectedEvent: (eventData) => null,
		selectedColumns: {},
		setSelectedColumns: () => {},
		selectedColumnsLabels: {},
		columnsToHide: [],
		alwaysVisibleColumns: [],
		columnNames: []
  },
	aiRadWorklistData: {
    dateFrom: moment().format(dateFormat),
    setDateFrom: () => null,
    dateTo: moment().format(dateFormat),
    setDateTo: () => null,
    eventStatuses: [],
    setEventStatuses: () => null,
    eventResults: [],
    setEventResults: () => {},
    searchText: '',
    setSearchText: () => {},
    selectedEvent: null,
    setSelectedEvent: (eventData) => null,
		selectedColumns: {},
		setSelectedColumns: () => {},
		selectedColumnsLabels: {},
		columnsToHide: [],
		alwaysVisibleColumns: [],
		columnNames: []
  },
	worklistColumLabels: WORKLIST_COLUMN_LABELS
});

const WorklistContextProvider = ({ children }) => {

	const userWorklistColumns = useReactiveVar(globalUserWorklistColumns)

  const receptionWorklistData = useReceptionWorklist(userWorklistColumns['administrative'], 'administrative');

  const physicianWorklistData = usePhysicianWorklist(userWorklistColumns['physician'], 'physician');

  const physicianPatientData = usePhysicianPatientData();

  const tecWorklistData = useTecWorklist(userWorklistColumns['technician'], 'technician');

  const radWorklistData = useRadiologyWorklist(userWorklistColumns['radiologist'], 'radiologist');

  const radiologyPatientData = useRadiologyPatientData();

  const patientDataDeliveryWorklistData = usePatientDataDeliveryWorklist(userWorklistColumns['patientDataDelivery'], 'patientDataDelivery');
  
  const clinicalWorklistData = useClinicalWorklist(userWorklistColumns['clinical'], 'clinical');

	const ophthalmologistWorklistData = useOphthalmologyWorklist(userWorklistColumns['ophthalmologist'], 'ophthalmologist');

	const aiRadWorklistData = useAIRadWorklist(userWorklistColumns['aiRadiologist'], 'aiRadiologist');

  const contextValue = {
    receptionWorklistData,
    physicianWorklistData,
    physicianPatientData,
    tecWorklistData,
    radWorklistData,
    radiologyPatientData,
    patientDataDeliveryWorklistData,
    clinicalWorklistData,
		ophthalmologistWorklistData,
		aiRadWorklistData,
		worklistColumLabels: WORKLIST_COLUMN_LABELS
  };


  return (
    <WorklistContext.Provider value={contextValue}>
      {children}
    </WorklistContext.Provider>
  );
};



export default WorklistContextProvider;