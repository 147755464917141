import React, { useContext } from 'react'
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Link } from 'react-router-dom'
import { Menu, Popconfirm } from 'antd'
import {
  UnorderedListOutlined,
  BarChartOutlined,
  CalendarOutlined,
  TeamOutlined,
  SettingOutlined,
  WarningOutlined,
  ExperimentOutlined,
  QuestionCircleOutlined,
  HddOutlined,
  ReconciliationOutlined,
  ExportOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  ProfileOutlined,
  EyeOutlined,
  RobotOutlined
} from '@ant-design/icons'

import { WorklistContext } from '../Shared/Context/worklistContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  globalLoggedUserInfo,
  globalIsMainPatientPageOpen,
  globalLastLocation, 
  globalColorMode,
  globalColors
} from '../GraphQL/LocalState/globalState.js';
import { getPersonInitials, titleCase } from '../Shared/Functions/stringFunctions';

const { SubMenu } = Menu;


const mainRoutesMap = new Map([
  ['/administrative/worklist', 'Administrative Worklist'],
  ['/administrative/worklist/patient-data-delivery', 'Patient Data Delivery'],
  ['/administrative/schedule', 'Schedule Module'],
  // ['/clinical/procedures', 'procedures'],
  ['/clinical/physician/worklist', 'Go to Clinical Menu'],
  ['/clinical/radiology/tec/worklist', 'Go to Clinical Menu'],
  ['/clinical/radiologist/worklist', 'Go to Clinical Menu'],
  ['/clinical/physician/patients','Go to Clinical Menu'], 
  ['/clinical/physician/schedule', 'Go to Clinical Menu'], 
  ['/clinical/ophthalmologist/worklist', 'Go to Clinical Menu'],
  ['/clinical/physician/statistics', 'Go to Clinical Menu']
]);

const mainRoutesMapToIcons = new Map([
  ['/administrative/worklist', UnorderedListOutlined],
  ['/administrative/worklist/patient-data-delivery', ExportOutlined],
  ['/administrative/schedule', CalendarOutlined],
  // ['/clinical/procedures', ForkOutlined],
  ['/clinical/physician/worklist', RightSquareOutlined],
  ['/clinical/radiology/tec/worklist', RightSquareOutlined],
  ['/clinical/radiologist/worklist', RightSquareOutlined],
  ['/clinical/physician/patients', RightSquareOutlined], 
  ['/clinical/physician/schedule', RightSquareOutlined], 
  ['/clinical/ophthalmologist/worklist', RightSquareOutlined], 
  ['/clinical/physician/statistics', RightSquareOutlined]
]);

const mainRoutesMapOrder = new Map([
  ['/administrative/worklist', 1],
  ['/administrative/worklist/patient-data-delivery', 2],
  ['/administrative/schedule', 3],
  // ['/clinical/procedures', 3],
  ['/clinical/physician/worklist', 4],
  ['/clinical/radiologist/worklist', 4],
  ['/clinical/radiology/tec/worklist', 4],
  ['/clinical/ophthalmologist/worklist', 4],
  ['/clinical/physician/patients', 4], 
  ['/clinical/physician/schedule', 4], 
  ['/clinical/physician/statistics', 4]
]);

const adminPanelRoutesMap = new Map([  
  ['/administrative/adminpanel/dashboard', 'Dashboard'],
  ['/administrative/adminpanel/masterdata', 'Master Data'], 
  ['/administrative/adminpanel/tenants', 'Tenants'], 
  ["/administrative/adminpanel/specialities", 'Specialities'],
  ["/administrative/adminpanel/billing-codes", 'Billing Codes'],
  ["/administrative/adminpanel/procedures", 'Procedures'],
  ["/administrative/adminpanel/zones", 'Zones'],
  ["/administrative/adminpanel/areas", 'Areas'],
  ["/administrative/adminpanel/centers", 'Centers'],
  ['/administrative/adminpanel/persons', 'Persons'],
  ["/administrative/adminpanel/professionals", 'Professionals'],
  ['/administrative/adminpanel/permissions', 'Permissions'],
  ["/administrative/adminpanel/roles", 'Roles'],
  ["/administrative/adminpanel/users", 'Users'],
  ["/administrative/adminpanel/professional-schedule", 'Professional Schedule'],
  ["/administrative/adminpanel/equipments", 'Equipments'],
  ["/administrative/adminpanel/equipment-schedule", 'Equipment Schedule'],
  ["/administrative/adminpanel/token-tags", 'Token Tags'],
  ["/administrative/adminpanel/templates", 'Templates'],
  ["/administrative/adminpanel/dynamic-doctos", 'Dynamic Documents'],
  ["/administrative/adminpanel/insurance-corps", 'Insurance Corps'],
  ["/administrative/adminpanel/price-agreements", 'Price Agreements']   
]);
const adminPanelRoutesMapOrder = new Map([  
  ['/administrative/adminpanel/dashboard', 1],
  ['/administrative/adminpanel/masterdata', 2], 
  ["/administrative/adminpanel/insurance-corps", 3],
  ['/administrative/adminpanel/tenants', 4],
  ["/administrative/adminpanel/billing-codes", 5],
  ["/administrative/adminpanel/procedures", 6], 
  ["/administrative/adminpanel/specialities", 7],
  ["/administrative/adminpanel/zones", 8],
  ["/administrative/adminpanel/areas", 9],
  ["/administrative/adminpanel/centers", 10],
  ['/administrative/adminpanel/persons', 11],
  ["/administrative/adminpanel/professionals", 12],
  ['/administrative/adminpanel/permissions', 13],
  ["/administrative/adminpanel/roles", 14],
  ["/administrative/adminpanel/users", 15],
  ["/administrative/adminpanel/professional-schedule", 16],
  ["/administrative/adminpanel/equipments", 17],
  ["/administrative/adminpanel/equipment-schedule", 18],
  ["/administrative/adminpanel/token-tags", 19],
  ["/administrative/adminpanel/templates", 20],
  ["/administrative/adminpanel/dynamic-doctos", 21],
  ["/administrative/adminpanel/price-agreements", 22]   
]);

const paymentsPanelRoutesMap = new Map([  
  ['/administrative/payments/cashier-sessions', 'Cashier Sessions'],
  ['/administrative/payments/user-cashier-sessions', 'User Cashier Sessions']
]);

const statisticsRoutesMap = new Map([  
  ['/statistics/reportsproduction', 'Informes Generados por Profesional'],
  ['/statistics/eventsproduction', 'Producción por Eventos'],
]);

const paymentsPanelRoutesMapOrder = new Map([  
  ['/administrative/payments/cashier-sessions', 1],
  ['/administrative/payments/user-cashier-sessions', 2]  
]);

const statisticsRoutesMapOrder = new Map([  
  ['/statistics/reportsproduction', 1],
  ['/statistics/eventsproduction', 2],
]);

const statisticsRoutesMapToIcon = new Map([
  ['/statistics/reportsproduction', BarChartOutlined],
  ['/statistics/eventsproduction', BarChartOutlined]
]);

const physicianRoutesMap = new Map([
  ['/clinical/physician/worklist', 'My Clinical Worklist'],
  ['/clinical/radiology/tec/worklist', 'Tec Worklist'],
  ['/clinical/radiologist/worklist', 'Radiologist Worklist'],
  ['/clinical/ai/radiologist/ai-worklist', 'AI Radiologist Worklist'],
  ['/clinical/ophthalmologist/worklist', 'Ophthalmologist Worklist'],
  ['/clinical/physician/patients', 'Mis Pacientes'], 
  ['/clinical/physician/schedule', 'Mi Agenda'], 
  ['/clinical/physician/statistics', 'Estadísticas'],
  ['/administrative/worklist', 'Go to administrative Menu'],
  ['/administrative/schedule', 'Go to administrative Menu'],
  ['/administrative/worklist/patient-data-delivery', 'Go to administrative Menu'],
]);

const physicianRoutesMapToIcons = new Map([
  ['/clinical/physician/worklist', ProfileOutlined],
  ['/clinical/radiology/tec/worklist', HddOutlined],
  ['/clinical/radiologist/worklist', ReconciliationOutlined],
  ['/clinical/ai/radiologist/ai-worklist', RobotOutlined],
  ['/clinical/physician/patients', TeamOutlined], 
  ['/clinical/physician/schedule', CalendarOutlined], 
  ['/clinical/physician/statistics', BarChartOutlined],
  ['/clinical/ophthalmologist/worklist', EyeOutlined],
  ['/administrative/worklist', LeftSquareOutlined],
  ['/administrative/schedule', LeftSquareOutlined],
  ['/administrative/worklist/patient-data-delivery', LeftSquareOutlined]
]);

const physicianRoutesMapOrder = new Map([
  ['/clinical/physician/worklist', 1],
  ['/clinical/radiology/tec/worklist', 2],
  ['/clinical/radiologist/worklist', 3],
  ['/clinical/ai/radiologist/ai-worklist', 4],
  ['/clinical/ophthalmologist/worklist', 5],
  ['/clinical/physician/patients', 5], 
  ['/clinical/physician/schedule', 6], 
  ['/clinical/physician/statistics', 7],
  ['/administrative/worklist', 8],
  ['/administrative/schedule', 9],
  ['/administrative/worklist/patient-data-delivery', 10]
]);


const patientRoutesMap = new Map([
  ['/clinical/physician/patients/medical_attentions', 'Atenciones'],
  ['/clinical/physician/patients/exams_and_procedures', 'Exámenes y Procedimientos']
]);

const patientRoutesMapToIcon = new Map([
  ['/clinical/physician/patients/medical_attentions', WarningOutlined],
  ['/clinical/physician/patients/exams_and_procedures', ExperimentOutlined]
]);

const patientRoutesMapOrder = new Map([
  ['/clinical/physician/patients/medical_attentions', 1],
  ['/clinical/physician/patients/exams_and_procedures', 2]
]);

const StyledMenuSpan = styled.span`

  margin: 0;
  padding: 0;
`;

const StyledMenuSpan2 = styled.span`

  margin: 0;
  padding: 0;
  font-size: 14px;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity .3s linear;
`;
  
export default function Menuapp({
  isCollapsed
}) {

  const localGlobalColors = useReactiveVar(globalColors)
  const localGlobalColorMode = useReactiveVar(globalColorMode)
  const localGlobalLoggedUserInfo = useReactiveVar(globalLoggedUserInfo);
  

  let allowedRoutes = [];

  if (localGlobalLoggedUserInfo) {
    
    allowedRoutes = localGlobalLoggedUserInfo.enabledRoutes.map(routeObject => routeObject.route);

  }
  
  const displayMainRoutes = () => {

    let validateDuplicate = []

    const mainRoutes = allowedRoutes.map((allowedRoute) => {

      let routeToRet

        if(mainRoutesMap.has(allowedRoute)) {

          mainRoutesMap.forEach((mainRouteDesc, route)=> {

              if(!validateDuplicate.includes(mainRouteDesc) && route === allowedRoute) {  
                validateDuplicate.push(mainRouteDesc)
                routeToRet = route
              }

          })

          return routeToRet
        } else {
          return null
        }
    }).filter(Boolean);

    const compare = (a, b) => {
      if (mainRoutesMapOrder.get(a) > mainRoutesMapOrder.get(b)) return 1;
      if (mainRoutesMapOrder.get(a) < mainRoutesMapOrder.get(b)) return -1;
      return 0;
    };

    const sortedMainRoutes = [...mainRoutes].sort(compare);

    return sortedMainRoutes.map(routeString => {

      const IconComponent = mainRoutesMapToIcons.get(routeString);

      return (
        <Menu.Item key={routeString} icon={<IconComponent className={localGlobalColors.menu_icon} />}>
          <Link to={routeString}>      
            <span>{mainRoutesMap.get(routeString)}</span>
          </Link>          
        </Menu.Item>
      );
    });

  }

  const displayAdminPanelRoutes = () => {

    const adminPanelRoutes = allowedRoutes.filter(routeString => {

      return adminPanelRoutesMap.has(routeString);

    });

    if (adminPanelRoutes.length === 0) return null;

    const compare = (a, b) => {
      if (adminPanelRoutesMapOrder.get(a) > adminPanelRoutesMapOrder.get(b)) return 1;
      if (adminPanelRoutesMapOrder.get(a) < adminPanelRoutesMapOrder.get(b)) return -1;
      return 0;
    };

    const sortedAdminPanelRoutes = [...adminPanelRoutes].sort(compare);

    return (
      <SubMenu
        key="AdminPanelSubMenu"
        icon={<SettingOutlined className={localGlobalColors.menu_icon} />}
        title={<StyledMenuSpan>Admin Panel</StyledMenuSpan>}        
      >
        {
          sortedAdminPanelRoutes.map(routeString => {

            return (
              <Menu.Item
                key={routeString}
                style={{ backgroundColor: '#f7fafc', margin: 0 }}
              >
                <Link to={routeString}>
                  <span>{adminPanelRoutesMap.get(routeString)}</span>
                </Link>
              </Menu.Item>
            );
          })
        }
      </SubMenu>
    );

  }

  const displayPaymentsPanelRoutes = () => {
    
    const paymentsPanelRoutes = allowedRoutes.filter(routeString => {
  
      return paymentsPanelRoutesMap.has(routeString);
  
    });
  
    if (paymentsPanelRoutes.length === 0) return null;
  
    const compare = (a, b) => {
      if (paymentsPanelRoutesMapOrder.get(a) > paymentsPanelRoutesMapOrder.get(b)) return 1;
      if (paymentsPanelRoutesMapOrder.get(a) < paymentsPanelRoutesMapOrder.get(b)) return -1;
      return 0;
    };
  
    const sortedPaymentsPanelRoutes = [...paymentsPanelRoutes].sort(compare);
  
    return (
      <SubMenu
        key="PaymentsPanelSubMenu"
        icon={<FontAwesomeIcon className={localGlobalColors.menu_icon} icon="cash-register" />}
        title={<StyledMenuSpan2 visible={!isCollapsed}>Payments</StyledMenuSpan2>}  
      >
        {
          sortedPaymentsPanelRoutes.map(routeString => {
  
            return (
              <Menu.Item
                key={routeString}
              >
                <Link to={routeString}>
                  <span>{paymentsPanelRoutesMap.get(routeString)}</span>
                </Link>
              </Menu.Item>
            );
          })
        }
      </SubMenu>
    );
  
  }

  const displayStatisticsRoutes = () => {
    
    const statisticsRoutes = allowedRoutes.filter(routeString => {
  
      return statisticsRoutesMap.has(routeString);
  
    });
  
    if (statisticsRoutes.length === 0) return null;
  
    const compare = (a, b) => {
      if (statisticsRoutesMapOrder.get(a) > statisticsRoutesMapOrder.get(b)) return 1;
      if (statisticsRoutesMapOrder.get(a) < statisticsRoutesMapOrder.get(b)) return -1;
      return 0;
    };
  
    const sortedstatisticsRoutes = [...statisticsRoutes].sort(compare);
  
    return (
      <SubMenu
        key="StatisticsSubMenu"
        icon={<BarChartOutlined/>}
        title={<StyledMenuSpan2 visible={!isCollapsed}>Statistics</StyledMenuSpan2>}  
      >
        {
          sortedstatisticsRoutes.map(routeString => {
  
            return (
              <Menu.Item
                key={routeString}
              >
                <Link to={routeString}>
                  <span>{statisticsRoutesMap.get(routeString)}</span>
                </Link>
              </Menu.Item>
            );
          })
        }
      </SubMenu>
    );
  
  }

  return (
    <div>
      <StyledUserInfo
        userObject={localGlobalLoggedUserInfo}
        isMenuCollapsed={isCollapsed}
      />
      <StyledMenuTitle>Menu</StyledMenuTitle>
      <Menu
        mode="inline"
        theme={localGlobalColorMode}
        className={`${localGlobalColors.back_color}`}
        defaultSelectedKeys={localGlobalLoggedUserInfo?.defaultRoute || undefined}
        onClick={() => globalIsMainPatientPageOpen(false)}   
      >
        {displayMainRoutes()}
        {displayAdminPanelRoutes()}
        {displayPaymentsPanelRoutes()}
        {displayStatisticsRoutes()}
      </Menu>
    </div>
  );
}

const StyledCashRegisterIcon = styled(FontAwesomeIcon)`

  && {
    color: #4a5568;
    font-size: 16px;
    margin-right: 10px;
    transition: color .3s linear;
  }

  &:hover {
    color: #4299e1;
  }
`;


const StyledMenuTitle = styled.span`
  padding: 0;
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;  
  line-height: 16px;  
  margin-top: 20px;
  color: #4a5568;
`;


export function PhysicianMenu({
  isCollapsed
}) {

  const localGlobalColors = useReactiveVar(globalColors)
  const localGlobalColorMode = useReactiveVar(globalColorMode)
  const localGlobalLoggedUserInfo = useReactiveVar(globalLoggedUserInfo);

  const location = useLocation();

  
  let allowedRoutes = [];

  if (localGlobalLoggedUserInfo) {
    
    allowedRoutes = localGlobalLoggedUserInfo.enabledRoutes.map(routeObject => routeObject.route);

  }

  

const displayPhysicianRoutes = () => {

  let validateDuplicate = []

  const physicianRoutes = allowedRoutes.map((allowedRoute) => {

    let routeToRet

      if(physicianRoutesMap.has(allowedRoute)) {

        physicianRoutesMap.forEach((physicianRouteDesc, route)=> {

            if(!validateDuplicate.includes(physicianRouteDesc) && route === allowedRoute) {  
              validateDuplicate.push(physicianRouteDesc)
              routeToRet = route
            }
        })

        return routeToRet
      } else {
        return null
      }
  }).filter(Boolean);

  const compare = (a, b) => {
    if (physicianRoutesMapOrder.get(a) > physicianRoutesMapOrder.get(b)) return 1;
    if (physicianRoutesMapOrder.get(a) < physicianRoutesMapOrder.get(b)) return -1;
    return 0;
  };

  const sortedPhysicianRoutes = [...physicianRoutes].sort(compare);

  return sortedPhysicianRoutes.map(routeString => {

    const IconComponent = physicianRoutesMapToIcons.get(routeString);

    return (
      <Menu.Item key={routeString} icon={<IconComponent style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} />}>
        <Link to={routeString}>      
          <span>{physicianRoutesMap.get(routeString)}</span>
        </Link>          
      </Menu.Item>
    );
  });

}

  return (
    <div>
      <StyledUserInfo
        userObject={localGlobalLoggedUserInfo}
        isMenuCollapsed={isCollapsed}
      />
      <StyledMenuTitle>Menú Clínico</StyledMenuTitle>
      <Menu mode="inline" className="bg-gray-300" defaultSelectedKeys={location.pathname}>
        {displayPhysicianRoutes()}
      </Menu>
    </div>
  );
}

export function PatientMenu ({
  isCollapsed,
}) {

  const localGlobalColors = useReactiveVar(globalColors)
  const localGlobalColorMode = useReactiveVar(globalColorMode)

  const localGlobalLoggedUserInfo = useReactiveVar(globalLoggedUserInfo);

  const localLastLocation = useReactiveVar(globalLastLocation);
  
  const location = useLocation();

  const history = useHistory();

  const {
    physicianPatientData,
    radiologyPatientData,
  } = useContext(WorklistContext);
  
  const {  
    isThereNewData: isThereNewMedicalData,
  } = physicianPatientData;

  const {  
    isThereNewData: isThereNewRadiologyData,
  } = radiologyPatientData;
  
  let allowedRoutes = [];

  if (localGlobalLoggedUserInfo) {
    
    allowedRoutes = localGlobalLoggedUserInfo.enabledRoutes.map(routeObject => routeObject.route);

  }
  

const displayPatientRoutes = () => {
  
  const patientRoutes = allowedRoutes.filter(routeString => {

    return patientRoutesMap.has(routeString);

  });

  const compare = (a, b) => {
    if (patientRoutesMapOrder.get(a) > patientRoutesMapOrder.get(b)) return 1;
    if (patientRoutesMapOrder.get(a) < patientRoutesMapOrder.get(b)) return -1;
    return 0;
  };

  const sortedPatientRoutes = [...patientRoutes].sort(compare);

  return sortedPatientRoutes.map(routeString => {

    const IconComponent = patientRoutesMapToIcon.get(routeString);

    const getLinkTo = () => {

      if (location.pathname === '/clinical/physician/patient/ehr' || 
          location.pathname === '/clinical/physician/patient/radreport' || 
          location.pathname === '/clinical/physician/patient/ophthalmologyreport') 
          {

        return {
          pathname: routeString,
          state: { previousLocation: location.pathname }
        }

      }

      return {
        pathname: routeString,
        state: { previousLocation: location.state.previousLocation }
      }

    };

    return (      
        <Menu.Item key={routeString} icon={<IconComponent style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} />} defaultSelectedKeys={location.pathname}>
          <Link to={getLinkTo()}>      
            <span>{patientRoutesMap.get(routeString)}</span>
          </Link>          
        </Menu.Item>      
    );
  });

}

  
  const handleReturnButtonClick = () => {

    history.push(localLastLocation);

  };

  
  const displayReturnButton = () => {
    
    if (!isThereNewMedicalData && !isThereNewRadiologyData) {

      return (
        <Link title="Regresar" to={localLastLocation}>           
          Regresar           
        </Link>        
      );

    }

    return (
      <Popconfirm
        placement="right"
        title="Tiene informacion sin guardar, se perderán datos. ¿Desea Salir?"
        okText="Sí"
        cancelText="No"
        onConfirm={handleReturnButtonClick}    
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      >
        <Link title="Regresar">           
          Regresar           
        </Link>      
      </Popconfirm>
    );    
  };

  const getLinkTo = () => {

    return location.state.previousLocation;

  };

  const getLabelLinkTo = () => {

    if (location.state.previousLocation === '/clinical/physician/patient/ehr') {
      return 'Registro Clínico';
    }

    if (location.state.previousLocation === '/clinical/physician/patient/radreport') {
      return 'Registro Radiológico';
    }

    if (location.state.previousLocation === '/clinical/physician/patient/ophthalmologyreport') {
      return 'Registro Ofatalmológico';
    }

  }

  const getIconLinkTo = () => {

    if (location.state.previousLocation === '/clinical/physician/patient/ehr') {
      return <FontAwesomeIcon icon="notes-medical" />;
    }

    if (location.state.previousLocation === '/clinical/physician/patient/radreport') {
      return <ReconciliationOutlined style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} />;
    }

    if (location.state.previousLocation === '/clinical/physician/patient/ophthalmologyreport') {
      return <ReconciliationOutlined style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} />;
    }

  };
  
  return (
    <div>
      <StyledUserInfo
          userObject={localGlobalLoggedUserInfo}
          isMenuCollapsed={isCollapsed}
        />
      <StyledMenuTitle>Menú de Paciente</StyledMenuTitle>
      <Menu mode="inline" className={`${localGlobalColors.back_color}`}>
        {displayPatientRoutes()}
        {
          (location.pathname !== '/clinical/physician/patient/ehr' && location.pathname !== '/clinical/physician/patient/radreport' && location.pathname !== '/clinical/physician/patient/ophthalmologyreport' )
          &&  <Menu.Item key="/clinical/physician/patient/ehr1" icon={getIconLinkTo()}>        
                <Link to={getLinkTo()} title="Registro Clínico del paciente" >           
                  {getLabelLinkTo()}    
                </Link>        
              </Menu.Item> 
        }
        {
          (location.pathname === '/clinical/physician/patient/ehr' || location.pathname === '/clinical/physician/patient/radreport' || location.pathname === '/clinical/physician/patient/ophthalmologyreport')
          &&  <Menu.Item key="/clinical/physician/worklist" icon={<FontAwesomeIcon icon="undo-alt" />}>
                {displayReturnButton()}
              </Menu.Item>
        }     
        
      </Menu>
    </div>
  );
}

const StyledUserInfoContainer = styled.div`

  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;    
  padding: 5px;

`;

const StyledUserLogin = styled.h3`

  font-size: 12px;
  font-weight: bold;
  color: #718096;
  margin-bottom: 5px;
  text-align: center;
    
`;

const StyledUserName = styled.h3`

  font-size: 12px;
  font-weight: bold;
  color: #718096;
  text-align: center;
`;

const StyledUserInfo = ({ userObject, isMenuCollapsed }) => {
  
  const userName = isMenuCollapsed ? getPersonInitials(userObject.personName) : titleCase(userObject.personName);
  
  return (
    <StyledUserInfoContainer>
      <StyledUserLogin>{userObject.userLogin}</StyledUserLogin>
      <StyledUserName>{userName}</StyledUserName>
    </StyledUserInfoContainer>

  );
};