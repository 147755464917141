import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, ApolloProvider, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {getAccessKey, setAccessKey } from './setKey'
import { setMessageError } from './setMessagesError'; 
import { TokenRefreshLink,  } from 'apollo-link-token-refresh'
import jwtDecode from 'jwt-decode';
import { ConfigProvider, message } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import cache  from './GraphQL/cache';
import './Shared/FontAwesome/library';
import MainContextProvider from './Shared/Context/mainContext';
import WorklistContext from './Shared/Context/worklistContext';
import { globalIsExpired } from "./GraphQL/LocalState/globalState"; 
//import dotenv from 'dotenv';

//dotenv.config();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_LOCAL_BACKEND_URL || 'https://blues.medphs.com/',  
  credentials: 'include'
});

const refreshLink = new TokenRefreshLink({
  accessTokenField: "token",
  isTokenValidOrUndefined: () => {
    const token = getAccessKey();
    if(!token ) {
      return true
    }
    try {
      const {exp} = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        return false;
      } else {
        return true
      }
    } catch {
      return false
      
    }
  },
  fetchAccessToken: () => {
    return fetch(process.env.REACT_APP_LOCAL_GMARS_URL || "https://blues.medphs.com/gmars", {
      method: "POST",
      credentials: "include"
    });
  },
  handleFetch: token => {
    setAccessKey(token)
  },
  handleError: err => {
    globalIsExpired(true);
    setMessageError();
    setAccessKey();
    console.log(err)
  }
})

//Check if there is any network error and set a message in setMessageErrorFunction
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors ){

    graphQLErrors.forEach(({ message, locations, path }) => {
    
        setMessageError(
          `BE: ${message}`
        )

    }
    )};
  if (networkError) {

    setMessageError(`[Network error]: Please check your connection and try again`)
    message.error(`${networkError}: Please check your connection and try again`, 6)
  };
});

const authLink = setContext((_, { headers }) => {
  const accessKey = getAccessKey();
  
  if(accessKey) {
    return {
      headers: {
        ...headers,
        Authorization: accessKey ? `Bearer ${accessKey}` : "",   
      }
    }
  }
});

export const client = new ApolloClient({
   link: from([
     refreshLink,
     errorLink,
     authLink,
     httpLink,
   ]),
   cache
})

ReactDOM.render(
  //<React.StrictMode>
    <ApolloProvider client={client}>
      <ConfigProvider locale={esES}>
        <MainContextProvider>
          <WorklistContext>
            <App />
          </WorklistContext>          
        </MainContextProvider>        
      </ConfigProvider>
    </ApolloProvider>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
