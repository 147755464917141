import { useState } from 'react';

const useRadiologyPatientData = () => {
    
  const [lastSaved, setLastSaved] = useState('');
  const [isThereNewData, setIsThereNewData] = useState(false);
    
  return {        
    lastSaved,
    setLastSaved,
    isThereNewData,
    setIsThereNewData,
  }


};

export default useRadiologyPatientData;