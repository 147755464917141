import { useState } from 'react';
import moment from 'moment';
import { message } from 'antd';
import { DEFAULT_WORKLIST_SELECTED_COLUMNS } from '../Constants/worklistContext';
import { globalLoggedUserInfo, globalUserWorklistColumns } from '../../GraphQL/LocalState/globalState';
import { useApolloClient } from '@apollo/client';
import { SAVE_USER_PREFERENCES } from '../../GraphQL/mutations';
const dateFormat = 'DD-MM-YYYY';

const DEFAULT_RECEPTION_WORKLIST_COLUMNS = {
	...DEFAULT_WORKLIST_SELECTED_COLUMNS,	
	accessionNumber: false,	
	appointmentReason: false,
	aiAnalisis: false,
	aiAnalisisScore: false,
	aiQuality: false,
	signedByProfessionals: false	
}

const useReceptionWorklist = (userColumns, worklistName) => {
	
  const [dateFrom, setDateFrom] = useState(moment().format(dateFormat))
  const [dateTo, setDateTo] = useState(moment().format(dateFormat))
  const [eventStatuses, setEventStatuses] = useState([]);
  const [eventResults, setEventResults] = useState(null);
  const [searchText, setSearchText] = useState('');
  
	const [selectedColumns, setColumns] = useState(userColumns || DEFAULT_RECEPTION_WORKLIST_COLUMNS);

	const client = useApolloClient()

	const columnsToHide = [
		'accessionNumber',
		'appointmentReason',
		'aiAnalisis',
		'aiAnalisisScore',
		'aiQuality',		
		'signedByProfessionals',
	]
	const alwaysVisibleColumns = ['actions', 'eventStatus'];
	
	const columnNames = [
		'firstName',
		'lastName',
		'patientType',
		'isImported',
		'priority',
		'age',
		'sex',
		'patientId',
		'date',
		'time',
		'speciality',
		'paymentStatus',
		'visitNumber',
		'procedureCode',
		'procedureName',
		'professionalEquipment',
		'eventStatus',
		'actions'		
	]

	const setSelectedColumns = async columns => {

		setColumns(columns)

		globalUserWorklistColumns({
			...globalUserWorklistColumns(),
			[worklistName]: columns
		})
    
		try {
			await client.mutate({
				mutation: SAVE_USER_PREFERENCES,
				variables: {
					input: {
						userID: globalLoggedUserInfo()._id,
						worklist: worklistName,
						...columns
					}
				},
				fetchPolicy: 'network-only'
			})
			message.success('Sus cambios han sido guardados exitosamente!')
		} catch (error) {
			message.error('Se produjo un Error al guardar los cambios!')	
		}
		
	}
 
  return {
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    eventStatuses,
    setEventStatuses,
    eventResults,
    setEventResults,
    searchText,
    setSearchText,
		selectedColumns,
		setSelectedColumns,
		columnsToHide,
		alwaysVisibleColumns,
		columnNames
  }

};

export default useReceptionWorklist;