import { makeVar } from '@apollo/client';

// VARIABLE GLOBAL QUE ALMACENA LOS DATOS DEL USUARIO LOGUEADO

export const globalLoggedUserInfo = makeVar(null);

export const globalCurrentTenant = makeVar(null);

export const globalCurrentCenter = makeVar(null);

export const globalCurrentArea = makeVar(null);

export const globalAppointmentType = makeVar(null);

export const globalAreaProceduresID = makeVar(null);

export const globalEventStatus = makeVar({
    deleted: 'Deleted',
    created: 'Agendado',
    admitted: 'Admitido',
    inprogress: 'En Progreso',
    canceled: 'Cancelado',
    discontinued: 'Descontinuado',
    paused: 'Pausado',
    recall: 'En Recitacion',
    arrived: 'Arribado',
    finished: 'Realizado',
    'inmeddocto': 'En documento',
    'inmeddocto-onhold': 'Docto - En ventana',
    'inmeddocto-inactive': 'Docto - Inactivo',
    'inmeddocto-deleted': 'Docto - Eliminado',
    'inmeddocto-initial': 'Docto - Inicial',
    'inmeddocto-draft': 'Docto - Borrador',
    'inmeddocto-signed': 'Docto - Firmado',
    'inmeddocto-delivered': 'Docto - Entregado',
    'inmeddocto-recalled': 'Docto - En Recitacion',
    'inmeddocto-peerreview': 'Docto - En Revision',
    'inmeddocto-signed-addendum': 'Docto - Firmado Addendum',
  });


// ******* MAIN PATIENT CONSULT VARIABLES

export const globalIsMainPatientPageOpen = makeVar(false);

export const globalMainPatientPageInputText = makeVar('');

export const globalMainPatientPageResults = makeVar(null);


export const globalPatientData = makeVar(null);  // CUANDO EN EL MENU DEL MEDICO ENTRAMOS EN MIS PACIENTES Y LUEGO
// HACEMOS CLICK EN UN PACIENTE, SE GUARDAN LOS DATOS DEL PACIENTE AQUI.


// ************* LOCATION VARIABLES ********************

export const globalLastLocation = makeVar(null);

export const globalPostUrlToImages = makeVar(process.env.REACT_APP_LOCAL_IMAGE_SIGN_URL || 'https://blues.medphs.com/pluton')

// ************** PREFERENCIAS DEL USUARIO *****************

export const globalDefaultImageViewer = makeVar(null);

export const globalIsExpired = makeVar(false)

export const globalColors = makeVar(null) 

export const globalColorMode = makeVar(null)

export const globalLastQueryAdmWL = makeVar(null)

export const globalLastTableFiltersAdmWL = makeVar(null)

export const globalLastQueryClinicalWL = makeVar(null)

export const globalLastTableFiltersClinicalWL = makeVar(null)


// ************** USER´S SELECTED COLUMNS FOR WORKLISTS

export const globalUserWorklistColumns = makeVar({})