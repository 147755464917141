import { useState } from 'react';




const usePatient = () => {

    const [patientLoaded, setPatientLoaded] = useState(false); 
    const [patientData, setPatientData] = useState({});
    
    const openPatient = () => {

        setPatientLoaded(true);
    
      };
    
      const closePatient = () => {
    
        setPatientLoaded(false);
    
      };
   
      
      return {
        patientLoaded,
        patientData,
        openPatient,
        closePatient,
        setPatientData
      };


};

export default usePatient;

