import { useState } from 'react';

const usePhysicianPatientData = () => {
  
  const [visitReason, setVisitReason] = useState('');
  const [anamnesis, setAnamnesis] = useState('');
  const [record, setRecord] = useState('');
  const [diagnoses, setDiagnoses] = useState([]);
  const [examsAndProcs, setExamsAndProcs] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [therapy, setTherapy] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const [isThereNewData, setIsThereNewData] = useState(false);
  const [patientRecords, setPatientRecords] = useState([]);
  const [patientClinicalInfo, setPatientClinicalInfo] = useState(defaultClinicalInfo);
  const [patientAlerts, setPatientAlerts] = useState(null);
    
  return {    
    visitReason,
    setVisitReason,
    anamnesis,
    setAnamnesis,
    record,
    setRecord,
    diagnoses,
    setDiagnoses,
    examsAndProcs,
    setExamsAndProcs,
    drugs,
    setDrugs,
    therapy,
    setTherapy,
    lastSaved,
    setLastSaved,
    isThereNewData,
    setIsThereNewData,
    patientRecords,
    setPatientRecords,
    patientClinicalInfo,
    setPatientClinicalInfo,
    patientAlerts,
    setPatientAlerts
  }


};

export default usePhysicianPatientData;

const defaultClinicalInfo = {
  _id: '0',
  "personal_family_history": {
    "occupation": "",
    "family_group": "",
    "family_background": ""
  },
  "morbid_antecedents": {
    "diseases": [],
    "surgeries": [],
    "others": ""
  },
  "allergies": {
    "medicines": [],
    "food": [],
    "others": ""
  },
  "gynecological_history": {
    "menarche_age": 0,
    "menopause_age": 0,
    "duration_days": 0,
    "frecuency_days": 0,
    "Flow_intensity": "",
    "FUR": "",
    "prengnancy": "",
    "others": ""
  },
  "habits": {
    "smoking": false,
    "alcohol": false,
    "type_feeding": "",
    "others": ""
  },
  "medicines": {
    "diseases": [],
    "surgeries": [],
    "others": ""
  },
  "immunizations": {
    "diseases": [],
    "surgeries": [],
    "others": ""
  },
};

