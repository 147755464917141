import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import { Layout, Input, Dropdown, Menu, Avatar, Modal, Tooltip, message } from "antd";
import ModalChangeUserPassword from "./ModalChangeUserPassword";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { setAccessKey } from '../setKey';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ExclamationCircleOutlined, 
} from "@ant-design/icons";

import {
  globalLoggedUserInfo,
  //globalLoggedUserInfo_OLD,
  globalCurrentTenant,
  globalCurrentCenter,
  globalCurrentArea,
  globalAppointmentType,
  globalIsMainPatientPageOpen,
  globalMainPatientPageInputText,
  globalMainPatientPageResults,  
  globalAreaProceduresID,
  globalColors,
  globalColorMode
} from '../GraphQL/LocalState/globalState';

import { GET_PERSON_EVENTS_BY_PERSON_SSN, LOGOUT_USER } from '../GraphQL/remoteQueries';
import { getMessageError } from "../setMessagesError";

import * as stringFunctions from '../Shared/Functions/stringFunctions';


const { SubMenu, ItemGroup } = Menu;
const { confirm } = Modal;

export default function Headerlife(props) {

  const location = useLocation();

  const localGlobalLoggedUserInfo = useReactiveVar(globalLoggedUserInfo);
  
  const localGlobalCurrentCenter = useReactiveVar(globalCurrentCenter);

  const localGlobalCurrentArea = useReactiveVar(globalCurrentArea);

  const localGlobalAppointmentType = useReactiveVar(globalAppointmentType);
  
  const localMainPatientPageInputText = useReactiveVar(globalMainPatientPageInputText);

  const localGlobalIsMainPatientPageOpen = useReactiveVar(globalIsMainPatientPageOpen)

  const localGlobalColors = useReactiveVar(globalColors)

  const localGlobalColorMode = useReactiveVar(globalColorMode)
    
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);

  const [currentPatSSNIdSearch, setCurrentPatSSNIdSearch] = useState('')

  const [
    logoutUserQuery,
    { client }
  ] = useLazyQuery(LOGOUT_USER, {fetchPolicy: 'network-only', onCompleted: () => {
    setAccessKey(null);
    client.clearStore();
    globalLoggedUserInfo(null);
    //globalLoggedUserInfo_OLD(null);    
    globalCurrentTenant(null);
    globalCurrentCenter(null);
    globalCurrentArea(null);
    globalAppointmentType(null);
    globalIsMainPatientPageOpen(false);
    globalMainPatientPageInputText('');
    globalMainPatientPageResults(null); 
    
  } });

  const [getMainPatientData, { loading: patientLoading, error: patientError, data: patientData } ] = useLazyQuery(GET_PERSON_EVENTS_BY_PERSON_SSN, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  });


  useEffect(() => {
    if ((patientData && !currentPatSSNIdSearch ) || (patientData && currentPatSSNIdSearch && patientData.getPersonEventsByPersonSsnPersonintID?.queryResults?.ssn_personintId !== currentPatSSNIdSearch)) {     
      globalMainPatientPageResults(patientData.getPersonEventsByPersonSsnPersonintID);
      setCurrentPatSSNIdSearch(patientData.getPersonEventsByPersonSsnPersonintID?.queryResults?.ssn_personintId)
    }
  }, [patientData, currentPatSSNIdSearch]);

  useEffect(() => {
    if(patientData && patientData.getPersonEventsByPersonSsnPersonintID?.queryResults?.ssn_personintId === currentPatSSNIdSearch) globalIsMainPatientPageOpen(true);
  }, [patientData, currentPatSSNIdSearch])

  useEffect(() => {
    if (patientError && getMessageError()) {
      return message.error(getMessageError());
    }

    if (patientError) message.error(patientError.message);
  }, [patientError]);
  

  const locationPathName = location.pathname;

  const { Header } = Layout;  

  const handleCenterMenuClick = (e) => {
    
    const [centerIdOrAppointmentType, tenantId] = e.keyPath;

    const tenantObject = getTenantFromId(locationPathName, tenantId);

    if (centerIdOrAppointmentType === 'telemedicine') {

      globalCurrentCenter(null);

      globalAppointmentType("telemedicine");

    }

    if (centerIdOrAppointmentType === 'domiciliary') {

      globalCurrentCenter(null);

      globalAppointmentType("domiciliary");

    }

    if (!!centerIdOrAppointmentType && centerIdOrAppointmentType !== 'telemedicine' && centerIdOrAppointmentType !== 'domiciliary') {
     
      const centerObject = getCenterFromId(locationPathName, centerIdOrAppointmentType);
  
      globalCurrentCenter({ ...centerObject });

      globalAppointmentType("center");

    }

    globalCurrentTenant({ ...tenantObject });


    globalCurrentArea(null);
  
   };

   const handleAreaMenuClick = (e) => {

    const areaId = e.key;

    const areaObject = getAreaFromId(locationPathName, areaId);

    globalCurrentArea({ ...areaObject });
    
    globalAreaProceduresID( 
      areaObject.areaProceduresID,
    );
    
   };
  
    //Build Menu tenant
  const buildTenantMenu = () => (
    <Menu theme={localGlobalColorMode} onClick={handleCenterMenuClick} mode="vertical" selectedKeys={localGlobalCurrentCenter ? [localGlobalCurrentCenter._id] : null}>
      <ItemGroup title="Tenants">
      {
        getAllowedTenantsForCurrentRoute(locationPathName).map(tenantObject => {
          return (
            <SubMenu title={tenantObject.tenantName} key={tenantObject.tenant_id}>              
              {
                getAllowedAppointmentTypesForCurrentRoute(locationPathName).includes("center")
                &&
                <ItemGroup title="Centers">
                  {
                    getAllowedCentersForCurrentRouteAndTenant(locationPathName, tenantObject.tenant_id).map(centerObject => {

                      return (
                        <Menu.Item key={centerObject.center_id} >
                          {centerObject.centerSocialName}
                        </Menu.Item>
                      );

                    })
                  }

                </ItemGroup>
              } 
              {
                getAllowedAppointmentTypesForCurrentRoute(locationPathName).includes("telemedicine")
                &&
                <ItemGroup title="Telemedicine">
                  <Menu.Item key="telemedicineHL">Telemedicine Events</Menu.Item>
                </ItemGroup>
              }
              {
                getAllowedAppointmentTypesForCurrentRoute(locationPathName).includes("domiciliary")
                &&
                <ItemGroup title="Domiciliary">
                  <Menu.Item  key="domiciliaryHL">Domiciliary Events</Menu.Item>
                </ItemGroup>
              }
            </SubMenu>
          );
        })
      }
      </ItemGroup>
    </Menu>
  );

  //Build Menu area
  const buildAreaMenu = () => {

    if (!localGlobalAppointmentType) {
      return (
        <Menu theme={localGlobalColorMode} mode="vertical">
          <Menu.Item>Select center first</Menu.Item>
        </Menu>
      );
    }

    return(
      <Menu theme={localGlobalColorMode} onClick={handleAreaMenuClick} mode="vertical">
        { localGlobalAppointmentType === "center" &&
          getAllowedAreasForCurrentRouteAndCenter(locationPathName, localGlobalCurrentCenter.center_id).map(areaObject => {

            return <Menu.Item key={areaObject.area_id}>{areaObject.areaName}</Menu.Item>;

          })
        } 
        {
          localGlobalAppointmentType === "telemedicine" &&
          getAllowedAreasForCurrentRouteAndTelemedicine(locationPathName).map(areaObject => {

            return <Menu.Item key={areaObject.area_id}>{areaObject.areaName}</Menu.Item>;

          })
        }    
        {
          localGlobalAppointmentType === "domiciliary" &&
          getAllowedAreasForCurrentRouteAndDomiciliary(locationPathName).map(areaObject => {

            return <Menu.Item key={areaObject.area_id}>{areaObject.areaName}</Menu.Item>;

          })
        }    
      </Menu>
    );
};
 
  //Build User Menu
  const menuUserOptions = (
    <Menu theme={localGlobalColorMode} onClick={openModalForSelectedUserMenuOption}>
      <Menu.Item key="userChangePassword">Change Password</Menu.Item>    
      <Menu.Item key="userLogout">Logout</Menu.Item>
    </Menu>
  );
 
  //Set visible for corresponding modal
  function openModalForSelectedUserMenuOption({ key }) {
    if (key === "userChangePassword") {
      setShowModalChangePassword(true);
    }
    if (key === "userLogout") {
      confirm({
        title: "Cerrar sesion?",
        icon: <ExclamationCircleOutlined />,
        okText: "Si",
        cancelText: "No",
        // content: 'Some descriptions',
        async onOk() {
          logoutUserQuery();
          props.setShowLogin(true);
        },
        onCancel() {
          console.log("Cancel")
        }
      });
    }
  }

  //Change lateral Menu Size
  function toggleCollapsed() {
    props.toggleMenu();
  }

  const getAllowedTenantsForCurrentRoute = (routePath) => {

    if (!localGlobalLoggedUserInfo) return [];
    
    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
  
    if(!routeObject) return [];

    const compare = (a, b) => {

      if (a.tenantName.toLowerCase() > b.tenantName.toLowerCase()) return 1;

      if (a.tenantName.toLowerCase() < b.tenantName.toLowerCase()) return -1;

      return 0;

    };

    const sortedTenants = [...routeObject.assignedTenants];
  
    return sortedTenants.sort(compare);  
  
  };

  const getAllowedAppointmentTypesForCurrentRoute = (routePath) => {

    if (!localGlobalLoggedUserInfo) return [];

    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);

    if(!routeObject) return [];

    return routeObject.assignedAppointmentTypes;

  };
  
  const getAllowedCentersForCurrentRouteAndTenant = (routePath, tenantId) => {
  
    if (!localGlobalLoggedUserInfo) return [];
  
    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
  
    if(!routeObject) return [];
    
    const centersList = routeObject.assignedCenters;
  
    const filteredCenters = centersList.filter(item => item.centerTenantID === tenantId);

    const compare = (a, b) => {

      if (a.centerSocialName.toLowerCase() > b.centerSocialName.toLowerCase()) return 1;

      if (a.centerSocialName.toLowerCase() < b.centerSocialName.toLowerCase()) return -1;

      return 0;

    };

    const sortedCenters = [...filteredCenters];
  
    return sortedCenters.sort(compare);  
  
  };
  
  const getAllowedAreasForCurrentRouteAndCenter = (routePath, centerId) => {
  
    if (!localGlobalLoggedUserInfo) return [];
  
    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
  
    if(!routeObject) return [];
    
    const centersList = routeObject.assignedCenters;
  
    const selectedCenter = centersList.find(item => item.center_id === centerId);
  
    if (!selectedCenter) return [];
  
    const areasIDs = selectedCenter.centerAllAreas;
  
    const areasList = routeObject.assignedAreas;
  
    const filteredAreas = areasList.filter(areaObject => {
  
      return areasIDs.some(item => item === areaObject.area_id) && areaObject.areaAppointmentTypes.includes("center");
      
    });

    const compare = (a, b) => {

      if (a.areaName.toLowerCase() > b.areaName.toLowerCase()) return 1;

      if (a.areaName.toLowerCase() < b.areaName.toLowerCase()) return -1;

      return 0;

    };

    const sortedAreas = [...filteredAreas];
  
    return sortedAreas.sort(compare);
  
  };

  const getAllowedAreasForCurrentRouteAndTelemedicine = (routePath, centerId) => {
  
    if (!localGlobalLoggedUserInfo) return [];
  
    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
  
    if(!routeObject) return [];
        
    const areasList = routeObject.assignedAreas;
  
    const filteredAreas = areasList.filter(areaObject => {
  
      return areaObject.areaAppointmentTypes.includes("telemedicine");
      
    });

    const compare = (a, b) => {

      if (a.areaName.toLowerCase() > b.areaName.toLowerCase()) return 1;

      if (a.areaName.toLowerCase() < b.areaName.toLowerCase()) return -1;

      return 0;

    };

    const sortedAreas = [...filteredAreas];
  
    return sortedAreas.sort(compare);
  
  };

  const getAllowedAreasForCurrentRouteAndDomiciliary = (routePath, centerId) => {
  
    if (!localGlobalLoggedUserInfo) return [];
  
    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
  
    if(!routeObject) return [];
        
    const areasList = routeObject.assignedAreas;
  
    const filteredAreas = areasList.filter(areaObject => {
  
      return areaObject.areaAppointmentTypes.includes("domiciliary");
      
    });

    const compare = (a, b) => {

      if (a.areaName.toLowerCase() > b.areaName.toLowerCase()) return 1;

      if (a.areaName.toLowerCase() < b.areaName.toLowerCase()) return -1;

      return 0;

    };

    const sortedAreas = [...filteredAreas];
  
    return sortedAreas.sort(compare);
  
  };
  
  const getTenantFromId = (routePath, tenantId) => {

    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
     
    if(!routeObject) return null;

    
    const tenantsList = routeObject.assignedTenants;

    const tenantObject = tenantsList.find(item => item.tenant_id === tenantId);

    if (!tenantObject) return null;

    return tenantObject;

  };

  const getCenterFromId = (routePath, centerId) => {

    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
  
    if(!routeObject) return null;

    const centersList = routeObject.assignedCenters;

    const centerObject = centersList.find(item => item.center_id === centerId);

    if (!centerObject) return null;

    return centerObject;

  };

  const getAreaFromId = (routePath, areaId) => {

    const routeObject = localGlobalLoggedUserInfo.enabledRoutes.find(item => item.route.toLowerCase() === routePath);
  
    if(!routeObject) return null;

    const areasList = routeObject.assignedAreas;

    const areaObject = areasList.find(item => item.area_id === areaId);

    if (!areaObject) return null;

    return areaObject;

  };

  const getCenterMenuTitle = (appType, currCenter) => {

    //if (!localGlobalAppointmentType) return 'Select a Center1';


    if (appType === 'center' || !appType) {

      if (currCenter) {

        return getCenterInitials(currCenter.centerInitials, currCenter.centerSocialName);

      } else {

        return 'Select Option';
      }
    }

    if (appType === 'telemedicine') {

      return "Telemedicine";
    }

    if (appType === 'domiciliary') {

      return "Domiciliary";
    }

  };

  const getCenterMenuPopup = () => {

    if (!localGlobalAppointmentType) return null;

    if (localGlobalAppointmentType === 'center') {

      if (localGlobalCurrentCenter) {

        return localGlobalCurrentCenter.centerSocialName;

      } else {

        return null;
      }
    }

    if (localGlobalAppointmentType === 'telemedicine') {

      return "Telemedicine Event";
    }

    if (localGlobalAppointmentType === 'domiciliary') {

      return "Domiciliary Event";
    }

  };

  const handleMainPatientSearchClick = (searchString) => {
    if (!searchString) return;

    globalMainPatientPageResults(null)
    
    getMainPatientData({
      variables: {
        SsnPersonintID: searchString,
      }
    });

    setCurrentPatSSNIdSearch('')


  };

  const handleMainPatientInputTextChange = (value) => {
    
    globalMainPatientPageInputText(value);

    if(!value && localGlobalIsMainPatientPageOpen) globalIsMainPatientPageOpen(false)

  };
  

  return (
    <Header className={`${localGlobalColors.back_color} h-13`} style={{ padding: 0, width:"100%" }}>
      <div style={{ paddingLeft: 20, paddingTop: 8, width: "100%" }}>
        <table style={{ lineHeight: "16px", paddingBottom: 20, width: "100%" }}>
          <thead style={{ width: "100%", paddingBottom: 20 }}>
            <tr style={{ width: "100%" }}>
              <th style={{ width: "auto" }}>
                <span style={{ width: "auto" }}> 
                  {props.collapsed ? (
                    <MenuUnfoldOutlined
                      style={{ fontSize: "1.4em", paddingRight: 10, color: localGlobalColors.anticon }}
                      onClick={toggleCollapsed}
                    />
                  ) : (
                    <MenuFoldOutlined
                      style={{ fontSize: "1.4em", paddingRight: 10, color: localGlobalColors.anticon }}
                      onClick={toggleCollapsed}
                    />
                  )}
                </span>
              </th>
              <th style={{ width: "75%", paddingLeft: 0 }}>
                <p className="text-2xl h-5 font-bold text-teal-700" style={{ width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                  RetinaRAD 
                  <span className={`text-lg font-normal ${localGlobalColors.text_header_color} `} style={{ marginLeft: 5, marginTop: 3 }}>
                    Desktop Cloud 
                  </span>
                </p>
              </th>
              <th style={{ width: "15%" }}>
                
                    <Input.Search
                      style={{ width: "100%", borderRadius: 15 }}
                      color={localGlobalColors.back_dropdown_menu_color}
                      allowClear
                      placeholder="Ingrese ID Paciente"
                      value={localMainPatientPageInputText}
                      onSearch={handleMainPatientSearchClick}
                      onChange={(e) => handleMainPatientInputTextChange(e.target.value)}
                      bordered
                      loading={patientLoading}
                    />
               
              </th>
              <th style={{ width: "5%" }}> 
                <StyledDropDownContainer className={localGlobalColors.styled_dropdown_container}>
                  <Dropdown overlay={buildTenantMenu()} trigger={['click']}>
                    <Tooltip
                      placement="bottom"
                      title={getCenterMenuPopup()}
                    >
                      <Link
                        to=""
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        <StyledCenterName className={localGlobalColors.styled_center_name}>
                          {getCenterMenuTitle(localGlobalAppointmentType, localGlobalCurrentCenter)}
                        </StyledCenterName>
                      </Link>
                    </Tooltip>                
                  </Dropdown>
                </StyledDropDownContainer>              
              </th>
              <th style={{ width: "10%" }}>
                <StyledDropDownContainer  className={localGlobalColors.styled_dropdown_container}>
                  <Dropdown overlay={buildAreaMenu()} trigger={['click']}>
                    <Tooltip
                        placement="bottom"
                        title={localGlobalCurrentArea ? localGlobalCurrentArea.areaName : ''}
                    >
                      <Link
                        to=""
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        <StyledCenterName className={localGlobalColors.styled_center_name}>
                          {
                            localGlobalCurrentArea
                            ? getInitials(localGlobalCurrentArea.areaName)
                            : 'Select Area'
                          }
                        </StyledCenterName>
                      </Link>
                    </Tooltip>                  
                  </Dropdown>
                </StyledDropDownContainer>               
              </th>
              <th style={{ width: "25% " }}>
                <Dropdown overlay={menuUserOptions}>
                  <Avatar
                    style={{
                      //backgroundColor: color,
                      verticalAlign: "middle"
                    }}
                    size="large"
                    //gap={gap}
                  >
                    {stringFunctions.getPersonInitials(localGlobalLoggedUserInfo?.personName || 'unknown unknown')}
                  </Avatar>
                </Dropdown>
              </th>
            </tr>
          </thead>
        </table>
        <hr className="header__separator"></hr>
      </div>
      <ModalChangeUserPassword      
        setShowModal={setShowModalChangePassword}
        showModal={showModalChangePassword}      
      />
    </Header>
  );
}



const StyledCenterName = styled.h3`

`;

const StyledDropDownContainer = styled.div`
    
`;

const getCenterInitials = (initials, name) => {

  if (!initials) return getInitials(name);

  return initials;

};

const getInitials = (name) => {

  const namesArray = name.split(' ');

  const initialsArray = namesArray.map(name => name[0] ? name[0]?.toUpperCase() : "");

  return initialsArray.join('.');

}




