import { useReducer } from 'react';


const initialState = {

    allIDs: [],
    studyById: {},
    scheduled: {}
};


const reducer = (state, action) => {
    
    switch (action.type) {
        case 'ADD_STUDY':

            const { studyObject } = action.payload;

            const id = studyObject._id;

            return {
                ...state,
                allIDs: [...state.allIDs, id],
                studyById: {
                    ...state.studyById,
                    [id]: studyObject
                },
                scheduled: {
                    ...state.scheduled,
                    [id]: false
                }
            };   
            
        case 'ADD_MANY':
           
            const { studiesObjectList } = action.payload;

            const newObjectList = {};

            const newScheduledList = {};

            const idList = studiesObjectList.map(item => {

                newObjectList[item._id]= item;

                newScheduledList[item._id]= false;

                return item._id;

            });

            return {
                ...state,
                allIDs: [...state.allIDs, ...idList],
                studyById: {
                    ...state.studyById,
                    ...newObjectList
                },
                scheduled: {
                    ...state.scheduled,
                    ...newScheduledList
                }
            };


        case 'REMOVE_STUDY':

            const { id: id2 } = action.payload;

            const filteredIDs = state.allIDs.filter(item => item !== id2 );

            const newStudies = {};

            const newScheduleStates = {};

            filteredIDs.forEach(item => {
                
                newStudies[item]= state.studyById[item];
                newScheduleStates[item]= state.scheduled[item];

            });

            return {
                ...state,
                allIDs: filteredIDs,
                studyById: newStudies,
                scheduled: newScheduleStates
            };


        case 'REMOVE_MANY':

            const { studiesIdList } = action.payload;

            const filteredIDs2 = state.allIDs.filter(item => {
                
                return !studiesIdList.some(idToRemove => idToRemove === item);

            });

            const newStudies2 = {};

            const newScheduleStates2 = {};

            filteredIDs2.forEach(item => {
                
                newStudies2[item]= state.studyById[item];
                newScheduleStates2[item]= state.scheduled[item];

            });


            return {
                ...state,
                allIDs: filteredIDs2,
                studyById: newStudies2,
                scheduled: newScheduleStates2
            };
        

        case 'SCHEDULE_STUDY':

            const { id: id3 } = action.payload;

            return {
                ...state,
                scheduled: {
                    ...state.scheduled,
                    [id3]: true
                }
            };

        case 'UNSCHEDULE_STUDY':

            const { id: id4 } = action.payload;

            return {
                ...state,
                scheduled: {
                    ...state.scheduled,
                    [id4]: false
                }
            };

        case 'LOAD_SELECTED_STUDIES':

            const { studiesState } = action.payload;

            const scheduledObject = {};
            
            studiesState.allIDs.forEach(item => {
              
                if (!!studiesState.scheduled[item]) {
                  return scheduledObject[item]= studiesState.scheduled[item]
                }

                scheduledObject[item]=false;                

            });

            return {
                ...state,
                    allIDs: studiesState.allIDs,
                    studyById: studiesState.studyById,
                    scheduled: scheduledObject
            };
        
        case 'RESET_STUDIES':
   
            return {
                allIDs: [],
                studyById: {},
                scheduled: {}
            };
    
        default:

            throw new Error('Action not allowed');
    }

};


const useStudy = () => {


    const [studiesState, dispatch] = useReducer(reducer, initialState);
    
        const setAddStudy = (studyObject) => {
            
            dispatch({
                type: 'ADD_STUDY',
                payload: {
                    studyObject
                }
            });

        };

        const setAddMany = studiesObjectList => {

            dispatch({
                type: 'ADD_MANY',
                payload: {
                    studiesObjectList
                }

            });

        };

        const setRemoveStudy = id => {

            dispatch({
                type: 'REMOVE_STUDY',
                payload: {
                    id
                }
            });
        };

        const setRemoveMany = studiesIdList => {

            dispatch({
                type: 'REMOVE_MANY',
                payload: {
                    studiesIdList
                }

            });

        };

        const setScheduleStudy = id => {
            dispatch({
                type: 'SCHEDULE_STUDY',
                payload: {
                    id
                }
            });
        };

        const setUnscheduleStudy = id => {
            dispatch({
                type: 'UNSCHEDULE_STUDY',
                payload: {
                    id
                }
            });
        };

        const setSelectedStudies = studiesState => {

            dispatch({
                type: 'LOAD_SELECTED_STUDIES',
                payload: {
                    studiesState
                }
    
            });
    
        };

        const resetStudies = () => {

            dispatch({
                type: 'RESET_STUDIES'
            });

        };
    

    
    return {
        studiesState,        
        setAddStudy,
        setRemoveStudy,
        setScheduleStudy,
        setUnscheduleStudy,
        setAddMany,
        setRemoveMany,
        setSelectedStudies,
        resetStudies
    };
   
};



export default useStudy;
