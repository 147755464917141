import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Form, Button, Input, message } from 'antd';
import { CHANGE_USER_PASSWORD } from '../GraphQL/mutations';
import { getMessageError } from '../setMessagesError';

export default function ModalChangeUserPassword(props) {
    
    const [changePassword, { loading, error, data }] = useMutation(CHANGE_USER_PASSWORD, {
      errorPolicy: "none"
    });

    const [formChangePassword] = Form.useForm();

    useEffect(() => {
      if (!props.showModal) {
        formChangePassword.resetFields();
      }
      // eslint-disable-next-line
    }, [props.showModal]);

    useEffect(() => {
      if (error) {

        if (getMessageError()) return message.error(getMessageError());

        return message.error('There was an Error changing the Password!');

      }
    }, [error]);

    useEffect(() => {
      if (data) {

        if (data.changeUserPass) {

          message.success('Password changed successfully');
          return props.setShowModal(false);

        }

        message.error('Current password error. Please try again');
        return props.setShowModal(false);
        
      }
      // eslint-disable-next-line
    }, [data]);

    
    const handleConfirmChangePassword = (values) => {

      try {

        changePassword({
          variables: {
            input: {
              old_pass: values.currentPassword,
              new_pass: values.newPassword
            }
          }
        });  
        
      } catch (error) {
        message.error('There was an Error changing the Password!');
      }

      
      

    };


    return (
        <Modal
            visible={props.showModal}
            title="Change Password"        
            onCancel={() => props.setShowModal(false)}
            destroyOnClose
            footer={[
                <Button key="back" onClick={() => props.setShowModal(false)} >
                  Cancel
                </Button>,
                <Button                        
                  type="primary"
                  form="formChangePassword"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>,
            ]}
        >
            <Form
                id="formChangePassword"
                form={formChangePassword}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                   currentPassword: '',
                   newPassword: '',
                   retypeNewPassword: ''
                }}
                onFinish={(values) => {

                  handleConfirmChangePassword(values);

                }}
            >
                <Form.Item
                    name="currentPassword"
                    label="Current Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your current password',
                        },
                    ]}
                >
                    <Input type="password"/>
                </Form.Item>
                <Form.Item 
                    name="newPassword" 
                    label="New password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password',
                        },
                    ]}
                    >
                    <Input type="password" />
                </Form.Item>
                <Form.Item 
                    name="retypeNewPassword" 
                    label="Confirm your New password"
                    rules={[
                        {
                            required: true,
                            message: 'Please retype your new password',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                                                                            
                            if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('The two passwords that you entered do not match!');
                          },
                        }),
                        ({ getFieldValue }) => ({
                          validator(rule, value) {

                            function checkPassReq(passString) {
                              const checkReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
                              if (passString.match(checkReg)) {
                                return true;
                              } else {
                                return false;
                              }
                            }
                                                                            
                            if (!!value & checkPassReq(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject('The password should have between 8 to 15 characters, contain at least one lowercase, one uppercase, one number, and one special char');
                          },
                        })
                    ]}
                    >
                    <Input type="password" />
                </Form.Item>
            </Form>
        </Modal>
    )
}


