import { gql } from '@apollo/client'

export const CREATE_EVENT = gql`
  mutation CreateEvent($input: eventInput) {
    createEvent(input: $input) {
      _id
      tenantID
      centersID
      isImported
      internal_number      
      day_slotID      
      uuid
      altern_uuid
      external_uuid
      patient_type
      date
      date_string
      time
      time_string
      personID
      patient_doc_id
      referring_professionalID
      referring_centerID
      referring_phy_name
      referring_center_name
      appointment_types
      status
      payment_status
      resource_types
      active
      professionalsID
      specialitiesID
      proceduresID
      appointment_reason
      reception_comment
      default_duration
      added_minutes
      adjacent_taken_slotsID
      visit_number
      medical_order_number
    }
  }
`

export const CHANGE_PERMISSION_STATUS = gql`
  mutation ChangePermissionStatus($input: permissionInput) {
    changeStatusPermission(input: $input) {
      _id
      status
    }
  }
`

export const CREATE_PERMISSION = gql`
  mutation CreatePermission($input: permissionInput) {
    createPermission(input: $input) {
      _id
      name
      internal_number
      op_code
      status
      detail
      system
      created_by
    }
  }
`

export const CHANGE_AREA_STATUS = gql`
  mutation ChangeAreaStatus($input: areaInput) {
    changeStatusArea(input: $input) {
      _id
      name
      status
    }
  }
`

export const CREATE_AREA = gql`
  mutation CreateArea($input: areaInput) {
    createArea(input: $input) {
      _id
      name
      tenantID
      proceduresID
      professionalsID
      internal_number
      status
      imported_events_only
      include_imported_events
      detail
      system
      appointment_types
      worklist_states
    }
  }
`

export const CREATE_CENTER = gql`
  mutation CreateCenter($input: centerInput) {
    createCenter(input: $input) {
      _id
      tenantID
      altern_number
      ext_number
      areasID
      status
      license_number
      legal_id_number
      legal_name
      social_name
      initials
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      zones
      mail_address
    }
  }
`

export const CHANGE_CENTER_STATUS = gql`
  mutation ChangeCenterStatus($input: centerInput) {
    changeStatusCenter(input: $input) {
      _id
      tenantID
      altern_number
      ext_number
      areasID
      status
      license_number
      legal_id_number
      legal_name
      social_name
      initials
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      zones
      mail_address
    }
  }
`

export const CHANGE_ROLE_STATUS = gql`
  mutation ChangeRoleStatus($input: roleInput) {
    changeStatusRole(input: $input) {
      _id
      name
      internal_number
      tenantID
      centersID
      areasID
      permissionsID
      status
      detail
      valid_for
      frontend_routes
    }
  }
`

export const CREATE_ROLE = gql`
  mutation CreateRole($input: roleInput) {
    createRole(input: $input) {
      _id
      name
      internal_number
      tenantID
      centersID
      areasID
      permissionsID
      status
      detail
      valid_for
      frontend_routes
      appointment_types
      created_by
      created_at
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($input: createUserInput) {
    createUser(input: $input) {
      _id
      internal_number
      tenantsID
      type
      assigned_personID
      assigned_professionalID
      origin_system
      status
      uuid
      alias
      assigned_rolesID
      override_permissionsID
      default_roleID
      default_frontend_route
      default_frontend_route_tenantID
      default_frontend_route_centerID
      default_frontend_route_areaID
      isClinical
      pass_last_change
      pass_must_change
    }
  }
`

export const CHANGE_USER_STATUS = gql`
  mutation ChangeUserStatus($input: changeStatusUserInput!) {
    changeStatusUser(input: $input) {
      _id
      internal_number
      tenantsID
      type
      assigned_personID
      assigned_professionalID
      origin_system
      status
      uuid
      alias
      assigned_rolesID
      override_permissionsID
      default_roleID
      default_frontend_route
      created_at
      created_by
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      pass_last_change
      pass_must_change
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($input: deleteUserInput) {
    deleteUser(input: $input) {
      _id
      internal_number
      tenantsID
      type
      assigned_personID
      assigned_professionalID
      origin_system
      status
      uuid
      alias
      assigned_rolesID
      override_permissionsID
      default_roleID
      default_frontend_route
      created_at
      created_by
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      pass_last_change
    }
  }
`

export const CHANGE_BILLING_CODE_STATUS = gql`
  mutation ChangeStatusBillingCode($input: billingcodeInput) {
    changeStatusBillingCode(input: $input) {
      _id
      tenantID
      name
      status
      altern_name
      internal_number
      code
      code_issuer
      altern_code
      ext_code
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      created_at
      created_by
    }
  }
`

export const CREATE_BILLING_CODE = gql`
  mutation CreateBillingCode($input: billingcodeInput) {
    createBillingCode(input: $input) {
      _id
      tenantID
      name
      altern_name
      internal_number
      code
      code_issuer
      altern_code
      ext_code
    }
  }
`

export const CREATE_PROCEDURE = gql`
  mutation CreateProcedure(
    $input: procedureInput
    $preparations: [fileInput]
    $patient_surveys: [fileInput]
  ) {
    createProcedure(
      input: $input
      preparations: $preparations
      patient_surveys: $patient_surveys
    ) {
      _id
      tenantID
      status
      name
      altern_name
      internal_number
      code
      altern_code
      ext_name
      ext_code
      billingCodes {
        _id
        quantity
      }
      is_DICOM
      DICOM_type
      minutes
      appointment_types
      req_preparation_minutes
      send_email_to_pat
      instructions_to_patient
    }
  }
`

export const CHANGE_PROCEDURE_STATUS = gql`
  mutation ChangeProcedureStatus($input: procedureInput) {
    changeStatusProcedure(input: $input) {
      _id
      tenantID
      status
      altern_name
      internal_number
      code
      altern_code
      ext_code
      billingCodesID
    }
  }
`

export const CREATE_ZONE = gql`
  mutation CreateZone($input: zoneInput) {
    createZone(input: $input) {
      _id
      tenantID
      name
      internal_number
      created_by
      created_at
    }
  }
`

export const CREATE_PROFESSIONAL = gql`
  mutation CreateProfessional($input: professionalInput) {
    createProfessional(input: $input) {
      _id
      personID
      assigned_tenantsID
      assigned_centersID
      internal_number
      altern_number
      uuid
      ext_number
      legal_data {
        college
        year
        title
        specialities
        record
      }
      type
      title
      status
      document_number
      sched_specialitiesID
      sched_proceduresID
      created_by
      created_at
    }
  }
`

export const CHANGE_PROFESSIONAL_STATUS = gql`
  mutation ChangeProfessionalStatus($input: professionalInput) {
    changeStatusProfessional(input: $input) {
      _id
      result
      code
    }
  }
`

export const CREATE_TENANT = gql`
  mutation CreateTenant($input: tenantsInput) {
    createTenant(input: $input) {
      _id
      internal_number
      altern_number
      status
      legal_id_number
      legal_name
      social_name
      head_office_address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
        latitude
        longitude
      }
      mail_address
      cel_phone
      assoc_insuranceCorpsID
      assoc_insuranceCorps_detail {
        _id
        name
        type
      }
    }
  }
`

export const CHANGE_TENANT_STATUS = gql`
  mutation ChangeTenantStatus($input: tenantsStatusInput) {
    changeStatusTenant(input: $input) {
      _id
      internal_number
      altern_number
      status
      legal_id_number
      legal_name
      social_name
      head_office_address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
        latitude
        longitude
      }
      mail_address
      cel_phone
    }
  }
`

export const CREATE_BASE_PERSON = gql`
  mutation CreateBasePerson($input: basePersonInput) {
    createBasePerson(input: $input) {
      _id
      uuid
      is_imported
      status
      origin_system
      ssn_issuer
      legal_name {
        first_name
        second_name
        third_name
        surname
        mother_maiden
      }
      ssn_personintId
      birth_date
      sex
      sex_code
      mail_address
      cel_phone
      home_phone
      address {
        street
        street_number
        city
        city2
        city3
        country_code
        country_name
      }
      insurance_type
      insurance_detail
      insurance_company
      insuranceCompanyID
    }
  }
`

export const FREEZE_PERSON = gql`
  mutation FreezePerson($input: freezePersonInput) {
    freezePerson(input: $input) {
      _id
      uuid
      is_imported
      status
      ssn_personintId
    }
  }
`

export const CREATE_SPECIALITIES = gql`
  mutation CreateSpecialities($input: specialitiesInput) {
    createSpecialities(input: $input) {
      _id
      tenantID
      status
      name
      search_type
      altern_name
      internal_number
      code
      altern_code
      proceduresID
    }
  }
`

export const CHANGE_SPECIALITIES_STATUS = gql`
  mutation ChangeSpecialitiesStatus($input: specialitiesInput) {
    changeStatusSpecialities(input: $input) {
      _id
      tenantID
      status
      name
      search_type
      altern_name
      internal_number
      code
      altern_code
      proceduresID
    }
  }
`

export const CREATE_DAY_SLOT_ADV = gql`
  mutation CreateDaySlotAdv($input: DaySlotsAdvInput) {
    createDaySlotsAdv(input: $input) {
      responseCode
      daysQ
      active
    }
  }
`

export const CREATE_DAY_SLOT_EQUIP = gql`
  mutation CreateDaySlotEquip($input: DaySlotsEquipInput) {
    createDaySlotsEquip(input: $input) {
      responseCode
      daysQ
      active
    }
  }
`

export const CHANGE_DAY_SLOT_STATUS = gql`
  mutation ChangeDaySlotStatus($slotsCreationInfoID: ID, $reason: String) {
    changeStatusDaySlots(
      slotsCreationInfoID: $slotsCreationInfoID
      reason: $reason
    ) {
      result
      createdSlotChanged
      _id
    }
  }
`

export const DELETE_DAY_SLOT_STATUS = gql`
  mutation DeleteDaySlots($slotsCreationInfoID: ID, $reason: String) {
    deleteDaySlots(slotsCreationInfoID: $slotsCreationInfoID, reason: $reason) {
      result
      createdSlotDeleted
    }
  }
`

export const CREATE_DAY_SLOTS_LOCKS = gql`
  mutation CreateDaySlotsLocks(
    $input: DaySlotsLocksInput
    $slotCreationInfoID: ID!
  ) {
    createDaySlotsLocks(
      input: $input
      slotCreationInfoID: $slotCreationInfoID
    ) {
      _id
      status
      dateFromStr
      dateToStr
      daysOfTheWeek
      timeFrom
      timeTo
      reason
      daySlotsID
      total_created_slotsID
      in_initial_created_slotsID
      in_reserved_created_slotsID
      adjacent_eventsID
    }
  }
`

export const DELETE_TENANT = gql`
  mutation DeleteTenant($tenantID: ID) {
    deleteTenant(tenantID: $tenantID) {
      _id
      internal_number
      legal_id_number
      legal_name
    }
  }
`

export const DELETE_SPECIALITIES = gql`
  mutation DeleteSpecialities($specialitiesID: ID) {
    deleteSpecialities(specialitiesID: $specialitiesID) {
      _id
      internal_number
      code
      name
    }
  }
`

export const DELETE_BILLING_CODES = gql`
  mutation DeleteBillingCodes($BillingCodesID: ID) {
    deleteBillingCodes(BillingCodesID: $BillingCodesID) {
      _id
      internal_number
      code
      name
    }
  }
`

export const DELETE_PROCEDURES = gql`
  mutation DeleteProcedures($proceduresID: ID) {
    deleteProcedures(proceduresID: $proceduresID) {
      _id
      internal_number
      code
      name
    }
  }
`

export const DELETE_ZONES = gql`
  mutation DeleteZones($zonesID: ID) {
    deleteZones(zonesID: $zonesID) {
      _id
      internal_number
      name
    }
  }
`

export const DELETE_AREAS = gql`
  mutation DeleteAreas($areasID: ID) {
    deleteAreas(areasID: $areasID) {
      _id
      internal_number
      name
    }
  }
`

export const DELETE_CENTERS = gql`
  mutation DeleteCenters($centerID: ID) {
    deleteCenters(centerID: $centerID) {
      _id
      internal_number
      legal_id_number
      legal_name
      social_name
    }
  }
`

export const DELETE_PROFESSIONALS = gql`
  mutation DeleteProfessionals($professionalID: ID) {
    deleteProfessionals(professionalID: $professionalID) {
      _id
      internal_number
      personID
    }
  }
`

export const DELETE_PERMISSIONS = gql`
  mutation DeletePermissions($permissionsID: ID) {
    deletePermissions(permissionsID: $permissionsID) {
      _id
      internal_number
      name
    }
  }
`

export const DELETE_ROLES = gql`
  mutation DeleteRoles($rolesID: ID) {
    deleteRoles(rolesID: $rolesID) {
      _id
      internal_number
      name
    }
  }
`

export const DELETE_EVENTS = gql`
  mutation DeleteEvents($eventsID: ID) {
    deleteEvents(eventsID: $eventsID) {
      _id
    }
  }
`

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment($input: equipmentInput) {
    createEquipment(input: $input) {
      _id
      tenantID
      assigned_tenantsID
      assigned_centersID
      name
      alias
      initials
      location
      internal_number
      altern_number
      ext_number
      status
      sched_specialitiesID
      sched_proceduresID
      override_proceduresTime {
        procedureID
        timeInMinutes
      }
    }
  }
`

export const DELETE_EQUIPMENT = gql`
  mutation DeleteEquipment($equipmentID: ID) {
    deleteEquipment(equipmentID: $equipmentID) {
      _id
      tenantID
      assigned_tenantsID
      assigned_centersID
      name
      alias
      initials
      location
      internal_number
      altern_number
      ext_number
      status
      sched_specialitiesID
      sched_proceduresID
      override_proceduresTime {
        procedureID
        timeInMinutes
      }
    }
  }
`

export const CHANGE_EQUIPMENT_STATUS = gql`
  mutation ChangeEquipmentStatus($input: equipmentInput) {
    changeStatusEquipment(input: $input) {
      _id
      tenantID
      assigned_tenantsID
      assigned_centersID
      name
      alias
      initials
      location
      is_dicom_comp
      ipv4_addr
      ipv6_addr
      dicom_modality
      dicom_qr_aetitle
      dicom_mwl_aetitle
      dicom_qr_port
      dicom_mwl_port
      internal_number
      altern_number
      ext_number
      status
      sched_specialitiesID
      sched_proceduresID
      override_proceduresTime {
        procedureID
        timeInMinutes
      }
    }
  }
`

export const CREATE_TOKEN_TAGS = gql`
  mutation CreateTokenTags($input: tokenTagsInput) {
    createTokenTags(input: $input) {
      _id
      tenantID
      name
      description
      internal_number
      type
      status
      display_selector
      schemaName
      collectionName
      fieldName
    }
  }
`

export const CHANGE_TOKEN_TAG_STATUS = gql`
  mutation ChangeTokenTagStatus($input: tokenTagsInput) {
    changeStatusTokenTags(input: $input) {
      _id
      tenantID
      name
      description
      internal_number
      type
      status
      display_selector
      schemaName
      collectionName
      fieldName
    }
  }
`

export const DELETE_TOKEN_TAG = gql`
  mutation DeleteTokenTag($tokenTagsID: ID) {
    deleteTokenTags(tokenTagsID: $tokenTagsID) {
      _id
      tenantID
      name
      description
      internal_number
      type
      status
      display_selector
      schemaName
      collectionName
      fieldName
    }
  }
`

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($input: templatesInput) {
    createTemplate(input: $input) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      isStructural
      owner_userID
      status
      isRad 
      reportProcedures
      reportRadModality
      content
      core_type
    }
  }
`

export const CHANGE_TEMPLATE_STATUS = gql`
  mutation ChangeTemplateStatus($input: templatesInput) {
    changeStatusTemplate(input: $input) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      owner_userID
      status
      isRad 
      reportProcedures
      reportRadModality
      content
      core_type
    }
  }
`

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($templatesID: ID) {
    deleteTemplate(templatesID: $templatesID) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      owner_userID
      status
      isRad 
      reportProcedures
      reportRadModality
      content
      core_type
    }
  }
`

export const CREATE_DYNAMIC_DOCTOS = gql`
  mutation CreateDynamicDoctos($input: dynamicDoctosInput) {
    createDynamicDoctos(input: $input) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      owner_userID
      status
      isRad 
      reportProcedures
      reportRadModality
      content
    }
  }
`

export const CHANGE_DYNAMIC_DOCTOS_STATUS = gql`
  mutation ChangeDynamicDoctosStatus($input: dynamicDoctosInput) {
    changeStatusDynamicDoctos(input: $input) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      owner_userID
      status
      content
    }
  }
`

export const DELETE_DYNAMIC_DOCTOS = gql`
  mutation DeleteDynamicDoctos($dynamicDoctosID: ID) {
    deleteDynamicDoctos(dynamicDoctosID: $dynamicDoctosID) {
      _id
      tenantID
      name
      alias
      description
      tokenTagsID
      internal_number
      type
      is_public
      is_editable
      owner_userID
      status
      content
    }
  }
`

export const CREATE_MD_INSURANCE_CORP = gql`
  mutation CreateMDInsuranceCorp($input: MDinsCorpInput) {
    createMDinsCorp(input: $input) {
      _id
      tenantID
      type
      type_other_detail
      name
      code
      legal_id_number
      ext_code
      internal_number
      status
      detail
    }
  }
`

export const CHANGE_MD_INSURANCE_CORP = gql`
  mutation ChangeMDInsuranceCorp($input: MDinsCorpInput) {
    changeMDinsCorp(input: $input) {
      _id
      tenantID
      type
      type_other_detail
      name
      code
      legal_id_number
      ext_code
      internal_number
      status
      detail
    }
  }
`

export const DELETE_MD_INSURANCE_CORP = gql`
  mutation DeleteMDInsuranceCorp($MDInsCorpID: ID) {
    deleteMDinsCorp(MDInsCorpID: $MDInsCorpID) {
      _id
      tenantID
      type
      type_other_detail
      name
      code
      legal_id_number
      ext_code
      internal_number
      status
      detail
    }
  }
`
export const ADD_BILLING_CODE_TO_PAY_ACCOUNT = gql`
  mutation AddBillingCodeToPayAccount(
    $paymentsAccountsID: ID
    $billingCodesItem: billingCodesListInput
  ) {
    addBillingCodeToPayAccount(
      paymentsAccountsID: $paymentsAccountsID
      billingCodesItem: $billingCodesItem
    ) {
      _id
    }
  }
`

export const CREATE_PAYMENTS_ACCOUNTS = gql`
  mutation CreatePaymentsAccounts($input: paymentsAccountsInput) {
    createPaymentsAccounts(input: $input) {
      _id
    }
  }
`

export const CREATE_PRICE_AGREEMENTS = gql`
  mutation CreatePriceAgreements($input: priceAgreementsInput) {
    createPriceAgreements(input: $input) {
      _id
      tenantID
      status
      centersID
      agreement_type
      insuranceCorpID
      name
      altern_name
      internal_number
      code
      altern_code
      ext_code
      ext_name
      list {
        prlist_id
        status
        type
        billingCodeID
        other_code
        price
        price_tax
        total_price
        default_price_coverage
        price_accepts_dscnt
        ISO4217
      }
    }
  }
`

export const CHANGE_STATUS_PRICE_AGREEMENTS = gql`
  mutation ChangeStatusPriceAgreements($priceAgreementID: ID, $prlistID: ID) {
    changeStatusPriceAgreements(
      priceAgreementID: $priceAgreementID
      prlistID: $prlistID
    ) {
      _id
      tenantID
      status
      centersID
      agreement_type
      insuranceCorpID
      name
      altern_name
      internal_number
      code
      altern_code
      ext_code
      ext_name
      list {
        prlist_id
        status
        type
        billingCodeID
        other_code
        price
        price_tax
        total_price
        default_price_coverage
        price_accepts_dscnt
        ISO4217
      }
    }
  }
`

export const DELETE_PRICE_AGREEMENTS = gql`
  mutation DeletePriceAgreements($priceAgreementID: ID, $prlistID: ID) {
    deletePriceAgreements(
      priceAgreementID: $priceAgreementID
      prlistID: $prlistID
    ) {
      _id
      tenantID
      status
      centersID
      agreement_type
      insuranceCorpID
      name
      altern_name
      internal_number
      code
      altern_code
      ext_code
      ext_name
      list {
        prlist_id
        status
        type
        billingCodeID
        other_code
        price
        price_tax
        total_price
        default_price_coverage
        price_accepts_dscnt
        ISO4217
      }
    }
  }
`

export const CREATE_CASHIER_SESSIONS = gql`
  mutation CreateCashierSessions($input: cashierSessionsInput) {
    createCashierSessions(input: $input) {
      _id
      internal_number
      uuid
      tenantID
      userID
      open_by_userID
      open_time
      closed_by_userID
      closed_time
      initial_amounts {
        currency_denomination
        amount
      }
      total_initial_amount
      payment_movements {
        type
        paymentAccountID
        paylisID
        comments
      }
      closed_amounts {
        payment_method
        amount
      }
      total_closed_amount
      status
      comments
      close_reason
    }
  }
`

export const CLOSE_CASHIER_SESSIONS = gql`
  mutation CloseCashierSessions($input: closeCashSessionInput) {
    closeCashierSession(input: $input) {
      _id
      internal_number
      uuid
      tenantID
      userID
      open_by_userID
      open_time
      closed_by_userID
      closed_time
      initial_amounts {
        currency_denomination
        amount
      }
      total_initial_amount
      payment_movements {
        type
        paymentAccountID
        paylisID
        comments
      }
      closed_amounts {
        payment_method
        amount
      }
      total_closed_amount
      status
      comments
      close_reason
    }
  }
`

export const DELETE_CASHIER_SESSIONS = gql`
  mutation DeleteCashierSessions($cashierSessionsID: ID) {
    deleteCashierSessions(cashierSessionsID: $cashierSessionsID) {
      _id
      internal_number
      uuid
      tenantID
      userID
      open_by_userID
      open_time
      closed_by_userID
      closed_time
      initial_amounts {
        currency_denomination
        amount
      }
      total_initial_amount
      payment_movements {
        type
        paymentAccountID
        paylisID
        comments
      }
      closed_amounts {
        payment_method
        amount
      }
      total_closed_amount
      status
      comments
      close_reason
    }
  }
`

export const CHANGE_STATUS_CASHIER_SESSIONS = gql`
  mutation ChangeStatusCashierSessions($input: cashierSessionsInput) {
    changeStatusCashierSessions(input: $input) {
      _id
      internal_number
      uuid
      tenantID
      userID
      open_by_userID
      open_time
      closed_by_userID
      closed_time
      initial_amounts {
        currency_denomination
        amount
      }
      total_initial_amount
      payment_movements {
        type
        paymentAccountID
        paylisID
        comments
      }
      closed_amounts {
        payment_method
        amount
      }
      total_closed_amount
      status
      comments
      close_reason
    }
  }
`

export const CHANGE_EVENT_STATUS = gql`
  mutation ChangeEventStatus($eventID: ID, $changeStatus: String) {
    changeEventStatus(eventID: $eventID, changeStatus: $changeStatus) {
      _id
      status
    }
  }
`

export const CREATE_FORCED_EVENT = gql`
  mutation CreateForcedEvent($input: eventInput) {
    createForcedEvent(input: $input) {
      _id
      tenantID
      centersID
      isImported
      internal_number
      visit_number
      uuid
      altern_uuid
      external_uuid
      creation_source
      creation_channel
      patient_type
      date
      date_string
      time
      time_string
      default_duration
      added_minutes
      personID
      patient_doc_id
      referring_professionalID
      appointment_types
      status
      payment_status
      resource_types
      active
      professionalsID
      specialitiesID
      proceduresID
      appointment_reason
      priority
      modality_types
      reception_comment
      referring_phy_number
      referring_phy_name
      referring_center_name
      referring_area
    }
  }
`
export const CHANGE_EVENT_PATIENT = gql`
  mutation ChangeEventPatient($eventID: ID, $personID: ID) {
    changeEventPatient(eventID: $eventID, personID: $personID) {
      _id
      name
      ssn_ident
      sexo
      birth_date
      eventsID
    }
  }
`

export const DELETE_BILLING_CODE_IN_PAY_ACCOUNT = gql`
  mutation DeleteBillingCodeInPayAccount(
    $paymentsAccountsID: ID
    $prlistID: ID
  ) {
    deleteBillingCodeInPayAccount(
      paymentsAccountsID: $paymentsAccountsID
      prlistID: $prlistID
    ) {
      _id
      eventID
      billingCodeID
      quantity
      priceAgreementID
      prlistID
      price
      tax
      discount
      totalPrice
    }
  }
`
export const DELETE_PAY_TO_PAY_ACCOUNT = gql`
  mutation deletePayToPayAccount($paymentsAccountsID: ID, $paymentListID: ID) {
    deletePayToPayAccount(
      paymentsAccountsID: $paymentsAccountsID
      paymentListID: $paymentListID
    )
  }
`

export const CHANGE_BILLING_CODE_IN_PAY_ACCOUNT = gql`
  mutation ChangeBillingCodeInPayAccount(
    $paymentsAccountsID: ID
    $billingCodesItem: billingCodesListInput
  ) {
    changeBillingCodeInPayAccount(
      paymentsAccountsID: $paymentsAccountsID
      billingCodesItem: $billingCodesItem
    ) {
      _id
      eventID
      billingCodeID
      quantity
      priceAgreementID
      prlistID
      price
      tax
      discount
      totalPrice
      priceAgreementID
      agreementCode
      agreementName
    }
  }
`

export const CHANGE_PAY_TO_PAY_ACCOUNT = gql`
  mutation ChangePayToPayAccount(
    $paymentsAccountsID: ID
    $paymentListItem: paymentlistInput
  ) {
    changePayToPayAccount(
      paymentsAccountsID: $paymentsAccountsID
      paymentListItem: $paymentListItem
    ) {
      _id
      uuid
      status
      date
      type
      eventsID
      invoicesID
      insuranceCorpID
      payment_method
      amount
      hasInvoice
      paymethod_detail {
        bono_number
        bono_date
        bono_emitter
        brand_cc
        bank_cc
        brand_dc
        bank_dc
        voucher_code
        auth_code
        other_code
        transfer_date
        transfer_bank_from
        transfer_person_from
        transfer_code
        cheque_number
        cheque_bank
        cheque_owner
        other_type
        other_detail
        other_scannedDocto
        comments
      }
      cashierSessionID
      # log
      # eventsData
      # invoicesData
      # insuranceName
    }
  }
`
export const ADD_PAY_TO_PAY_ACCOUNT = gql`
  mutation AddPayToPayAccount(
    $paymentsAccountsID: ID
    $paymentListItem: paymentlistInput
  ) {
    addPayToPayAccount(
      paymentsAccountsID: $paymentsAccountsID
      paymentListItem: $paymentListItem
    ) {
      _id
      uuid
      status
      date
      type
      #log
      eventsID
      invoicesID
      insuranceCorpID
      payment_method
      amount
      hasInvoice
      paymethod_detail {
        bono_number
        bono_date
        bono_emitter
        brand_cc
        bank_cc
        brand_dc
        bank_dc
        voucher_code
        auth_code
        other_code
        transfer_date
        transfer_bank_from
        transfer_person_from
        transfer_code
        cheque_number
        cheque_bank
        cheque_owner
        other_type
        other_detail
        other_scannedDocto
        comments
      }
      cashierSessionID
      #eventsData
      #invoicesData
      #insuranceName
    }
  }
`

export const EDIT_FORCED_EVENT = gql`
  mutation EditForcedEvent($input: eventInput) {
    editEvent(input: $input) {
      _id
      tenantID
      centersID
      isImported
      internal_number
      visit_number
      uuid
      altern_uuid
      external_uuid
      creation_source
      creation_channel
      patient_type
      date
      date_string
      time
      time_string
      default_duration
      added_minutes
      personID
      patient_doc_id
      referring_professionalID
      appointment_types
      status
      payment_status
      resource_types
      active
      professionalsID
      specialitiesID
      proceduresID
      appointment_reason
      modality_types
      reception_comment
      referring_phy_number
      referring_phy_name
      referring_center_name
      referring_area
    }
  }
`

export const UPLOAD_DOCUMENT_TO_EVENT = gql`
  mutation UploadDocumentToEvent($eventID: ID, $input: [fileInput]) {
    uploadDocumentToEvent(eventID: $eventID, input: $input) {
      _id
      file_name
      event_UUID
      file_classification
    }
  }
`

export const CREATE_MED_DOCTO = gql`
  mutation createMedDocto($patientID: ID!, $input: medDoctoInput) {
    createMedDocto(patientID: $patientID, input: $input) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      origin
      status
      visit_reason
      anamnesis
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      HTML_type
      form_template_HTML_content
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      therapy
      created_on_tenantID   
      structure_documents {
        type
        name
        description
        content
      }
    }
  }
`;


export const CREATE_MED_OPHT_DOCTO = gql`
  mutation createMedOphtDocto($patientID: ID!, $input: medDoctoOphtInput) {
    createMedOphtDocto(patientID: $patientID, input: $input) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      origin
      status
      visit_reason
      anamnesis
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      HTML_type
      form_template_HTML_content
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      therapy
      created_on_tenantID   
      structure_documents {
        type
        name
        description
        content
      }
    }
  }
`;


export const SIGN_MED_DOCTO = gql`
  mutation signMedDocto($patientID: ID!, $input: medDoctoInput) {
    signMedDocto(patientID: $patientID, input: $input) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      origin
      status
      visit_reason
      anamnesis
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      therapy
      created_on_tenantID
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      structure_documents {
        _id
        type
        name
        description
        content
      }
      created_at
      create_by
    }
  }
`;

export const SIGN_CLINICAL_SUPPORT_DOCTO = gql`
  mutation signClinicalSupportDocto($patientID: ID!, $input: clinicalSupportDoctoInput) {
    signClinicalSupportDocto(patientID: $patientID, input: $input) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      origin
      status
      visit_reason
      templateID
      anamnesis
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      clinical_support_templatesID
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      therapy
      created_on_tenantID
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      structure_documents {
        _id
        type
        name
        description
        content
      }
      created_at
      create_by
    }
  }
`;


export const SIGN_MED_OPHT_DOCTO = gql`
  mutation signMedOphtDocto($patientID: ID!, $input: medDoctoOphtInput) {
    signMedOphtDocto(patientID: $patientID, input: $input) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      origin
      status
      visit_reason
      anamnesis
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      therapy
      created_on_tenantID
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      created_at
      create_by
    }
  }
`;

export const CREATE_ADDENDUM_TO_MED_DOCTO = gql`
  mutation createAddendumToMedDocto($patientID: ID!, $input: medDoctoInput) {
    createAddendumToMedDocto(patientID: $patientID, input: $input) {
      _id
      internal_number
      medRecordID
      eventID
      event_type
      patient_visit_type
      specialityID
      speciality_string
      uuid
      procedureID
      procedure_name
      onhold_date
      finished_date
      ext_number
      isImported
      imported_MIME_type
      imported_content
      external_url
      external_content_type
      docto_creation_date
      hasPatientLicense
      origin
      status
      visit_reason
      anamnesis
      docto_creation_professionalID
      docto_creation_professional_name
      docto_creation_professional_ssnId
      record
      isDicom
      HTML_rad_report
      clean_rad_report
      diagnoses {
        _id
        code_diccionary
        CIE10codeID
        SNOMEDcodeID
        description
        isChronic
        comments
        isGES
      }
      exams_and_procs {
        _id
        procedureID
        proc_specialityID
        procedure_name
        procedure_speciality
        date
      }
      drugs {
        _id
        drugID
        drug_name
        drug_type
        drug_dose
        drug_indications
        date
      }
      therapy
      created_on_tenantID
      addendums {
        _id
        record
        created_by
        created_at
      }
      log {
        date
        time
        action_type
        detail
        prev_data
        new_data
        user
      }
      created_at
      create_by
    }
  }
`;


export const ADD_EVENT_TEC_ANAMNESIS = gql`
  mutation AddEventTecAnamnesis($eventsID: ID, $anamnesis: String) {
    addEventTecAnamnesis(eventsID: $eventsID, anamnesis: $anamnesis) {
      _id
      tec_anamnesis
    }
  }
`;

export const CREATE_PATIENT_CLINICAL_INFO = gql`
  mutation CreatePatientClinicalInfo(
    $patientID: ID,
    $input: patientClinicalInfoInput
  ) {
    createPatientClinicalInfo(
      patientID: $patientID,
      input: $input
    ) {
      _id
      personal_family_history{
        occupation
        family_group
        family_background
      }
      morbid_antecedents{
        diseases
        surgeries
        others
      }
      allergies{
        medicines
        food
        others
      }
      gynecological_history{
        menarche_age
        menopause_age
        duration_days
        frecuency_days
        Flow_intensity
        FUR
        prengnancy
        others
      }
      habits{
        smoking
        alcohol
        type_feeding
        others
      }
      medicines{
        diseases
        surgeries
        others
      }
      immunizations{
        diseases
        surgeries
        others
      }
    }
  }
`;


export const CHANGE_EVENT_STATUS_TO = gql`
  mutation ChangeEventStatusTo($eventsID: ID, $newState: String) {
    changeEventStatusTo(eventsID: $eventsID, newState: $newState) {
      _id
      status
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword($input: inputPassType) {
    changeUserPass(input: $input) 
  }
`;

export const CREATE_DELIVER_PDF_MED_DOCTO = gql`
  mutation CreateDeliverPdfMedDocto(
    $input: deliverPDFMedDoctoInput!,
    $tenantID: ID,
    $procedureID: ID,
    $personID: ID,
    $eventID: ID,
    $medDoctoID: ID,
    $billingID: ID
  ) {
    createDeliverPDFMedDocto(
      input: $input,
      tenantID: $tenantID,      
      procedureID: $procedureID,
      personID: $personID,
      eventID: $eventID,
      medDoctoID: $medDoctoID,
      billingID: $billingID
    ) {
      name
      MIME_Type
      fileRaw
    }
  }
`

export const CREATE_FRONTEND_ERROR = gql`
  mutation CreateFrontendError(
    $input: frontendErrorInput!,
  ) {
    createFrontendError(
      input: $input,
    ) {
      _id
    }
  }
`

export const DELETE_UPLOADED_DOCUMENT_FROM_EVENT = gql`
  mutation DeleteUploadedDocumentFromEvent(
    $eventID: ID,
    $fileDoctoID: ID
  ) {
    deleteUploadedDocumentFromEvent(
      eventID: $eventID,
      fileDoctoID: $fileDoctoID
    ) {
      _id,
      attached_FILES {
        _id,
        type,
        classification,
        isDeleted,
      }
    }
  }
`

export const ADD_CONFIRMATION_TO_EVENT = gql`
  mutation AddConfirmationToEvent(
    $input: eventConfirmationAction!,
  ) {
    addConfirmationToEvent(
      input: $input 
    ) {
      _id,
      confirm_actions {
        confirmed_action_date,
        userID,
        confirmed_action_channel,
        confirmed_action_detail,
        confirmedbypatient,
      }
    }
  }
`

export const SAVE_USER_PREFERENCES = gql`
  mutation SaveUserPreferences(
    $input: userPreferencesInput
  ) {
    saveUserPreferences(
      input: $input 
    ) {
      firstName
      lastName
      patientType
      priority
      age
      sex
      patientId
      date
      time
      isImported
      speciality
      paymentStatus
      accessionNumber
      procedureCode
      procedureName
      professionalEquipment
      signedByProfessionals
      eventStatus
      actions
      appointmentReason
      visitNumber
      aiAnalisis
      aiAnalisisScore
      aiQuality
    }
  }
`