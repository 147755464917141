import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faUserInjured,
  faPenAlt,
  faEdit,
  faWindowClose,
  faCashRegister,
  faPaste,
  faPrint,
  faIdCard,
  faHospital,
  faUsers,
  faUserSlash,
  faFileInvoiceDollar,
  faBriefcaseMedical,
  faNotesMedical,
  faUserNurse,
  faSyringe,
  faHospitalUser,
  faClinicMedical,
  faStethoscope,
  faUserMd,
  faMapMarkerAlt,
  faProcedures,
  faUserLock,
  faUserShield,
  faUsersCog,
  faLaptopMedical,
  faTags,
  faFileInvoice,
  faFileAlt,
  faExclamationTriangle,
  faCheckCircle,
  faTrashAlt,
  faUndoAlt,
  faLock,
  faLockOpen,
  faHistory,
  faArrowCircleUp,
  faArrowCircleDown,
  faCalendarPlus,
  faFileSignature,
  faMarker,
  faFileUpload,
  faImages,
  faMicrophoneAlt,
  faSearchPlus,
  faStopwatch,
  faShareSquare,
  faHandHoldingUsd,
  faEye,
  faPenFancy,
  faSignature,
  faFileContract,
  faBookMedical,
  faClock,
  faCalendarCheck
} from '@fortawesome/free-solid-svg-icons';


library.add(
  faUserInjured,
  faPenAlt,
  faEdit,
  faWindowClose,
  faCashRegister,
  faPaste,
  faPrint,
  faIdCard,
  faHospital,
  faUsers,
  faUserSlash,
  faFileInvoiceDollar,
  faBriefcaseMedical,
  faNotesMedical,
  faUserNurse,
  faSyringe,
  faHospitalUser,
  faClinicMedical,
  faStethoscope,
  faUserMd,
  faMapMarkerAlt,
  faProcedures,
  faUserLock,
  faUserShield,
  faUsersCog,
  faLaptopMedical,
  faTags,
  faFileSignature,
  faFileInvoice,
  faFileAlt,
  faExclamationTriangle,
  faCheckCircle,
  faTrashAlt,
  faUndoAlt,
  faLock,
  faLockOpen,
  faHistory,
  faArrowCircleUp,
  faArrowCircleDown,
  faCalendarPlus,
  faMarker,
  faFileUpload,
  faImages,
  faMicrophoneAlt,
  faSearchPlus,
  faStopwatch,
  faShareSquare,
  faHandHoldingUsd,
  faEye,
  faPenFancy,
  faSignature,
  faFileContract,
  faBookMedical,
  faClock,
  faCalendarCheck
);