export const titleCase = (str) => {
  if (!str) return str;
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
};

export const getPersonInitials = (fullName) => {
  
  if (!fullName) return '-';
  const wordsList = fullName.split(' ');

  if (wordsList.length === 1) {
    return wordsList[0].substring(0, 2).toUpperCase();
  }


  const initials = `${wordsList[0][0]?.toUpperCase()}${wordsList[1][0]?.toUpperCase() || wordsList[2][0].toUpperCase() }`;
  
  return initials.replace('.', '');

};

export const uppercaseWords = (str) => {
    return str
        .split(' ')
        .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
        .join(' ');
      }