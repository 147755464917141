import { useState } from "react";

export default function useSearch() {

  const [searchType, setSearchType] = useState(null);
  const [searchDetail, setSearchDetail] = useState(null);  
  const [secondarySearch, setSecondarySearch] = useState(null);
  const [searchDateRange, setSearchDateRange] = useState({
    startDate: null,
    endDate: null
  });
    
  function handleSearchType(value) {
    
    setSearchType(value);
    
  }

  // searchDetail puede ser null o un Objecto { id, label }

  function handleSearchDetail(value) {
        
    setSearchDetail(value);

  }

  
  function resetSearchDetail() {

    setSearchDetail(null);    

  }

  function handleSecondarySearch(value) {
    //console.log(`Secondary selected ${value}`);
    setSecondarySearch(value);
  }

  function handleSearchDateRange(value) {
    //console.log(`Date range selected:`, JSON.stringify(value, null, 2));
    setSearchDateRange(value);
  }
 
  return {
    searchType,
    searchDetail,
    secondarySearch,
    searchDateRange,
    handleSearchType,
    handleSearchDetail,
    handleSearchDateRange,
    handleSecondarySearch,
    resetSearchDetail
  };
}
