export const DEFAULT_WORKLIST_SELECTED_COLUMNS = {
	firstName: true,
	lastName: true,
	patientType: true,
	priority: true,
	age: true,
	sex: true,
	patientId: true,
	date: true,
	time: true,
	isImported: true,
	speciality: true,
	paymentStatus: true,
	accessionNumber: true,
	procedureCode: true,
	procedureName: true,
	professionalEquipment: true,
	signedByProfessionals: true,
	eventStatus: true,
	actions: true,
	appointmentReason: true,
	visitNumber: true,
	aiAnalisis: true,
	aiAnalisisScore: true,
	aiQuality: true
}

export const WORKLIST_COLUMN_LABELS = {
	firstName: 'Nombres',
	lastName: 'Apellidos',
	patientType: 'Tipo',	
	priority: 'Prioridad',	
	age: 'Edad',
	sex: 'Sexo',
	patientId: 'ID del Paciente',
	date: 'Fecha Cita',
	time: 'Hora Cita',
	isImported: 'Evento Importado',
	speciality: 'Especialidad',
	paymentStatus: 'Pago',
	accessionNumber: 'Accession Nº',
	procedureCode: 'Código Procedimiento',
	procedureName: 'Nombre Procedimiento',
	professionalEquipment: 'Profesional/Equipo',
	signedByProfessionals: 'Draft/Final por',
	eventStatus: 'Estado',
	actions: 'Acciones',
	appointmentReason: 'Motivo Consulta',	
	visitNumber: 'Nº Visita',	
	aiAnalisis: 'IA Normal/Anormal',
	aiAnalisisScore: 'IA Normal/Anormal Score',
	aiQuality: 'IA Calidad'
}

export const APP_WORKLISTS = [
	'administrative',
	'radiology'
]